import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import axios from 'axios';
import 'react-table-6/react-table.css'
import { NativeSelect } from '@material-ui/core';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import fileDownload from 'js-file-download'
import { CleanConsole } from '@eaboy/clean-console';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondo: {
    width: '100%',
    height: '90em',
    backgroundColor: '#002A68',
    
  },
  homeFoto: {   
    height: '60%',
    position: 'absolute',
    left: '10%',
    top:'10%'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5%',
    height: '12%'
  },  
  tablaAdmin: {
      top: '32%',      
      width: '85%',
      left: '5%',
      marginBottom: '5%',
  },
  btnCrear: { 
      color: 'white',
      backgroundColor: '#5cb85c', 
      fontFamily: 'Montserrat'
  },
  boxbtnCrear: {   
    position: 'absolute',
    marginBottom: '3%',
    top: '85%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  fondoModalEliminar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  
  titleModalInsertar: {
    fontSize: '150%',
    fontWeight: 'bold',
    position: 'relative',
  },
  boxtitleModalInsertar: {
    left: '10%',
    position: 'relative',    
    width: '80%',
    height: '5%',
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  titleModalEliminar: {
    fontSize: '150%',
    fontWeight: 'bold',
    top:  '15%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  form: {
    position: 'absolute',
    top: '15%',
    left: '10%',    
    width: '85%',
    height: '80%'
  },
  inputEmail: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputEmailEditar: {
    width: '90%',
    marginTop: '15%',
    marginBottom: '2%',
  },
  inputNombre: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputContraseña: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    marginTop: '10%',
    left: '25%',
    color: 'white'
  },
  btnInsertar2: {
    backgroundColor: '#5cb85c',
    marginTop: '5%',
    marginBottom: '5%',
    left: '25%',
    color: 'white'
  },
  btnInsertar3: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    marginBottom: '5%',
    left: '25%',
    top: '60%',
    color: 'white'
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '10%',
    color: 'white'
  },
  btnCancelar2: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '5%',
    marginBottom: '5%',
    color: 'white'
  },
  btnCancelar3: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '3%',
    top: '60%',
    marginBottom: '5%',
    color: 'white'
  },
  fondoArriba: {
    width: '100%',
    height: '8em',
    position: 'abosolute'
  },

  legalFoto: {   
    width:'100%',
    marginTop: '5%',
    position: 'relative'
  },  
  btnAdministradores: {
    position: 'absolute',
    top: '2%',
    left: '3%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnNoticias: {
    position: 'absolute',
    top: '2%',
    left: '40%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  bienvenido: {
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '65%',
    fontFamily: 'Montserrat'
  },
  btnCerrar: {
    position: 'absolute',
    top: '2%',
    left: '90%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnUsuarios: {
    position: 'absolute',
    top: '2%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  barraMenu: {
    position: 'absolute',
    backgroundColor: '#213361',
    width: '95%',
    height: '2.5em',
    marginTop: '1%',
    left: '2.5%',
    border: '5px',
    borderRadius: '10px'
  },
  textNombreCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textNombre: {
    marginBottom: '2%',
    marginTop: '2%'
  },
  textCorreoCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textIdCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textEditarCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  btnCategorias: {
    position: 'absolute',
    top: '2%',
    left: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '15%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px'

  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    left: '15%',
    top: '15%',
    fontFamily: 'Montserrat'
  },
  exito2: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '15%',
    width: '35%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divError2: {
    backgroundColor: '#ff9090',
    height: '8%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',  
    fontFamily: 'Montserrat'
  },
  iconBtn: {
    left: '90%',
    top: '2%',
    position: 'absolute',
  },
  btnEditar: {
    backgroundColor: '#0275d8',
    marginRight: '10%',
    marginLeft: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnEliminar: {
    backgroundColor: '#d9534f',
    color: 'white',
    fontFamily: 'Montserrat'
    
  },
  inputId: {
    width: '90%',
    height: '10%',
    position: 'absolute',
    marginBottom: '5%',
  },
  textEmail: {
    marginTop: '10%',
    position: 'absolute'
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  btnReportes: {
    position: 'absolute',
    top: '2%',
    left: '75%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  boxTodo: {
      position: 'relative',
      marginTop: '8%',  
      width: '100%',
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  },
  boxInputs: {
      width: '30%',
      height: '100%',
      border: '#213361 solid 3px',
      position: 'relative',
      
  },
  textReporte: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '5%',
      fontFamily: 'Montserrat'

  },
  textReporte1: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '5%',
    fontFamily: 'Montserrat'
},
  inputReporte: {
      marginTop: '3%'
  },
  inputReporte2: {
    marginTop: '3%'
},
boxinputReporte: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}


}));


export default function AdminAdministradores() { 


  CleanConsole.init()  

  const [state, setState] = React.useState({
    mobileView: false,    
    administradores: [],
    administrador: [],
  });
  


  
 
  
  


  const { mobileView } = state;
  const classes = useStyles();


  React.useEffect(() => {
    validateSession();
    getAdministradores();
    getAdministrador();
  }, []);

  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
    if (localStorage.getItem('administrador_id') == undefined){
      window.location.href="/#/acceder"
    }
  }

 
  function getAdministradores(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/administrador/list", {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {    
      response.data.data.map((administrador) =>
        console.log("")
      );
      setState((prevState) => ({ ...prevState, administradores: response.data.data })
      );
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 


  function getAdministrador(administradorID){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/administrador/detail?id="+administradorID, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, administrador: response.data.data }));
      
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  

  const tipo_reporte = React.useRef();
  const tipo_file = React.useRef();
  const [Fecha,setFecha] = React.useState("");
  const [Fecha2,setFecha2] = React.useState("");


  
  function generarReporte(fecha1 , fecha2){ 
    var url=localStorage.getItem('URL') + "/main/api/reporte/";
    var nameFile=""
    if (tipo_reporte.current.value == "noticia") {
      url+="noticia/"
      if (tipo_file.current.value == "excel"){
          url+="excel?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
          nameFile="Reporte.xls"
      }
      if(tipo_file.current.value == "pdf"){
          url+="pdf?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
          nameFile="Reporte.pdf"
      }}
    if (tipo_reporte.current.value == "usuario") {
      url+="usuario/"
      if (tipo_file.current.value == "excel"){
        url+="excel?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
        nameFile="Reporte.xls"
      }
      if(tipo_file.current.value == "pdf"){
        url+="pdf?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
        nameFile="Reporte.pdf"
    }}
    if (tipo_reporte.current.value == "categoria") {
        url+="categoria/"
        if (tipo_file.current.value == "excel"){
          url+="excel?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
          nameFile="Reporte.xls"
        }
        if(tipo_file.current.value == "pdf"){
          url+="pdf?fecha_inicio="+fecha1+"&fecha_fin="+fecha2;
          nameFile="Reporte.pdf"
      }
    
    };
    
    
    axios
    .get(url, {
        responseType: 'blob',
        headers: {
            'Administrador-Id': localStorage.getItem("administrador_id"),
            'Session-Token': localStorage.getItem("session_token")
          }
    }
    )
    .then((response) => {
      fileDownload(response.data, nameFile) 

      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/administrador/logout', data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box></Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box>
          <Box>
          <Box>            
            <img alt=""  src={FondoArriba} className={classes.fondoArriba}></img>            
            <Box>
              <Typography variant="h4" className={classes.bienvenido}>
                Bienvenido {localStorage.getItem("nombre")}
              </Typography>                        
            </Box>
            
            <Box className={classes.barraMenu} >
            <Button className={classes.btnAdministradores} variant="text" href="#/menu/admin/administradores">Administradores</Button>
            <Button className={classes.btnUsuarios} variant="text" href="#/menu/admin/usuarios">Usuarios</Button>
            <Button className={classes.btnCategorias} variant="text" href="#/menu/admin/categorias">Categorías</Button>
            <Button className={classes.btnNoticias} variant="text" href="#/menu/admin/noticias">Admins. Experiencias</Button>
            <Button className={classes.btnReportes} variant="text" href="#/menu/admin/reportes">Reportes</Button>
            <Button className={classes.btnCerrar} variant="text" onClick={cerrarSesion} href="#/">Cerrar</Button>
            </Box>
            </Box>
        </Box>
        <Box className={classes.boxTodo}>
            <Box className={classes.boxInputs}>                
                <Typography className={classes.textReporte1} >Tipo de reporte: &nbsp; &nbsp; </Typography><br></br>
                <Box className={classes.boxinputReporte}>
                <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputReporte} inputRef={tipo_reporte}>
                    <option>Selecciona...</option>
                    <option value="noticia">Noticias</option>
                    <option value="usuario">Usuarios</option>
                    <option value="categoria">Categorías</option>
                </NativeSelect><br></br><br></br><br></br>
                </Box>
                <Typography className={classes.textReporte} >Tipo de Archivo:&nbsp; &nbsp; </Typography><br></br>
                <Box className={classes.boxinputReporte}>
                <NativeSelect className={classes.inputReporte} inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={tipo_file}>
                    <option>Selecciona...</option>
                    <option value="pdf">PDF</option>
                    <option value="excel">Excel</option>
                </NativeSelect> <br></br><br></br><br></br>
                </Box>
                <Typography className={classes.textReporte} >Fecha de inicio:&nbsp; &nbsp; </Typography><br></br>
                <Box className={classes.boxinputReporte}>
                <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  type="date" className={classes.inputReporte2} onChange={(e)=>setFecha(e.target.value)} /> <br></br><br></br><br></br>
                </Box>
                <Typography className={classes.textReporte} >Fecha de Fin:&nbsp; &nbsp; </Typography><br></br>
                <Box className={classes.boxinputReporte}>
                <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="date"  className={classes.inputReporte2} onChange={(e)=>setFecha2(e.target.value)}/> <br></br><br></br><br></br>
                </Box>
                <Box className={classes.boxbtnCrear}>
                <Button variant="contained" className={classes.btnCrear}   onClick={() => generarReporte(Fecha, Fecha2)}>Generar reporte</Button>
                </Box>

            </Box>


        </Box>



          <Box >   
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img  alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>            
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }