import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import  NativeSelect from '@material-ui/core/NativeSelect';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import HomeImage from './images/home-37.png';
import CargarImage from './images/cargar.png';
import EscribirImage from './images/escribir.png';
import OpcionesImage from './images/opciones.png';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import subeExperiencia from './images/sube-experiencia.png';
import enviar from './images/enviar.png';
import aprobado from './images/aprobado.png';
import pendiente from './images/pendiente.png';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import logout from './images/logout.png';
import fondoNoticia from './images/fondoNoticia.png';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import ReactHtmlParser from 'react-html-parser'; 
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import editarIcon from './images/editar_perfil-43-43.png';
import { CleanConsole } from '@eaboy/clean-console';








const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  fondoArriba: {
    width: '100%',
    height: '38vh',
    minHeight: '270px'

  }, 
  homeFoto: {  
    width: '65%',
    position: 'relative',
    
  },
  opcionesFoto: {  
    height: '2.8em',
    position: 'absolute',
    left: '0.8%',
    top:'1%'     
  },
  opcionesFoto2: {  
    width: '65%',
    position: 'relative',
    marginTop: '0.3em'     
  },
  escribirFoto: {   
    width: '65%',
    position: 'relative',
  },
  cargarFoto: {   
    width: '65%',
    position: 'relative',
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '4.5em',
    height: '38vh',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '270px'

  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '30%'
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '10%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  acceder: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    width: '300px'
  },
  experiencias: { 
    fontSize: '3em',
    fontWeight: '1000',
    color: '#213361',
    marginTop: '2%',
    width: '94%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  boxNoticia: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '70%',
    height: '25em',
    marginTop: '3%',
    left: '15%',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#8f9fbe',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '20px 15px 10px  #8f9fbe',
    
  },
  boxNoticiaMobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '85%',
    height: '40em',
    marginTop: '5%',
    left: '7%',
    position: 'relative',
    border: '3px',
    borderRadius: '1em',   
  },
  boxNoticias: {
  },
  boxNoticiasMobile: {
    marginTop: '20%',
    
  },
  textNombre: {
    color: 'white',
    fontSize: '170%',
    top: '10%',
    left: '5%',
    width: '90%',
    position: 'relative',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textNombreMobile: {
    color: 'white',
    fontSize: '130%',
    top: '5%',
    left: '5%',
    width: '94%',
    position: 'relative',
    fontWeight: 'bold',
    maxHeight: '15%',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textDescripcion: {
    color: 'white',
    fontSize: '120%',    
    position: 'relative',
    fontFamily: 'Montserrat'
  },  
  textEstado: {
    color: 'white',
    fontSize: '150%',
    top: '80%',
    left: '5%',
    width: '10%',
    position: 'absolute',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textEstadoMobile: {
    color: 'white',
    fontSize: '120%',
    top: '90%',
    left: '5%',
    position: 'absolute',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textCaracteres: {
    fontWeight: 'lighter',
    fontSize: '50%',
    color: '#EEEDED',
    fontFamily: 'Montserrat'
  },
  textCaracteresM: {
    fontWeight: 'lighter',
    fontSize: '50%',
    color: '#EEEDED',
    fontFamily: 'Montserrat'
  },
  textEstado2: {
    color: 'white',
    fontSize: '150%',
    top: '34%',
    left: '15%',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  logoutFoto: {   
    width: '100%',
    position: 'relative',
    marginBottom: '0.3em'
  },
  textEstado2Mobile: {
    color: 'white',
    fontSize: '120%',
    top: '11%',
    left: '35%',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxDescripcion: {
    top: '15%',
    left: '5%',
    position: 'relative',
    width: '92%',
    overflow: 'hidden',
    display: '-webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical;',

  },
  boxDescripcionMobile: {
    top: '8%',
    left: '5%',
    position: 'relative',
    width: '93%',
    overflow: 'hidden',
    display: '-webkit-box; -webkit-line-clamp: 14; -webkit-box-orient: vertical;',

  },
  opcionesFotoMobile: {  
    height: '40px',
    position: 'absolute',
    left: '4.6%',
    top:'3em'     
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '100%',
    height: '40px',
    top: '3em'
  },
  opcionesFoto2Mobile: {  
    height: '100%',
    position: 'absolute',
    left: '5%',
  },
  homeFotoMobile: {  
    height: '99%',
    position: 'absolute',
    left: '25%',
    
    
  },
  escribirFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '46%',
  },
  cargarFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '66%',
    
  },
  fondoArribaMobile: {
    width: '100%',
    height: '8em',
  }, 
  registrarteMobile: {
    fontSize: '2em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '1%',
    left: '5%',
    fontFamily: 'Montserrat'
  },
  accederMobile: {
    width: '45% ',
    marginTop: '2%',
    left: '27%',
    position: 'relative',
  },
  experienciasMobile: { 
    fontSize: '1.8em',
    fontWeight: '1000',
    color: '#213361',
    marginTop: '5%',
    marginBottom: '5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  boxImage: {
    width: '73%',
    height: '12%',
    top: '80%',
    left: '20%',
    position: 'absolute',
  },
  boxImageMobile: {
    width: '70%',
    height: '12%',
    top: '35.5em',
    left: '30%',
    position: 'absolute',
  },
  editarFoto: {
    left: '60%',
    position: 'relative'
  },
  fondoModalInsertar: {
    position: 'relative',   
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflowX:'hidden',
    ['@media (max-width:1300px)']:{
      left: '50%',
      width: '70%'
    },
  },
  fondoModalInsertarME: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '100%',
    position: 'relative',    
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '2px',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden'
  },
  iconBtn:{
    position:'absolute',
    left: '90%',
    top: '2%',
    borderBottomLeftRadius: '10px 10px',
  }, 
  textCategoria: {
    color: 'white',
    fontSize: '150%',
    top: '2.8em',
    left: '5%',
    width: '30%', 
    position: 'absolute', 
    fontFamily: 'Montserrat'
  },
  inputCategoria: {
    backgroundColor: 'white',
    top: '4.5em',
    left: '35%',
    width: '20%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 10px'
  },
  textCorreo: {
    color: 'white',
    fontSize: '150%',
    width: '30%',
    top: '5.5em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  
  textAño: {
    color: 'white',
    fontSize: '150%',
    top: '8.3em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textAñoMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    marginTop: '2%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputAñoMobile: {
    backgroundColor: 'white',
    left: '33%',
    width: '60%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  textPais: {
    color: 'white',
    fontSize: '150%',
    top: '10.5em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPaisMobile: {
    color: 'white',
    fontSize: '115%',
    top: '1.5em',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  inputPaisMobile: {
    backgroundColor: 'white',
    left: '32.5%',
    width: '60%',
    border: '3px',    
    borderRadius: '10px',
    position: 'relative',
    padding: '0 5px'

  },
  textCiudad: {
    color: 'white',
    fontSize: '150%',
    top: '12.7em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudadMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    top: '1.8em',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  inputCiudadMobile: {
    backgroundColor: 'white',
    left: '43%',
    width: '50%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  textDescripcionModal: {
    color: 'white',
    fontSize: '150%',
    top: '15em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textDescripcionMobile: {
    color: 'white',
    fontSize: '95%',
    left: '2%',
    fontFamily: 'Montserrat'
  },
  textPorque: {
    color: 'white',
    fontSize: '150%',
    top: '24.2em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },  
  textPorqueMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    width: '93%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },  
  textResultados: {
    color: 'white',
    fontSize: '150%',
    top: '33.5em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textComentarios: {
    color: 'white',
    fontSize: '150%',
    top: '52.5em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textOds: {
    color: 'white',
    fontSize: '150%',
    top: '43em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textOdsM: {
    color: 'white',
    fontSize: '150%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textId: {
    color: 'white',
    fontSize: '120%',
    top: '100em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textIdMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textResultadosMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    position: 'relative',
    width: '94%',
    fontFamily: 'Montserrat'
  },
  textComentariosMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textImagenes: {
    color: 'white',
    fontSize: '150%',
    top: '61em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenesEditar: {
    color: '#B5B2B2',
    fontSize: '150%',
    top: '61em',
    left: '25%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenesEditar2: {
    color: '#B5B2B2',
    fontSize: '150%',
    top: '66em',
    left: '25%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenesEditar3: {
    color: '#B5B2B2',
    fontSize: '150%',
    top: '71em',
    left: '45%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenesMobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    width: '94%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textImagenesMobileEditar: {
    color: 'white',
    fontSize: '110%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textImagenes2: {
    color: 'white',
    fontSize: '150%',
    top: '66em',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes2Mobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    width: '94%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  inputCorreo: {
    backgroundColor: 'white',
    top: '8em',
    left: '43%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute',
  },
  pendienteFoto: {
    top: '20%',
    position: 'relative',
    
  },
  pendienteFotoMobile: {
    top: '20%',
    position: 'relative',
    width: '6em',
    marginLeft: '5%'
  },
  aprobadoFotoMobile: {
    top: '8%',
    position: 'relative',
    width: '8em',
    marginLeft: '5%'
  },
  inputAño: {
    backgroundColor: 'white',
    top: '12.5em',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputPais: {
    backgroundColor: 'white',
    top: '16em',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 10px'
  },
  inputCiudad: {
    backgroundColor: 'white',
    top: '19em',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputDescripcion: {
    backgroundColor: 'white',
    top: '25em',
    left: '5%',
    width: '89%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    height: '11em',
    overflowY: 'scroll',
    padding: '0 0.5em'
  },
  inputPorque: {
    backgroundColor: 'white',
    top: '40em',
    left: '5%',
    width: '89%',
    border: '3px',   
    height: '10em', 
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.5em'

  },
  inputResultados: {
    backgroundColor: 'white',
    top: '53.2em',
    left: '5%',
    height: '11em',
    width: '89%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.5em'
  },
  inputComentarios: {
    backgroundColor: 'white',
    top: '82em',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputId: {
    backgroundColor: 'white',
    top: '123em',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputIdMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  inputImagen1: {
    backgroundColor: 'white',
    top: '95em',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen1Mobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  inputImagen2: {
    backgroundColor: 'white',
    top: '102em',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen2Mobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  inputImagen3: {
    backgroundColor: 'white',
    top: '110em',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen3Mobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  textImagenes3: {
    color: 'white',
    fontSize: '150%',
    top: '71em',
    left: '5%',
    position: 'absolute'
  },
  textImagenes3Mobile: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    position: 'relative'
  },
  textFormato: {
    color: 'white',
    fontSize: '130%',
    fontFamily: 'Montserrat'
  },
  boxtextFormato: {
    top: '115em',
    width: '90%',
    left: '5%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxbotonAcceder3: {
    width: '100%',           
    top: '130em',
    position: 'absolute',
    marginBottom: '5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    
  },
  botonAcceder3: {
    width: '15em',    
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px',    
  },
  botonAcceder3Mobile: {
    width: '60%',           
    left: '20%',
    position: 'relative',
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px',
    marginBottom: '5%',
    marginTop: '5%'
    
  },
  editbtn: {
    position:'relative',
    left: '70%',
    cursor: 'pointer',
    color: 'white'
  },
  editbtnMobile: {
    position:'relative',
    left: '3%',
    cursor: 'pointer',
    color: 'white'
  },
  more: {
    position: 'absolute',
    left: '75%',
    cursor: 'pointer',
    color: 'white'
  },
  moreMobile: {
    position: 'absolute',
    left: '75%',
    cursor: 'pointer',
    color: 'white'
  },
  espacio: {
    position: 'absolute',
    width: '5%',
    height: '10%',
    top: '200%'
  },
  divError4: {
    backgroundColor: '#ff9090',
    height: '6em',
    width: '35%',
    left: '60%',
    top: '125em',
    position: 'absolute',  
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    fontFamily: 'Montserrat'
  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '20%',
    left: '70%',
    top: '70%',
    position: 'fixed',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  fondoModalInsertar2: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto',
 
  },
  titleModalInsertar: {
    fontSize: '200%',
    fontWeight: 'bold',
    left: '10%',
    width: '75%',
    position: 'relative',
    color: '#002A68',
    marginTop: '3%',
    fontFamily: 'Montserrat'
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '80%',    
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1.2em',
    marginTop: '5%',
    marginBottom: '2% '
  },
  textPais2: {
    color: 'white',
    position: 'relative',
    fontSize: '150%',
    top: '5%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais3: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10',
    fontFamily: 'Montserrat'
  },
  textAño2: {
    color: 'white',
    position: 'relative',
    fontSize: '150%',
    top: '11%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '170%',
    top: '11%',
    left: '20%',
    fontFamily: 'Montserrat'
  },
  textDescripcion2: {
    color: 'white',
    position: 'relative',
    fontSize: '150%',
    top: '17%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextDescripcion3: {
    position: 'relative',
    width: '90%',
    height: '33%',
    top: '23%',
    left: '5%'   ,
    overflow: 'hidden',
    textAlign: 'justify'
  },
  textDescripcion3: {
    color: '#e3e4e5',
    fontSize: '110%',
    fontFamily: 'Montserrat'
      
  },
  boxFotoDescripcion1: {
    backgroundColor: 'white',
    width: '330px',
    height: '206px',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
    marginLeft: '5%',
    overflow: 'hidden',
    ['@media (max-width:1300px)']:{
      width: '220px',
      height: '138px',
    },

  },
  textResultados2: {
    color: 'white',
    position: 'relative',
    fontSize: '150%',
    top: '58%',
    left: '45%',
    width: '45%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextResultados3: {
    position: 'relative',
    width: '95%',
    left: '5%'    ,
    overflow: 'hidden',
    textAlign: 'justify'

  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '110%',
    width: '97%',
    fontFamily: 'Montserrat'
  },
  boxFotoDescripcion2: {
    position: 'relative',
    backgroundColor: 'white',
    width: '330px',
    height: '206px',   
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
    marginRight: '5%',
    overflow: 'hidden',
    ['@media (max-width:1300px)']:{
      width: '220px',
      height: '138px',
    },

  },
  imagen1: {
    border: '3px',
    borderRadius: '20px',
    display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'
  },
  fondoModalInsertarMobile: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflowX: 'hidden',
  },
  iconBtnMobile: {
    left: '78%',
    top: '-1%',
    position: 'absolute',
  },
  iconBtnME: {
    left: '80%',
    top: '-1%',
    position: 'absolute',
  },
  titleModalInsertarMobile: {
    fontSize: '150%',
    fontWeight: 'bold',
    left: '5%',    
    width: '94%',
    position: 'relative',
    color: '#002A68',
    marginTop: '20%',
    fontFamily: 'Montserrat'
  },
  fondoFormulario2Mobile: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '120%',
    width: '80%',    
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '20%',
    marginBottom: '5%'
  },
  textPais2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '130%',
    top: '1%',
    left: '10%',
    width: '89%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10'

  },
  textAño2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '130%',
    top: '3%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '130%',
    top: '3%',
    left: '40%',
  },
  textDescripcion2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '130%',
    top: '5%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextDescripcion3Mobile: {
    position: 'relative',
    width: '85%',
    height: '39.5%',
    top: '7%',
    left: '10%'   ,
    overflow:'hidden',
    textAlign: 'justify'

  },
  textDescripcion3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '95%',
    fontFamily: 'Montserrat'
      
  },
  boxFotoDescripcion1Mobile: {
    position: 'relative',
    backgroundColor: 'white',
    width: '85%',
    height: '10em',
    top: '47%',
    left: '10%'   ,
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'

  },
  textResultados2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '130%',
    top: '57.5%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextResultados3Mobile: {
    position: 'relative',
    width: '85%',
    height: '27.5%',
    top: '60%',
    left: '10%'    ,
    overflow: 'hidden',
    textAlign: 'justify'

  },
  textResultados3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '95%',
    fontFamily: 'Montserrat'
  },
  boxFotoDescripcion2Mobile: {
    position: 'relative',
    backgroundColor: 'white',
    width: '85%',
    height: '10em',
    top: '88%',
    left: '10%'   ,
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'

  },
  boxOds: {
    width: '89%',
    position: 'absolute',
    left: '5%',
    height: '10em',
    top: '67em',
    backgroundColor: 'white',
    border: '1px',
    borderRadius: '10px',
    overflowX: 'auto',
    padding: '1%'
  },
  boxOdsM: {
    width: '89%',
    position: 'relative',
    left: '5%',
    height: '13em',
    backgroundColor: 'white',
    border: '1px',
    borderRadius: '10px',
    overflowX: 'auto',
    padding: '1%',
    marginBottom: '0.5em'
  },
  textCategoriaMobile: {
    color: 'white',
    fontSize: '120%',
    marginTop: '10%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'

  },
  inputCategoriaMobile: {
    backgroundColor: 'white',
    left: '15%',
    width: '70%',
    border: '3px',    
    borderRadius: '10px',
    position: 'relative',
    padding: '0 5px'
  },
  textCorreoMobile: {
    color: 'white',
    fontSize: '115%',
    width: '95%',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  inputCorreoMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '90%',
    border: '3px',
    borderRadius: '15px',
    position: 'relative'
  },
  textDescripcionMobileE: {
    color: 'white',
    fontSize: '115%',
    left: '5%',
    width: '94%',
    position: 'relative'
  },
  inputDescripcionMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '89%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative',
    height: '15em',
    overflowY: 'scroll',
    padding: '0 0.2em',
    marginBottom: '0.5em'
  },
  inputPorqueMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative',
    padding: '0 0.2em',
    marginBottom: '0.5em',
    height: '15em',
    overflowY: 'scroll',
  },
  inputResultadosMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative',
    padding: '0 0.2em',
    marginBottom: '0.5em',
    height: '15em',
    overflowY: 'scroll',
  },
  inputComentariosMobile: {
    backgroundColor: 'white',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  },
  textFormatoMobile: {
    color: 'white',
    fontSize: '100%',
    left: '8%',
    width: '85%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  divExtio2Mobile: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '70%',
    left: '15%',
    top: '80%',
    position: 'fixed',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  
  },
  divError4Mobile: {
    backgroundColor: '#ff9090',
    height: '20%',
    width: '94%',
    left: '3%',
    position: 'relative',  
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textNoHay: {
    position: 'relative',
    fontWeight: 'bold',
    color: '#002A68'
  },
  textNoHayMobile: {
    fontWeight: 'bold',
    color: '#002A68'
  },
  fondoModalFormularioMobile: {
    position: 'relative',
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '100%',
  },
  logoutFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '85%',
    
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  fondoFormulario: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '100%',
    width: '100%',
    height: '140em'
  },
  textodsL: {
    fontFamily: 'Montserrat'
  },
  boxOdses: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    left: '5%',
    alignItems: 'center',  
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  fotoOds: {
    width: '5em',
    height: 'auto',
    margin: '0.5em'
  },
  fotoOdsM: {
    width: '2.5em',
    height: 'auto',
    margin: '0.2em'
  },
  boxOdsMobile: {
    width: '80%',
    left: '10%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5%',
    flexWrap: 'wrap',
  },
  

  

}));

export default function MenuPage() {
  

  CleanConsole.init()

  const [errorMessage, setError] = useState([]); 
  const [validacionMensaje, cambiarValidacionMensaje] = useState(false);

  const [mostrarMenu, MostrarMenu] = useState(false);
  const [idRowNow, setIdRowNow] = useState(0);

  const [errorFormulario, cambiarErrorFormulario] = useState(false);

  const imagen1Editar = React.useRef();
  const imagen2Editar = React.useRef();
  const pdfEditar = React.useRef();

  const MAX_LENGTH = 1000;
  const html = "";
  const blocksFromHTML = convertFromHTML(html)
  const content = ContentState.createFromBlockArray(blocksFromHTML)

  const [stateText, setStateText] = React.useState({
    editorState: EditorState.createWithContent(content)   
  });
  const {editorState} = stateText;


  const DescripcionText = draftToHtml(convertToRaw(editorState.getCurrentContent()))


       const  _handleBeforeInput = () => {
          const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length
    
          if (currentContentLength > MAX_LENGTH - 1) {
            alert('El contenido tiene mas de 1000 caracteres');
    
            return 'handled';
        }
      }
      
      const _handlePastedText = (pastedText) => {
          const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length
    
          if (currentContentLength + pastedText.length > MAX_LENGTH) {
            alert('El contenido tiene mas de 1000 caracteres');
    
            return 'handled';
        }
      }
  
  const onEditorStateChange = (editorState) => {
    setStateText({
        editorState,
    });}

    const [stateText2, setStateText2] = React.useState({
      editorState2: EditorState.createEmpty(),   
    });
  
    
    const {editorState2} = stateText2;
    const DescripcionText2 = draftToHtml(convertToRaw(editorState2.getCurrentContent()))
  
  
         const  _handleBeforeInput2 = () => {
            const currentContent = editorState2.getCurrentContent();
          const currentContentLength = currentContent.getPlainText('').length
      
            if (currentContentLength > MAX_LENGTH - 1) {
              alert('El contenido tiene mas de 1000 caracteres');
      
              return 'handled';
          }
        }
        
        const _handlePastedText2 = (pastedText) => {
            const currentContent = editorState2.getCurrentContent();
          const currentContentLength = currentContent.getPlainText('').length
      
            if (currentContentLength + pastedText.length > MAX_LENGTH) {
              alert('El contenido tiene mas de 1000 caracteres');
      
              return 'handled';
          }
        }
    
    const onEditorStateChange2 = (editorState2) => {
      setStateText2({
          editorState2,
      });}

      const [stateText3, setStateText3] = React.useState({
        editorState3: EditorState.createEmpty(),   
      });
    
      
      const {editorState3} = stateText3;
      const DescripcionText3 = draftToHtml(convertToRaw(editorState3.getCurrentContent()))
    
    
           const  _handleBeforeInput3 = () => {
              const currentContent = editorState3.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length
        
              if (currentContentLength > MAX_LENGTH - 1) {
                alert('El contenido tiene mas de 1000 caracteres');
        
                return 'handled';
            }
          }
          
          const _handlePastedText3 = (pastedText) => {
              const currentContent = editorState3.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length
        
              if (currentContentLength + pastedText.length > MAX_LENGTH) {
                alert('El contenido tiene mas de 1000 caracteres');
        
                return 'handled';
            }
          }
      
      const onEditorStateChange3 = (editorState3) => {
        setStateText3({
            editorState3,
        });
  };



  
  function mostrarOtro(){
    MostrarMenu(true)
    
  }
  function ocultarOtro(){
    MostrarMenu(false)
  }

  function refreshPage() {
    window.location.reload(false);
  }

  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/logout', data, {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }

  function getOdses(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/ods/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, odses: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function validarPeso(){
    const oFile = document.getElementById("imagen1").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen1").value = "";
      cambiarValidacionMensaje(true)
      setTimeout(() => cambiarValidacionMensaje(false), 5000);    
    return;
  }
  }
  function validarPesoM(){
    const oFile = document.getElementById("imagen1M").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen1M").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso2(){
    const oFile = document.getElementById("imagen2").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen2").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso2M(){
    const oFile = document.getElementById("imagen2M").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen2M").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso3(){
    const oFile = document.getElementById("pdf").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("pdf").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso3M(){
    const oFile = document.getElementById("pdfM").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("pdfM").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }

  function enviarNoticia(){   
    var bodyFormData = new FormData();
      bodyFormData.append('id', idRowNow);
      bodyFormData.append('categoria_id', categoriaRef.current.value);
      bodyFormData.append('nombre', nombreExperienciaRef.current.value);
      bodyFormData.append('ano', añoRef.current.value);
      bodyFormData.append('pais_id', paisRef.current.value);
      bodyFormData.append('ciudad', ciudadRef.current.value);
      bodyFormData.append('descripcion', DescripcionText);
      bodyFormData.append('razon_innovadora', DescripcionText2);
      bodyFormData.append('resultados', DescripcionText3);
      bodyFormData.append('imagen1', imagen1Editar.current.files[0]);      
      bodyFormData.append('imagen2', imagen2Editar.current.files[0]);     
      bodyFormData.append('pdf', pdfEditar.current.files[0]); 
      var markedCheckbox = document.getElementsByName('ods');
      for (var checkbox of markedCheckbox) {
        if (checkbox.checked)
          bodyFormData.append("ods",checkbox.value);
      }

    
    axios({
      method: "put",
      url: localStorage.getItem('URL') + "/main/api/noticia/update",
      data: bodyFormData,
      headers: { 'Session-Token': localStorage.getItem("session_token"),
         'Usuario-Id': localStorage.getItem("usuario_id"),
         },
      
    })
    .then((response) => {
      cambiarFormularioEnviado(true);
      setTimeout(() => cambiarFormularioEnviado(false), 5000);
      handleClose1();
      setTimeout(() => refreshPage(), 2000)
      
          
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000); 
      
      
      
      });
  }
  const categoriaRef = React.useRef();
  const añoRef = React.useRef();
  const nombreExperienciaRef = React.useRef();
  const paisRef = React.useRef();
  const ciudadRef = React.useRef(); 
  const descripcionRef = React.useRef(); 
  const porqueRef = React.useRef(); 
  const resultadosRef = React.useRef(); 
  const [imagenes, setImagenes] = React.useState({

  });
  const [OdsEstado, setOds] = React.useState({

  });
  
  const [noticias, setNoticias] = useState([]);
      
  const [state, setState] = React.useState({
    mobileView: false,
    noticia: [],
    noticias: [],
    paises: [],
    odses: [],
    categorias: [],
    ods_found: false,

  });

  function handleChangeEvent(event) {
  
    setState((prevState) => ({ ...prevState, noticia: {
      [event.target.name]: event.target.value,
    } 
  }));
  }


  function getNoticias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/noticia/list", {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
      setNoticias(response.data.data)
     
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  React.useEffect(() => {   
    validateSession(); 
    getNoticias();
    getCategorias();
    getPaises();
    getOdses();

  }, []);
  
  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
  }

  function getCategorias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/list", {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, categorias: response.data.data })
      );
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, paises: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function getExperiencia(noticiaHash){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/noticia/detail?hash="+noticiaHash, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, noticia: response.data.data }));
      setImagenes(response.data.data);
      setOds(response.data.data);
      const blocksFromHTML = convertFromHTML(response.data.data.descripcion)
      const content = ContentState.createFromBlockArray(blocksFromHTML)   
      const blocksFromHTML2 = convertFromHTML(response.data.data.razon_innovadora)
      const content2 = ContentState.createFromBlockArray(blocksFromHTML2)   
      const blocksFromHTML3 = convertFromHTML(response.data.data.resultados)
      const content3 = ContentState.createFromBlockArray(blocksFromHTML3)   
      setStateText((prevState) => ({ ...prevState, editorState: EditorState.createWithContent(content) }));
      setStateText2((prevState) => ({ ...prevState, editorState2: EditorState.createWithContent(content2) }));
      setStateText3((prevState) => ({ ...prevState, editorState3: EditorState.createWithContent(content3) }));

      
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };


  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);


 
 

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>
        <Box>
        <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFotoMobile}></img></a>
              { mostrarMenu && <Box className={classes.boxHomeMobile}>
              <a  onClick={ocultarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto2Mobile}></img></a>
              <a  href="#/menu" ><img alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFotoMobile}></img></a>
              <a href="#/menu/validaciones"><img alt="" src={CargarImage} className={classes.cargarFotoMobile}></img></a>
              <a href="#/"><img alt="" src={logout} className={classes.logoutFotoMobile} onClick={cerrarSesion}></img></a>
            </Box>  }
            <img alt="" src={FondoArriba} className={classes.fondoArribaMobile}></img>
            <Box>
            <a href="#/menu/subeExperiencia" ><img alt="" src={subeExperiencia}className={classes.accederMobile}></img></a> 
            </Box>
            <Typography className={classes.registrarteMobile}>
            {localStorage.getItem("nombre")}
            </Typography> 
            <Typography className={classes.experienciasMobile}>
            Mis Experiencias
            </Typography> 
            <Modal
          open={open2}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertarMobile}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtnMobile}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>

            <Typography className={classes.titleModalInsertarMobile} variant="h6" component="h2">
            {state.noticia.nombre}
            </Typography>
            <Box className={classes.fondoFormulario2Mobile}><br></br>
            <Typography className={classes.textPais2Mobile}>País: <a className={classes.textPais3Mobile}>{state.noticia.pais_nombre == "" ? "Sin información": state.noticia.pais_nombre}</a></Typography><br></br>
            <Typography className={classes.textAño2Mobile}>Año: <a className={classes.textPais3Mobile}>{state.noticia.ano == null ? "Sin información": state.noticia.ano}</a></Typography><br></br>
            <Typography className={classes.textDescripcion2Mobile}>Descripcion</Typography><br></br>
            <Box className={classes.boxTextDescripcion3Mobile}>            
            <Typography className={classes.textDescripcion3Mobile}>{ ReactHtmlParser(state.noticia.descripcion)}</Typography>            
            </Box><br></br>
            <Box className={classes.boxFotoDescripcion1Mobile}>
            {state.noticia.imagen1_filename != '' ? <img alt="" src={state.noticia.imagen1_filename} className={classes.imagen1} ></img> :<img  alt="" src={fondoNoticia} className={classes.imagen1} ></img>}
            </Box><br></br>
            <Typography className={classes.textResultados2Mobile}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3Mobile}>
            <Typography className={classes.textResultados3Mobile}>{ ReactHtmlParser(state.noticia.resultados)}</Typography>            
            </Box><br></br>
            <Box className={classes.boxFotoDescripcion2Mobile}>
            {state.noticia.imagen2_filename != '' ? <img alt="" src={state.noticia.imagen2_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} className={classes.imagen1} ></img>}
            </Box>  <br></br><br></br> 
            <Box >
              {OdsEstado.odses != undefined ?
                          <Box className={classes.boxOdsMobile}>                            
                            {OdsEstado.odses.map(noticia_ods=>(                        
                          <img alt="" key={noticia_ods}className={classes.fotoOdsM} src={noticia_ods.imagen}></img> 
                        ))}

                          </Box>:<Box></Box>
              }
            </Box><br></br>       

            </Box>
              
          </Box>
        </Modal>
        <Modal                   
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertarME}>              
            <IconButton
              aria-label="close"
              onClick={handleClose1}
              className={classes.iconBtnME}
            >              
              <CancelPresentationIcon fontSize="large" />
            </IconButton><br></br>
                    <Typography  className={classes.textCategoriaMobile}>Elige una categoria</Typography><br></br>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCategoriaMobile} inputRef={categoriaRef} size="small" value={state.noticia.categoria_id} onChange={handleChangeEvent} >
                    <option > Categorias... </option>    
                    {state.categorias.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect><br></br><br></br>                
                <Box>
                    <Typography className={classes.textCorreoMobile}>Nombre de la experiencia</Typography><br></br> 
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCorreoMobile} placeholder='Máximo 255 caracteres' variant="outlined" size="small" inputRef={nombreExperienciaRef}  value={state.noticia.nombre} onChange={handleChangeEvent} /><br></br><br></br>         
                </Box> 
                <Box>
                    <Typography className={classes.textAñoMobile}>Año</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" className={classes.inputAñoMobile} variant="outlined" size='small' inputRef={añoRef}  value={state.noticia.ano} onChange={handleChangeEvent} />         
                </Box>
                <Box>
                    <Typography className={classes.textPaisMobile}>País</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputPaisMobile} size="small" variant="outlined" inputRef={paisRef} value={state.noticia.pais_id} onChange={handleChangeEvent} > 
                    <option value="" >Selecciona...</option>
                      {state.paises.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}   
                    </NativeSelect>        
                </Box>
                <Box>
                    <Typography className={classes.textCiudadMobile}>Ciudad</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCiudadMobile} size='small' variant="outlined" inputRef={ciudadRef}  value={state.noticia.ciudad} onChange={handleChangeEvent} /><br></br><br></br>        
                </Box>
                <Box>
                    <Typography className={classes.textDescripcionMobileE}>Descripción de la innovación: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography><br></br>
                    <Box className={classes.inputDescripcionMobile}>
                    <Editor
                      editorState={editorState}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange} 
                      handleBeforeInput={_handleBeforeInput}
                      handlePastedText={_handlePastedText}   
                     />
                     </Box>     
                   </Box>
                <Box>
                    <Typography className={classes.textPorqueMobile}>¿Por qué es una solución innovadora?: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography><br></br>
                    <Box className={classes.inputPorqueMobile}>
                    <Editor
                      editorState={editorState2}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2} 
                      handleBeforeInput={_handleBeforeInput2}
                      handlePastedText={_handlePastedText2}   
                     />
                     </Box>
                     </Box>
                <Box>
                    <Typography className={classes.textResultadosMobile}>Resultados: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography><br></br>
                    <Box className={classes.inputResultadosMobile}>
                    <Editor
                      editorState={editorState3}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange3} 
                      handleBeforeInput={_handleBeforeInput3}
                      handlePastedText={_handlePastedText3}   
                     />
                     </Box>
                    </Box>
                <Box>
                    <Typography className={classes.textOdsM}>ODS</Typography>
                    <Box className={classes.boxOdsM}>
                  {state.odses.map(elemento=>(
                      <Box  key={elemento.id}>
                        {state.ods_found = false }
                        {OdsEstado.odses != undefined ?
                          <Box>                            
                            {OdsEstado.odses.map(noticia_ods=>(                        
                          noticia_ods.id == elemento.id  ?  <Box>{state.ods_found = true}<Checkbox  name="ods" value={elemento.id}  color="primary" defaultChecked={true} ></Checkbox><label className={classes.textodsL}>{elemento.codigo}-{elemento.nombre}</label> </Box>: <Box></Box>
                        ))}

                          </Box>:<Box></Box>
                        }
                        {state.ods_found == false ? <Box><Checkbox name="ods" value={elemento.id}  color="primary" ></Checkbox><label className={classes.textodsL}>{elemento.codigo}-{elemento.nombre}</label></Box> : <Box></Box> }
                      </Box>
                        )
                      )}
                </Box>         
                </Box>
                <Box>
                    <Typography className={classes.textComentariosMobile}>Comentarios</Typography><br></br>
                    <TextField  multiline inputProps={{ style: {  fontFamily: 'Montserrat' } }}  rows={4} className={classes.inputComentariosMobile} variant="outlined"  value={state.noticia.comentario} disabled onChange={handleChangeEvent} /> <br></br><br></br>        
                </Box>
                <Box>
                    <Typography className={classes.textImagenesMobile}>Imagen 1<a style={{fontFamily: "Montserrat", fontSize: '9px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>{imagenes.imagen1_filename != "" ? <Typography className={classes.textImagenesMobileEditar}><Link href={imagenes.imagen1_filename} color="inherit" rel="noreferrer"  target="_blank" underline='always'>Imagen Actual</Link></Typography> : <Box></Box>}<br></br>
                    <TextField inputProps={{accept:"image/*", style: {  fontFamily: 'Montserrat' }}} type="file" onChange={(e) => validarPesoM()}  inputRef={imagen1Editar} id="imagen1M"  variant="outlined" className={classes.inputImagen1Mobile} size='small'  /> <br></br><br></br>                            
                    <Typography className={classes.textImagenes2Mobile}>Imagen 2<a style={{fontFamily: "Montserrat", fontSize: '9px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>{imagenes.imagen2_filename != "" ? <Typography className={classes.textImagenesMobileEditar}><Link href={imagenes.imagen2_filename} color="inherit" rel="noreferrer"  target="_blank" underline='always'>Imagen Actual</Link></Typography> : <Box></Box>}<br></br>
                    <TextField type="file" inputProps={{accept:"image/*", style: {  fontFamily: 'Montserrat' }}} id="imagen2M" onChange={(e) => validarPeso2M()}  className={classes.inputImagen2Mobile} size='small' variant="outlined" inputRef={imagen2Editar} placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField> <br></br><br></br>                   
                    <Typography className={classes.textImagenes3Mobile}>Subir PDF  <a className={classes.textCaracteresM}>(Máximo 3MB)</a></Typography>{imagenes.pdf_filename != "" ? <Typography className={classes.textImagenesMobileEditar}><Link href={imagenes.pdf_filename} color="inherit" rel="noreferrer"  target="_blank" underline='always'>PDF Actual</Link></Typography> : <Box></Box>}<br></br>
                    <TextField type="file"  className={classes.inputImagen3Mobile} inputProps={{accept:"application/pdf", style: {  fontFamily: 'Montserrat' }}} onChange={(e) => validarPeso3M()}  id="pdfM" size='small' variant="outlined" inputRef={pdfEditar}  placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField> <br></br><br></br>
                    
                     
                    <Typography className={classes.textFormatoMobile}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
máximo de 3MB</Typography>      <br></br>
                </Box>
                
                <Box>
                    <Typography className={classes.textIdMobile}>ID</Typography><br></br>
                    <TextField  className={classes.inputIdMobile} variant="outlined" size='small' disabled  value={state.noticia.id}   />    <br></br><br></br>     
                </Box>
                <img alt="" src={enviar} className={classes.botonAcceder3Mobile} onClick={enviarNoticia} >                                              
                  </img> <br></br>
                  <Box className={classes.espacio}></Box> 
                  { errorFormulario &&<div className={classes.divError4Mobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
                  { validacionMensaje && <div className={classes.divError4Mobile}><Typography className={classes.error}> El archivo pesa más de 3MB </Typography> </div> }

              </Box> 
              </Modal> 
            <Box className={classes.boxNoticiasMobile}>       
            {noticias.map(noticia =>
               <div key={noticia.id} className={classes.boxNoticiaMobile}>
                 <Typography className={classes.textNombreMobile} >{noticia.nombre}</Typography>
                 <Box className={classes.boxDescripcionMobile}>
                  <Typography className={classes.textDescripcionMobile} >{ ReactHtmlParser(noticia.descripcion)}</Typography>                 
                 </Box>
                 <Typography className={classes.textEstadoMobile} >Estado:</Typography>
                 <Box className={classes.boxImageMobile}><MoreOutlinedIcon fontSize='large' className={classes.moreMobile}  onClick={() => {
          handleOpen2();
          getExperiencia(noticia.hash );
       }}></MoreOutlinedIcon>
       {noticia.estado == 'A' ? <img alt="" src={aprobado} className={classes.aprobadoFotoMobile}></img> :<Box><img alt="" src={pendiente} className={classes.pendienteFotoMobile}></img>
        <EditIcon className={classes.editbtnMobile} fontSize='large' onClick={() => {
                    handleOpen1();
                    setIdRowNow(noticia.id);
                    getExperiencia(noticia.hash);
                }}></EditIcon>   
                                            
                </Box>
         }
         </Box>
               </div>
            )}
            { formularioEnviado && <div className={classes.divExtio2Mobile}> <Typography className={classes.exito}>Experiencia editada correctamente</Typography> </div>}
            </Box>    
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
      </Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>
          <Box>
          <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto}></img></a>
              { mostrarMenu && <Box className={classes.boxHome}>
              <a  onClick={ocultarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto2}></img></a>
              <a href="#/menu" ><img alt="" src={HomeImage} className={classes.homeFoto}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFoto}></img></a>
              <a href="#/menu/editar"><img alt="" src={editarIcon} className={classes.cargarFoto}></img></a>
              <a href="#/"><img alt="" src={logout} className={classes.logoutFoto} onClick={cerrarSesion}></img></a>
            </Box>  }
            <img alt="" src={FondoArriba} className={classes.fondoArriba}></img>
            <Box>
              <a href="#/menu/subeExperiencia" ><img alt="" src={subeExperiencia}className={classes.acceder}></img></a>
            </Box>
            <Typography className={classes.registrarte}>
            {localStorage.getItem("nombre")}
            </Typography> 
            <Typography className={classes.experiencias}>
            Mis Experiencias
            </Typography> 
            <Modal                   
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertar}>
              <IconButton
              aria-label="close"
              onClick={handleClose1}
              className={classes.iconBtn}
            >              
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box className={classes.fondoFormulario}>
                    <Typography  className={classes.textCategoria}>Elige una categoria</Typography>
                    <NativeSelect className={classes.inputCategoria} inputProps={{ style: {  fontFamily: 'Montserrat' } }} value={state.noticia.categoria_id} inputRef={categoriaRef} onChange={handleChangeEvent} >
                    <option> Categorias... </option>    
                    {state.categorias.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect>     
                </Box> 
                <Box>
                    <Typography className={classes.textCorreo}>Nombre de la experiencia</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} placeholder='El nombre de la experiencia no puede contener mas 255 caracteres' className={classes.inputCorreo} variant="outlined" size='small' inputRef={nombreExperienciaRef}  value={state.noticia.nombre} onChange={handleChangeEvent} />         
                </Box> 
                <Box>
                    <Typography className={classes.textAño}>Año</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" className={classes.inputAño} variant="outlined" size='small' inputRef={añoRef}  value={state.noticia.ano} onChange={handleChangeEvent}  />         
                </Box>
                <Box>
                    <Typography className={classes.textPais}>País</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputPais} variant="outlined" size='small' inputRef={paisRef}  value={state.noticia.pais_id} onChange={handleChangeEvent}  > 
                    <option value="" >Selecciona...</option>
                    {state.paises.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}                       
                    </NativeSelect>        
                </Box>
                <Box>
                    <Typography className={classes.textCiudad}>Ciudad</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCiudad} variant="outlined" size='small' inputRef={ciudadRef}  value={state.noticia.ciudad} onChange={handleChangeEvent}  />         
                </Box>
                <Box>
                    <Typography className={classes.textDescripcionModal}>Descripción de la innovación:  <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputDescripcion}>
                    <Editor
                      editorState={editorState}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange} 
                      handleBeforeInput={_handleBeforeInput}
                      handlePastedText={_handlePastedText}   
                     />
                     </Box>
                </Box>
                <Box>
                    <Typography className={classes.textPorque}>¿Por qué es una solución innovadora?:  <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputPorque}>
                    <Editor
                      editorState={editorState2}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2} 
                      handleBeforeInput={_handleBeforeInput2}
                      handlePastedText={_handlePastedText2}   
                     /> 
                     </Box>
                   </Box>
                <Box>
                    <Typography className={classes.textResultados}>Resultados:  <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputResultados}>
                    <Editor
                      editorState={editorState3}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange3} 
                      handleBeforeInput={_handleBeforeInput3}
                      handlePastedText={_handlePastedText3}   
                     /> 
                     </Box>                </Box>
                <Box>
                    <Typography className={classes.textOds}>ODS</Typography>
                    <Box className={classes.boxOds}>
                  {state.odses.map(elemento=>(
                      <Box key={elemento.id}>
                        {state.ods_found = false }
                        {OdsEstado.odses != undefined ?
                          <Box>                            
                            {OdsEstado.odses.map(noticia_ods=>(                        
                          noticia_ods.id == elemento.id  ?  <Box>{state.ods_found = true}<Checkbox  name="ods" value={elemento.id}  color="primary" defaultChecked={true}
                           ></Checkbox><label className={classes.textodsL}>{elemento.codigo}-{elemento.nombre}</label> </Box>: <Box></Box>
                        ))}

                          </Box>:<Box></Box>
                        }
                        {state.ods_found == false ? <Box><Checkbox name="ods" value={elemento.id}  color="primary" ></Checkbox><label className={classes.textodsL}>{elemento.codigo}-{elemento.nombre}</label></Box> : <Box></Box> }
                      </Box>
                        )
                      )}
                </Box>         
                </Box>
                <Box>
                    <Typography className={classes.textComentarios}>Comentarios</Typography>
                    <TextField  multiline inputProps={{ style: {  fontFamily: 'Montserrat' } }} rows={5} className={classes.inputComentarios} variant="outlined"  value={state.noticia.comentario} disabled onChange={handleChangeEvent}  />         
                </Box>
                <Box>
                    <Typography className={classes.textImagenes}>Imagen 1<a style={{fontFamily: "Montserrat", fontSize: '11px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>{imagenes.imagen1_filename != "" ? <Typography className={classes.textImagenesEditar}><Link href={imagenes.imagen1_filename} color="inherit" rel="noreferrer"  target="_blank" underline='always'>Imagen Actual</Link></Typography> : <Box></Box>}
                    <TextField inputProps={{accept:"image/*", style: {  fontFamily: 'Montserrat' }}} type="file" inputRef={imagen1Editar} id="imagen1" onChange={(e) => validarPeso()}  className={classes.inputImagen1} variant="outlined"  />                             
                    
                    <Typography className={classes.textImagenes2}>Imagen 2<a style={{fontFamily: "Montserrat", fontSize: '11px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>{imagenes.imagen2_filename != "" ? <Typography className={classes.textImagenesEditar2}><Link href={imagenes.imagen2_filename} color="inherit"  rel="noreferrer"  target="_blank" underline='always'>Imagen Actual</Link></Typography> : <Box></Box>}
                    <TextField type="file" inputProps={{accept:"image/*", style: {  fontFamily: 'Montserrat' }}} id="imagen2"  className={classes.inputImagen2} onChange={(e) => validarPeso2()}  variant="outlined" inputRef={imagen2Editar} placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField>
                    <Typography className={classes.textImagenes3}>Subir PDF <a className={classes.textCaracteres}>(Máximo 3MB)</a></Typography>{imagenes.pdf_filename != "" ? <Typography className={classes.textImagenesEditar3}><Link href={imagenes.pdf_filename} color="inherit" rel="noreferrer"  target="_blank" underline='always'>PDF Actual</Link></Typography> : <Box></Box>}
                    <TextField type="file" inputProps={{accept:"application/pdf", style: {  fontFamily: 'Montserrat' }}} id="pdf" className={classes.inputImagen3} onChange={(e) => validarPeso3()}  variant="outlined" inputRef={pdfEditar}  placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField>
                     <Box className={classes.boxtextFormato}>
                    <Typography className={classes.textFormato}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
                    máximo de 3MB</Typography> 
                    </Box>     
                </Box>
                <Box>
                    <Typography className={classes.textId}>ID</Typography>
                    <TextField  className={classes.inputId} variant="outlined" size='small' disabled value={state.noticia.id}   />         
                </Box>
                <Box className={classes.boxbotonAcceder3}>
                <img  alt="" src={enviar} className={classes.botonAcceder3} onClick={enviarNoticia} >                                              
                  </img> 
                </Box>
                  <Box className={classes.espacio}></Box> 
                  { validacionMensaje && <div className={classes.divError4}><Typography className={classes.error}> El archivo pesa más de 3MB </Typography> </div> }
                  { errorFormulario &&<div className={classes.divError4}><Typography className={classes.error}>{errorMessage}</Typography> </div> }
              </Box>
              </Modal> 
              <Modal
          open={open2}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertar2}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>

            <Typography className={classes.titleModalInsertar} >
            {state.noticia.nombre}
            </Typography>
            <Box className={classes.fondoFormulario2}><br></br><br></br>
            <Typography className={classes.textPais2}>País:  <a className={classes.textPais3}>{state.noticia.pais_nombre == "" ? "Sin información": state.noticia.pais_nombre}</a></Typography><br></br>
            <Typography className={classes.textAño2}>Año:  <a className={classes.textPais3}>{state.noticia.ano == null ? "Sin información": state.noticia.ano}</a></Typography><br></br>
            <Typography className={classes.textDescripcion2}>Descripcion</Typography><br></br>
            <Box className={classes.boxTextDescripcion3}> 
            <Box className={classes.boxFotoDescripcion1}>
            {state.noticia.imagen1_filename != '' ? <img alt="" src={state.noticia.imagen1_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} style={{width: '100%', height: '100%'}} ></img>}
            </Box><br></br>            
            <Typography className={classes.textDescripcion3}>{ ReactHtmlParser(state.noticia.descripcion)}</Typography>                       
            </Box> <br></br>           
            <Typography className={classes.textResultados2}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3}>
            <Box className={classes.boxFotoDescripcion2}>
            {state.noticia.imagen2_filename != '' ? <img  alt="" src={state.noticia.imagen2_filename} className={classes.imagen1} ></img> :<img  alt="" src={fondoNoticia} style={{width: '100%', height: '100%'}} ></img>}
            </Box><br></br>
            <Typography className={classes.textResultados3}>{ ReactHtmlParser(state.noticia.resultados)}</Typography>            
            </Box><br></br>
            <Box >
            {OdsEstado.odses != undefined ?
                          <Box className={classes.boxOdses}>                            
                            {OdsEstado.odses.map(noticia_ods=>(                        
                          <img alt="" key={noticia_ods} className={classes.fotoOds} src={noticia_ods.imagen}></img> 
                        ))}

                          </Box>:<Box></Box>
                        }
            </Box><br></br>
            
            </Box>
              
          </Box>
        </Modal> 
            <Box className={classes.boxNoticias}>       
            {noticias.map(noticia =>
               <div key={noticia.id} className={classes.boxNoticia}>
                 <Typography className={classes.textNombre} >{noticia.nombre}</Typography>
                 <Box className={classes.boxDescripcion}>
                  <Typography className={classes.textDescripcion} >{ ReactHtmlParser(noticia.descripcion)}</Typography>                 
                 </Box>
                 <Typography className={classes.textEstado} >Estado:</Typography>                 
                 <Box className={classes.boxImage}><MoreOutlinedIcon className={classes.more} fontSize='large' onClick={() => {
          handleOpen2();
          getExperiencia(noticia.hash );
       }}></MoreOutlinedIcon>
       
       {noticia.estado == 'A' ? <Box><img alt="" src={aprobado} className={classes.aprobadoFoto}></img></Box> :<Box><img alt="" src={pendiente} className={classes.pendienteFoto}></img>
                <EditIcon fontSize='large' className={classes.editbtn} onClick={() => {
                    handleOpen1();
                    setIdRowNow(noticia.id);
                    getExperiencia(noticia.hash);
                }}></EditIcon>                  
                 
              </Box> }
              </Box>
               </div>
            )}
            { formularioEnviado && <div className={classes.divExtio2}> <Typography className={classes.exito}>Experiencia Editada correctamente</Typography> </div>} 
            </Box>    
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }