import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-05.png';
import circulos from './images/circulos-06.png';
import HomeImage from './images/home-37.png';
import Acceder from './images/acceder-01-R.png';
import axios from 'axios';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { CleanConsole } from '@eaboy/clean-console';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  textoArriba: {
    fontSize: '4em',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    top: '30%',
    left: '14%'
  },
  fondoFormulario: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '70%',    
    height: '25em',
    left: '15%',
    marginTop: '5em',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    // eslint-disable-next-line
    ['@media (max-height:750px)']:{
      width: '60%',    
      height: '20rem',
      left: '20%',
      marginTop: '2em',

    },
  },
  fondoArriba: {
    width: '100%',
    height: '15em'
  },
  textCorreo: {
    color: 'white',
    fontSize: '180%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseña: {
    color: 'white',
    fontSize: '180%',
    top: '40%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputCorreo: {
    backgroundColor: 'white',
    top: '20%',
    left: '32%',
    width: '60%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    fontFamily: 'Montserrat',
    // eslint-disable-next-line
    ['@media (max-width:1050px)']:{
      left: '40%',
      width: '50%'
    },
  },
  inputContraseña: {
    backgroundColor: 'white',
    top: '40%',
    left: '32%',
    width: '60%',
    border: '3px',    
    borderRadius: '5px',
    position: 'absolute',
    // eslint-disable-next-line
    ['@media (max-width:1050px)']:{
      left: '40%',
      width: '50%'
    },
  },
  botonAcceder: {
    cursor: 'pointer'
  },
  boxbotonAcceder: {
    top: '70%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  homeFoto: {   
    position: 'relative',
    width: '3em'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5em',
    height: '6em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // eslint-disable-next-line
    ['@media (max-width:1150px)']:{
      width: '60px',
      height: '90px',
    },
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '5%'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '5em',
    width: '30%',
    position: 'absolute',
    top: '70%',
    left: '65%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '30%',
    position: 'absolute',
    top: '70%',
    left: '65%',
    border: '3px',
    borderRadius: '5px',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // eslint-disable-next-line
    ['@media (max-width:1350px)']:{
      width: '40%',
      left: '75%',
    },
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'

  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'
  },
  circulos: {
    position: 'absolute',
    left: '10%',
    height: '23%',
    top: '2%',
    width: '70%'
  }

}));

export default function AccederAdminPage() {

  CleanConsole.init()
  const correoRef = React.useRef();
  const contraseñaRef = React.useRef();
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorMessage, setError] = useState([]); 

      
  const [state, setState] = React.useState({
    mobileView: false,
    
  });
  

  function enviarUserAdmin(){
    const data = {    
      
          email: correoRef.current.value,          
          password: contraseñaRef.current.value,       
             
    };
    axios.post(localStorage.getItem('URL') + '/main/api/administrador/login', data)
    .then((response) => {
      cambiarFormularioEnviado(true);
      localStorage.setItem('administrador_id', response.data.data.administrador.id);
      localStorage.setItem('session_token', response.data.data.session_token);
      localStorage.setItem('email', response.data.data.administrador.email);
      localStorage.setItem('nombre', response.data.data.administrador.nombre);

      
      setTimeout(() => cambiarFormularioEnviado(false), 5000);
      window.location.href="/#/menu/admin/administradores"; 
    })
    .catch((error) => {
      setError(error.response.data.message)
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000);
    });
  }
 

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box></Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box  style={{ backgroundImage: 'url(./fondo/fonodo-azulclaro.png)', height: '100%'}}>  
          <Box>
            <Box className={classes.boxHome}>
              <a href="#/"><img src={HomeImage}  alt="" className={classes.homeFoto}></img></a>
            </Box>
            <img src={FondoArriba} alt=""  className={classes.fondoArriba}></img>
            <img alt=""  src={circulos} className={classes.circulos}></img>
            <Typography className={classes.registrarte}>
            Ingresa, ¡Es rápido y fácil!
            </Typography>
          </Box>                
          <Box className={classes.fondoFormulario} >
            <Box>
              <Typography  className={classes.textCorreo}>Correo:</Typography>
              <TextField  className={classes.inputCorreo} inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  inputRef={correoRef} />         
            </Box> 
            <Box>
              <Typography className={classes.textContraseña}>Contraseña:</Typography>
              <TextField type="password" className={classes.inputContraseña} variant="outlined"  inputRef={contraseñaRef} />         
            </Box> 
            <Box className={classes.boxbotonAcceder}>
              <img src={Acceder} alt=""  className={classes.botonAcceder} onClick={enviarUserAdmin} ></img>         
            </Box>
            { formularioEnviado && <div className={classes.divExtio}> <Typography className={classes.exito}>¡Login Exitoso!</Typography> </div>}
            
            { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            </Box>
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' target="_blank" rel="noreferrer"><img  alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' target="_blank"  rel="noreferrer"><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org' ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }