import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './Header';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import InicioPage from './InicioPage';

localStorage.setItem('URL', "https://api.innovacion-infosegura.org/");


ReactDOM.render(  
  
  <React.StrictMode>      
    <Routes />
      

  </React.StrictMode>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
