import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import NativeSelect  from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Modal from '@material-ui/core/Modal';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { ReactTableDefaults } from 'react-table-6'
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { CleanConsole } from '@eaboy/clean-console';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondo: {
    width: '100%',
    height: '90em',
    backgroundColor: '#002A68',
    
  },
  homeFoto: {   
    height: '60%',
    position: 'absolute',
    left: '10%',
    top:'10%'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5%',
    height: '12%'
  },  

  tablaAdmin: {
    top: '32%',      
    width: '85%',
    left: '5%',
    marginBottom: '5%'     
},
  btnCrear: { 
    color: 'white',
    backgroundColor: '#5cb85c',
    marginTop: '5%',
    left: '5%',
    marginBottom: '3%',
    fontFamily: 'Montserrat'
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  boxtitleModalInsertar: {
    left: '10%',
    position: 'relative',    
    width: '80%',
    height: '5%',
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBtn:{
    position:'absolute',
    left:'90%',
    top: '2%',
    borderBottomLeftRadius: '10px 10px',
  },  
  btnReportes: {
    position: 'absolute',
    top: '2%',
    left: '75%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  titleModalInsertar: {
    fontSize: '150%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'

  },
  form: {
    position: 'absolute',
    top: '15%',
    left: '10%',    
    width: '85%',
    height: '80%'
  },
  inputEmail: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputNombre: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputContraseña: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    marginTop: '5%',
    left: '25%',
    top: '10%',
    color: 'white',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '5%',
    color: 'white',
    top: '10%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  title: {
      position: 'absolute',
      color: 'white',
      fontWeight: 'bold',
      top: '10%',
      left: '15%'
  },
  fondoArriba: {
    width: '100%',
    height: '8em',
    position: 'abosolute'
  },
  legalFoto: {   
    width:'100%',
    marginTop: '5%',
    position: 'relative'
  },   
  btnAdministradores: {
    position: 'absolute',
    top: '2%',
    left: '3%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnNoticias: {
    position: 'absolute',
    top: '2%',
    left: '40%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  bienvenido: {
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '65%',
    fontFamily: 'Montserrat'
  },
  btnCerrar: {
    position: 'absolute',
    top: '2%',
    left: '90%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnUsuarios: {
    position: 'absolute',
    top: '2%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  barraMenu: {
    position: 'absolute',
    backgroundColor: '#213361',
    width: '95%',
    height: '2.5em',
    marginTop: '1%',
    left: '2.5%',
    border: '5px',
    borderRadius: '10px'
  }, 
  textNombreCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textNombre: {
    fontFamily: 'Montserrat'
  },
  textCorreoCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textIdCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textEditarCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  btnCategorias: {
    position: 'absolute',
    top: '2%',
    left: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  inputDescripcion: {
    width: '90%',
    marginTop: '2%',
    height: '25%',
  },
  inputId: {
    width: '90%',
    height: '10%',
    position: 'absolute',
    marginBottom: '5%',
  },
  textEmail: {
    marginTop: '10%',
    position: 'absolute'
  },
  inputEmailEditar: {
    width: '90%',
    marginTop: '15%',
    marginBottom: '2%',
  },
  btnInsertar2: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    marginBottom: '5%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnInsertar3: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    marginBottom: '5%',
    left: '25%',
    top: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnCancelar2: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '3%',
    marginBottom: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnCancelar3: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '3%',
    top: '60%',
    marginBottom: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnEditar: {
    backgroundColor: '#0275d8',
    marginRight: '10%',
    marginLeft: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnEliminar: {
    backgroundColor: '#d9534f',
    color: 'white',
    fontFamily: 'Montserrat'
    
  },
  fondoModalEliminar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  titleModalEliminar: {
    fontSize: '150%',
    fontWeight: 'bold',
    position: 'absolute',
    top: '20%',
    fontFamily: 'Montserrat'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '15%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px'

  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    left: '15%',
    top: '15%',
    fontFamily: 'Montserrat'
  },
  exito2: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'

  },
  divError: {
    backgroundColor: '#ff9090',
    height: '15%',
    width: '40%',
    position: 'absolute',
    top: '65%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divError2: {
    backgroundColor: '#ff9090',
    height: '8%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },

  


  

}));


export default function AdminAdministradores() { 
  CleanConsole.init()
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  
  const handleOpen2 = (rowId) => {
    setIdRowNow(rowId);
    setOpen2(true);
  };
  const handleOpen3 = (rowId) => {
    setIdRowNow(rowId);
    setOpen3(true);
  };
  
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  

  const [state, setState] = React.useState({
    mobileView: false,    
    administradores: [],
    categorias: [],
    categoria: [],
  });
  
  const nombreRefEditar = React.useRef();
  const descripcionRefEditar = React.useRef();
  const estadoRefEditar = React.useRef();


  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [formularioEnviado2, cambiarFormularioEnviado2] = useState(false);
  const [formularioEnviado3, cambiarFormularioEnviado3] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorFormulario2, cambiarErrorFormulario2] = useState(false);
  const [errorFormulario3, cambiarErrorFormulario3] = useState(false);
  const [errorMessage, setError] = useState([]); 
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [idRowNow, setIdRowNow] = useState(0);
 
  


  const { mobileView } = state;
  const classes = useStyles();

  const nombreRef = React.useRef();
  const descripcionRef = React.useRef("");
  Object.assign(ReactTableDefaults, {
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando..',
    noDataText: 'No rows found',
    pageText: 'Pagina',
    ofText: 'de',
    rowsText: 'Filas',
  })
  
  const columns = [{
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    width: 80,
    Cell: row => <div style={{ textAlign: "center", }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
    
  }, {
    id: 'nombre',
    Header: 'NOMBRE',
    accessor: 'nombre',
    width: 200,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  }, {
    id: 'descripcion',
    Header: 'DESCRIPCIÓN',
    accessor: 'descripcion' ,
    width: 900,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  }, {
    id: 'estado',
    Header: 'ESTADO',
    accessor: 'estado' ,
    width: 90,
    // eslint-disable-next-line
    Cell: row => <div style={{ textAlign: "center" }}>{row.value == 'A' ? 'ACTIVO' : 'INACTIVO'}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },{
    id: 'acciones',
    Header: 'ACCIONES',
    accessor: 'id',
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'},
    Cell: (row) => {     
      return <div>
        <button className={classes.btnEditar} 
        onClick={() => {
          handleOpen2(row.value);
          getCategoria(row.value);
       }}
        >EDITAR </button>
        
        <button className={classes.btnEliminar} onClick={() => handleOpen3(row.value)}>ELIMINAR </button>
        
      </div>;
    }     
  }]
  React.useEffect(() => {
    validateSession();
    getCategorias();
  }, []);
  
  function  validateSession(){
    // eslint-disable-next-line
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
    // eslint-disable-next-line
    if (localStorage.getItem('administrador_id') == undefined){
      window.location.href="/#/acceder"
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  function handleChangeEvent(event) {
  
    setState((prevState) => ({ ...prevState, categoria: {
      [event.target.name]: event.target.value,
    } 
  }));
  }


  function editarCategoria(){ 
    const data = {
      categoria:
        { 
          id: idRowNow,
          nombre: nombreRefEditar.current.value,      
          descripcion: descripcionRefEditar.current.value,          
          estado: estadoRefEditar.current.value,          
        }          
    };
    axios
    .put(localStorage.getItem('URL') + "/main/api/categoria/update", data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
      handleClose2()
      cambiarFormularioEnviado3(true);
      setTimeout(() => cambiarFormularioEnviado3(false), 5000)
      setTimeout(() => refreshPage(), 2000)   
      
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)  
      cambiarErrorFormulario3(true);
      setTimeout(() => cambiarErrorFormulario3(false), 5000); 
    });
  } 

 

  function getCategorias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/list", {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
    
      setDataTable(response.data.data);
      response.data.data.map((categoria) =>
        console.log("")
      );
      setState((prevState) => ({ ...prevState, categorias: response.data.data })
      );
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function getCategoria(categoriaID){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/detail?id="+categoriaID, {
      headers: {
        'Session-Token': localStorage.getItem("session_token"),
        'Administrador-Id': localStorage.getItem("administrador_id"),        
      }      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, categoria: response.data.data }));
      
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function enviarCategoria(){
    const data = {
      categoria:
        { 
          nombre: nombreRef.current.value,
          descripcion: descripcionRef.current.value,                      
        }          
    };
    axios.post(localStorage.getItem('URL') + '/main/api/categoria', data,{
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    })
    .then((response) => {
      handleClose1();   
      cambiarFormularioEnviado(true);
      setTimeout(() => cambiarFormularioEnviado(false), 5000); 
      setTimeout(() => refreshPage(), 2000);
    })
    .catch((error) => {
      console.log(error);        
      setError(error.response.data.message)         
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000); 
    });
  }

  function eliminarCategoria(categoriaID){ 
    axios
    .delete(localStorage.getItem('URL') + "/main/api/categoria/delete?id="+categoriaID, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
      handleClose3()
      cambiarFormularioEnviado2(true);
      setTimeout(() => cambiarFormularioEnviado2(false), 5000)
      setTimeout(() => refreshPage(), 2000)
       
          
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message) 
      handleClose3()
      cambiarErrorFormulario2(true);
      setTimeout(() => cambiarErrorFormulario2(false), 5000); 
    });
  } 

  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/administrador/logout', data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box></Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box>
          <Box > 
          <Box>
          <Box>            
            <img src={FondoArriba} alt=""  className={classes.fondoArriba}></img>            
            <Box>
              <Typography variant="h4" className={classes.bienvenido}>
                Bienvenido {localStorage.getItem("nombre")}
              </Typography>                        
            </Box>
            
            <Box className={classes.barraMenu} >
            <Button className={classes.btnAdministradores} variant="text" href="#/menu/admin/administradores">Administradores</Button>
            <Button className={classes.btnUsuarios} variant="text" href="#/menu/admin/usuarios">Usuarios</Button>
            <Button className={classes.btnCategorias} variant="text" href="#/menu/admin/categorias">Categorías</Button>
            <Button className={classes.btnNoticias} variant="text" href="#/menu/admin/noticias">Admins. Experiencias</Button>
            <Button className={classes.btnReportes} variant="text" href="#/menu/admin/reportes">Reportes</Button>
            <Button className={classes.btnCerrar} variant="text" onClick={cerrarSesion} href="#/">Cerrar</Button>
            </Box>            
          </Box>
        </Box>                  
            <Button className={classes.btnCrear} variant="contained" onClick={handleOpen1}>
                Crear Categoría
            </Button>
            <Modal 
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertar}>
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1}          
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                    className={classes.iconBtn}
                  >  
                    <CancelPresentationIcon  fontSize="large"/>
                  </IconButton>
                  <Box  className={classes.boxtitleModalInsertar}>
                <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
                    CREAR CATEGORÍA
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <Typography className={classes.textNombre} id="modal-modal-description" sx={{ mt: 2 }}>
                    Nombre:
                  </Typography>
                  <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputEmail} inputRef={nombreRef} />
                  </Box>
                  <Box>
                    <Typography  className={classes.textNombre}>Descripcion</Typography>
                    <TextField 
                      multiline
                      rows={6}
                      variant="outlined" className={classes.inputDescripcion} inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={descripcionRef} />         
                  </Box>
                  
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={enviarCategoria}     
                    className={classes.btnInsertar}      
                      >
                    Guardar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                    </Box>
                    { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
                  </Box>
            </Modal>
            <Modal
          open={open2}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertar}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box className={classes.boxtitleModalInsertar}>  
            <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
              EDITAR  CATEGORÍA
            </Typography>
            </Box>
            <Box className={classes.form}>
              <Box>
                <Typography id="modal-modal-description" className={classes.textNombre} sx={{ mt: 2 }}>
                  Id:
                </Typography>
                <TextField variant="outlined" className={classes.inputContraseña} inputProps={{ style: {  fontFamily: 'Montserrat' } }} disabled="disabled" value={idRowNow} />
              </Box>
              <Box>
                <Typography className={classes.textNombre} id="modal-modal-description" sx={{ mt: 2 }}>
                  Nombre:
                </Typography>
                <TextField type="text" variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputContraseña}  inputRef={nombreRefEditar} value={state.categoria.nombre} onChange={handleChangeEvent}   />
              </Box>              
              <Box>
                <Typography className={classes.textNombre}>Descripcion:</Typography>
                <TextField  multiline rows={6} variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputContraseña} inputRef={descripcionRefEditar} value={state.categoria.descripcion} onChange={handleChangeEvent} />
              </Box>
              <Box>
                <Typography className={classes.textNombre}>Estado:</Typography>
                <NativeSelect variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputContraseña} inputRef={estadoRefEditar} value={state.categoria.estado} onChange={handleChangeEvent} >
                  <option>Selecciona...</option>
                  <option value="A">Activo</option>
                  <option value="I">Inactivo</option>
                </NativeSelect>
              </Box>
              <Button
                variant="contained"
                size="large"
                className={classes.btnInsertar2}
                onClick={editarCategoria}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose2}
                className={classes.btnCancelar2}
              >
                Cancelar
              </Button>
            </Box>
            { errorFormulario3 &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleClose3}

        >
          <Box className={classes.fondoModalEliminar}>
            <IconButton
              aria-label="close"
              onClick={handleClose3}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box  className={classes.boxtitleModalInsertar}>
            <Typography className={classes.titleModalEliminar} variant="h6" component="h2">
              ¿Seguro que desea eliminar esta categoria?
            </Typography>
            </Box>
            <Box className={classes.form}>


              <Button
                variant="contained"
                size="large"
                onClick={() => eliminarCategoria(idRowNow)}
                className={classes.btnInsertar3}
              >
                Eliminar
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose3}
                className={classes.btnCancelar3}
              >
                Cancelar
              </Button>
            </Box>

          </Box>
        </Modal>
            <ReactTable className={classes.tablaAdmin}
              getTdProps={() => ({
                style: { border: `1px solid rgb(200 200 200)` },
              })}
              data={dataTable}
              columns={columns}
              pagination             
            />
            { formularioEnviado && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Categoría creada correctamente</Typography> </div>} 
            {formularioEnviado2 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Categoría Eliminada correctamente</Typography> </div>}
            {formularioEnviado3 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Categoría Editada correctamente</Typography> </div>}   
            { errorFormulario2 &&<div className={classes.divError2}><Typography className={classes.error}>No se pudo eliminar en este momento</Typography> </div> }              
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }