import React from 'react';


import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FondoArriba from './images/fondo-05.png';
import circulos from './images/circulos-06.png';
import LegalArriba2 from './images/logos2SinFondo.png';
import HomeImage from './images/home-37.png';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useState } from 'react';
import enviar from './images/enviar-R.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { CleanConsole } from '@eaboy/clean-console';




import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondoArriba: {
    width: '100%',
    height: '15em',
    ['@media (max-width:1150px)']:{
      height: '10em'
    },
  },
  fondoArribaMobile: {
    width: '100%',
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '15%',
    fontFamily: 'Montserrat' ,
    ['@media (max-width:1150px)']:{
      fontSize: '3em'
    },
  },
  textoArriba: {
    fontSize: '4em',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    top: '30%',
    left: '15%'
  },
  experiencias: {
    fontSize: '4em',
    color: '#002A68',
    fontWeight: 'bolder',
    left: '5%',
    position: 'absolute'    
  } ,
  experienciasMobile: {
    fontSize: '2em',
    color: '#002A68',
    fontWeight: 'bolder',
    left: '14%',
    marginTop:'5%',
    position: 'absolute'    
  } ,
  fondoFormulario: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '100%',
    width: '70%',    
    height: '120em',
    left: '15%',
    marginTop: '5%',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    ['@media (max-width:1150px)']:{
      width: '80%',
      left: '10%'
    },
  },
  fondoFormularioMobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: '100%',
    width: '90%',    
    height: '100em',
    left: '5%',
    marginTop: '20%',
    position: 'relative',
    border: '10px',
    borderRadius: '20px'
  },
  inputNombre: {
    backgroundColor: 'white',
    top: '5%',
    left: '35%',
    width: '60%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputNombreMobile: {
    backgroundColor: 'white',
    top: '3%',
    left: '45%',
    width: '50%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputError: {
    borderWith: '1px',
    borderStyle: 'solid',
    borderColor: 'red',
  },
  inputApellidos: {
    backgroundColor: 'white',
    top: '9%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputApellidosMobile: {
    backgroundColor: 'white',
    top: '8%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCorreo: {
    backgroundColor: 'white',
    top: '13%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCorreoMobile: {
    backgroundColor: 'white',
    top: '16%',
    left: '15%',
    width: '80%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCorreo2: {
    backgroundColor: 'white',
    top: '17%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCorreo2Mobile: {
    backgroundColor: 'white',
    top: '23.3%',
    left: '15%',
    width: '80%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCargo: {
    backgroundColor: 'white',
    top: '21%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCargoMobile: {
    backgroundColor: 'white',
    top: '27%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputFecha: {
    backgroundColor: 'white',
    top: '35%',
    left: '45%',
    width: '49%',
    height: '2.2%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute',
    padding: '0 7px'
  },
  inputFechaMobile: {
    backgroundColor: 'white',
    top: '46%',
    left: '15%',
    width: '74%',
    height: '2.2%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute',
    padding: '0 10px'
  },
  inputGenero: {
    backgroundColor: 'white',
    top: '60%',
    left: '40%',
    width: '50%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    display: 'none',
  },
  mostrarO: {
    backgroundColor: 'white',
    top: '64%',
    left: '35%',
    width: '50%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    display: 'block',
  },
  mostrarOM: {
    backgroundColor: 'white',
    top: '65%',
    left: '15%',
    width: '70%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    display: 'block',
  },
  inputPais: {
    backgroundColor: 'white',
    top: '39%',
    left: '45%',
    width: '25%',
    border: '3px',
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 5px'
  },
  inputPaisMobile: {
    backgroundColor: 'white',
    top: '50%',
    left: '45%',
    width: '49%',
    border: '3px',
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 10px',
  },
  inputCiudad: {
    backgroundColor: 'white',
    top: '43%',
    left: '45%',
    width: '25%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCiudadMobile: {
    backgroundColor: 'white',
    top: '54%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputContraseña: {
    backgroundColor: 'white',
    top: '47%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputContraseñaMobile: {
    backgroundColor: 'white',
    top: '58%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputTwitter: {
    backgroundColor: 'white',
    top: '66%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputTwitterMobile: {
    backgroundColor: 'white',
    top: '76%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputWeb: {
    backgroundColor: 'white',
    top: '71%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputWebMobile: {
    backgroundColor: 'white',
    top: '79%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputFacebook: {
    backgroundColor: 'white',
    top: '76%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputFacebookMobile: {
    backgroundColor: 'white',
    top: '82%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputLinkedin: {
    backgroundColor: 'white',
    top: '81%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputLinkedinMobile: {
    backgroundColor: 'white',
    top: '85%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputTelefono: {
    backgroundColor: 'white',
    top: '86%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputTelefonoMobile: {
    backgroundColor: 'white',
    top: '88%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputInstitucion: {
    backgroundColor: 'white',
    top: '57%',
    left: '5%',
    width: '90%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputInstitucionMobile: {
    backgroundColor: 'white',
    top: '69.5%',
    left: '5%',
    width: '90%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },    
  textNombre: {
    color: 'white',
    fontSize: '180%',
    top: '5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textNombreMobile: {
    color: 'white',
    fontSize: '130%',
    top: '3%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textApellidos: {
    color: 'white',
    fontSize: '180%',
    top: '9%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textApellidosMobile: {
    color: 'white',
    fontSize: '130%',
    top: '8%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCorreo: {
    color: 'white',
    fontSize: '180%',
    top: '13%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCorreoMobile: {
    color: 'white',
    fontSize: '130%',
    top: '13%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCorreo2: {
    color: 'white',
    fontSize: '180%',
    top: '17%',
    left: '5%',
    position: 'absolute',
    width: '35%',
    fontFamily: 'Montserrat'
  },
  textCorreo2Mobile: {
    color: 'white',
    fontSize: '130%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCargo: {
    color: 'white',
    fontSize: '180%',
    top: '21.5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCargoMobile: {
    color: 'white',
    fontSize: '130%',
    top: '27%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textGenero: {
    color: 'white',
    fontSize: '180%',
    top: '25%',
    left: '5%',
    width: '37%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textGeneroMobile: {
    color: 'white',
    fontSize: '120%',
    top: '31%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPais: {
    color: 'white',
    fontSize: '180%',
    top: '39%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPaisMobile: {
    color: 'white',
    fontSize: '130%',
    top: '50%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudad: {
    color: 'white',
    fontSize: '180%',
    top: '43%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudadMobile: {
    color: 'white',
    fontSize: '130%',
    top: '54%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseña: {
    color: 'white',
    fontSize: '180%',
    top: '47%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseñaMobile: {
    color: 'white',
    fontSize: '130%',
    top: '58%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textInformacion: {
    color: 'white',
    fontSize: '180%',
    top: '61.5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textInformacionMobile: {
    color: 'white',
    fontSize: '130%',
    top: '72%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textTwitter: {
    color: 'white',
    fontSize: '180%',
    top: '66%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textTwitterMobile: {
    color: 'white',
    fontSize: '130%',
    top: '76%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textWeb: {
    color: 'white',
    fontSize: '180%',
    top: '71%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textWebMobile: {
    color: 'white',
    fontSize: '130%',
    top: '79%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFacebeook: {
    color: 'white',
    fontSize: '180%',
    top: '76%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFacebeookMobile: {
    color: 'white',
    fontSize: '130%',
    top: '82%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textLinkedin: {
    color: 'white',
    fontSize: '180%',
    top: '81%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textLinkedinMobile: {
    color: 'white',
    fontSize: '130%',
    top: '85%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textTelefono: {
    color: 'white',
    fontSize: '180%',
    top: '86%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textTelefonoMobile: {
    color: 'white',
    fontSize: '130%',
    top: '88%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textInstitucion: {
    color: 'white',
    fontSize: '170%',
    top: '52%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textInstitucionMobile: {
    color: 'white',
    fontSize: '130%',
    top: '62%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  boxGenero: {
    backgroundColor: 'white',
    width: '50%',
    height: '10em',
    position: 'absolute',
    top: '25%',
    left: '45%',
    border: '3px',
    borderRadius: '10px'
  },
  boxGeneroMobile: {
    backgroundColor: 'white',
    width: '80%',
    height: '8em',
    position: 'absolute',
    top: '35%',
    left: '15%',
    border: '3px',
    borderRadius: '10px'
  },
  textFemenino: {
    color: '#002A68',
    top: '2%',
    left: '8%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textMasculino: {
    color: '#002A68',
    top: '30%',
    left: '8%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textOtro: {
    color: '#002A68',
    top: '65%',
    left: '8%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFecha: {
    color: 'white',
    fontSize: '180%',
    top: '35%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFechaMobile: {
    color: 'white',
    fontSize: '130%',
    top: '43%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  checkM: {
    top:'80%',
    left: '30%',
    position:'absolute',
    color: '#002A68'

  },
  checkO: {
    top: '200%',
    position:'absolute',
    left: '30%',
    color: '#002A68'

  },
  checkF: {
    left: '30%',
    position: 'relative',
    color: '#002A68'
  },
  boxbotonEnviar: {    
    top: '92%',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  botonEnviar: {     
    border: '2px',
    borderRadius: '10px',
    fontFamily: 'Montserrat'
  },
  botonEnviarMobile: {    
    width: '10em',
    border: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
    fontFamily: 'Montserrat'
  },
  boxbotonEnviarMobile: {    
    top: '92%',    
    position: 'absolute',    
    width: '100%',
    height: '5%',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'
  },
  homeFoto: {   
    height: '3em',
    position: 'relative',

  },
  legalFoto: {   
    width:'100%',
    height: '10%',
    top:'90%',
    marginTop: '5%'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5em',
    height: '6em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '3%',
    width: '30%',
    position: 'absolute',
    top: '95%',
    left: '65%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  divExtioMobile: {
    backgroundColor: '#D3E9D0',
    height: '4%',
    width: '90%',
    position: 'absolute',
    top: '97%',
    left: '5%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  exitoMobile: {
    color: 'green',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '40%',
    position: 'fixed',
    top: '60%',
    left: '55%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divErrorMobile: {
    backgroundColor: '#ff9090',
    height: '4%',
    width: '70%',
    position: 'absolute',
    top: '97%',
    left: '15%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  errorMobile: {
    color: 'red',
    position: 'absolute', 
    fontFamily: 'Montserrat'
  },
  inputsGenero: {
    backgroundColor: 'white', 
    width: '40%',
    height: '100%',
    border: '3px',
    borderRadius: '10px'
  },
  inputF: {
    color: '#002A68',
    fontSize: '130%'
  }, 
  inputF2: {
    color: '#002A68',
    fontSize: '130%'
  }, 
  inputFM: {
    color: '#002A68',
    fontSize: '100%'
  }, 
  inputsM:{
    position: 'absolute',
    top: '40%',
    left: '5%'
  },
  inputsMM:{
    position: 'absolute',
    top: '30%',
    left: '5%'
  },
  inputsO: {
    position: 'absolute',
    top: '70%',
    left: '5%',
    width: '35%'
  },
  inputsOM: {
    position: 'absolute',
    top: '50%',
    left: '5%'
  },
  inputsF: {
    position: 'absolute',
    top: '10%',
    left: '5%'
  },  
  inputsFM: {
    position: 'absolute',
    top: '10%',
    left: '5%'
  },
  homeFotoMobile: {   
    height: '1.8em',
    position: 'relative',

  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '2em',
    height: '3em',
    top: '0.01%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  registrarteMobile: {
    fontSize: '1em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    fontFamily: 'Montserrat',
    top: '1em',
    left: '30%',
    ['@media (min-width:570px)']:{
      fontSize: '2em',
      left: '15%',
      fontFamily: 'Montserrat'
    },
    ['@media (min-width:850px)']:{
      fontSize: '3em',
      left: '15%',
      fontFamily: 'Montserrat'
    },
  },
  textoArribaMobile: {
    fontSize: '0.8em',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    top: '3em',
    left: '40%',
    fontFamily: 'Montserrat'
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '12%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  fondoTodo: {
  },
  circulos: {
    position: 'absolute',
    left: '10%',
    height: '23%',
    top: '2%',
    width: '70%',
    ['@media (max-width:1150px)']:{
      height: '18%'
    },
  }
  
}));


export default function RegistrarsePage() {
  
  CleanConsole.init()
  const [Genero,setGenero] = React.useState("");
  const [Fecha,setFecha] = React.useState("");

  const [state, setState] = React.useState({
    mobileView: false,
    paises: [],
  });
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorValidacion, cambiarErrorValidacion] = useState(false);
  const [mostrarOtroG, cambiarDisplay] = useState(false);
  
  const { mobileView } = state;
  const classes = useStyles();

  const nombreRef = React.useRef();
  const apellidoRef = React.useRef();
  const correoRef = React.useRef();
  const correoRef2 = React.useRef();
  
  const cargoRef = React.useRef();
  const generoOtroRef = React.useRef("");
  const paisRef = React.useRef();
  const ciudadRef = React.useRef();
  const passwordRef = React.useRef();  
  const institucionRef = React.useRef();
  const twitterRef = React.useRef();
  const webRef = React.useRef();
  const facebookRef = React.useRef();
  const linkedinRef = React.useRef();
  const telefonoRef = React.useRef();
  React.useEffect(() => {
    getPaises();
  }, []);

  const [EnviarMessage, setData] = useState([]); 

function mostrarOtro(){
  cambiarDisplay(true)
}
function ocultarOtro(){
  cambiarDisplay(false)
}

function getPaises(){

  axios
  .get(localStorage.getItem('URL') + "/main/api/pais/list")
  .then((response) => {
    setState((prevState) => ({ ...prevState, paises: response.data.data }));
  })
  .catch((error) => {
    console.log(error);
  });
} 
const [errorMessage, setError] = useState([]); 

function enviarData(){
  var generoOtro=""
  if (mostrarOtroG == true ){    
      generoOtro=generoOtroRef.current.value;
    
  } 
  if (correoRef.current.value != correoRef2.current.value){
    cambiarErrorValidacion(true)
    setTimeout(() => cambiarErrorValidacion(false), 5000);   
  }
  if (correoRef.current.value == correoRef2.current.value){
    const data = {
      usuario:
        { 
          nombres: nombreRef.current.value,
          apellidos: apellidoRef.current.value,
          email: correoRef.current.value,
          cargo: cargoRef.current.value,
          fecha_nacimiento: Fecha,
          genero: Genero,
          genero_otro: generoOtro,
          pais_id: paisRef.current.value,
          ciudad: ciudadRef.current.value,
          password: passwordRef.current.value,
          institucion: institucionRef.current.value,
          twitter: twitterRef.current.value,
          web: webRef.current.value,
          facebook: facebookRef.current.value,
          linkedin: linkedinRef.current.value,
          telefono: telefonoRef.current.value,
        }          
    };
    axios.post(localStorage.getItem('URL') + '/main/api/usuario', data)
    .then((response) => {
      setData(response.data.message)
      cambiarFormularioEnviado(true);
        setTimeout(() => cambiarFormularioEnviado(false), 5000);            
        setTimeout(() => window.location.href="/#/acceder", 3000);
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)
      cambiarErrorFormulario(true);
        setTimeout(() => cambiarErrorFormulario(false), 5000);   
    });
  }
  }
  
  

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => {

  return (
    <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>
      <img alt=""  src={FondoArriba} className={classes.fondoArribaMobile}></img>
        <Box className={classes.boxHomeMobile}>
        <a href="#/"><img alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a>
        </Box>    
        <Box>
          <Typography className={classes.registrarteMobile}>
          Regístrate, ¡Es rápido y fácil!
          </Typography>

           
        </Box>
        <Box className={classes.fondoTodo}>
        <Box className={classes.fondoFormularioMobile} >
          <Box>
            <Typography  className={classes.textNombreMobile}>Nombres:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={nombreRef} variant="outlined" size="small"  className={classes.inputNombreMobile} />         
          </Box> 
          <Box>
            <Typography className={classes.textApellidosMobile}>Apellidos:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={apellidoRef} variant="outlined"  className={classes.inputApellidosMobile} size='small' />         
          </Box> 
          <Box>
            <Typography className={classes.textCorreoMobile}>Correo electrónico:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={correoRef} variant="outlined" size='small' className={classes.inputCorreoMobile} />         
          </Box> 
          <Box>
            <Typography className={classes.textCorreo2Mobile}>Repite tu correo electrónico:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCorreo2Mobile} inputRef={correoRef2} variant="outlined" size='small' />         
          </Box> 
          <Box>
            <Typography className={classes.textCargoMobile}>Cargo:</Typography>
            <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={cargoRef} variant="outlined" size='small' className={classes.inputCargoMobile} />         
          </Box>
          <Box>
            <Typography className={classes.textGeneroMobile}>¿Con qué género te identificas?</Typography>
            <Box className={classes.boxGeneroMobile}>    
              <Box className={classes.inputsGenero}>        
              <Box className={classes.inputsFM}> 
              <input  type="radio" name="Genero"  value="F" id="F" color="primary" onClick={ocultarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputFM} >Femenino</label><br/>
              </Box> 
              <Box className={classes.inputsMM}>
              <input type="radio" name="Genero"  value="M" id="M" color="primary" onClick={ocultarOtro}  onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputFM} >Masculino</label><br/>
              </Box>
              <Box className={classes.inputsOM}>
              <input type="radio" name="Genero"  value="O" id="O" color="primary" onClick={mostrarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputFM} >Otro, específica:</label><br/>
              </Box>
              </Box>             
              { mostrarOtroG &&<TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }}  inputRef={generoOtroRef} className={classes.mostrarOM} /> }  

            </Box>  
          </Box>
          <Box>
            <Typography className={classes.textFechaMobile}>Fecha de nacimiento:</Typography>
            <input  type="date" name="Fecha" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputFechaMobile} variant="outlined"  onChange={(e)=>setFecha(e.target.value)} />         
          </Box>
          <Box>
            <Typography className={classes.textPaisMobile}>País:</Typography>
            <NativeSelect inputRef={paisRef} inputProps={{ style: {  fontFamily: 'Montserrat' } }} name="paises" size='small' variant="outlined" className={classes.inputPaisMobile}  defaultValue="0"> 
              <option value='0'> Seleccione... </option>
              {state.paises.map(elemento=>(
                <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                )
              )}
            </NativeSelect>      
          </Box>
          <Box>
            <Typography className={classes.textCiudadMobile}>Ciudad:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={ciudadRef} variant="outlined"  size='small' className={classes.inputCiudadMobile} />         
          </Box>        
          <Box>
            <Typography className={classes.textContraseñaMobile}>Contraseña:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef} size='small' variant="outlined"  className={classes.inputContraseñaMobile} />         
          </Box>            
          <Box>
            <Typography className={classes.textInstitucionMobile}>Institución a la que pertenece (trabajo, universidad, ONG, centro de pensamiento, etc)</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={institucionRef} variant="outlined" size='small' className={classes.inputInstitucionMobile} />         
          </Box>
          <Box>
            <Typography className={classes.textInformacionMobile}>Información de contacto (opcional)</Typography>
          </Box>
          <Box>
            <Typography className={classes.textTwitterMobile}>Twitter:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={twitterRef} size='small'  variant="outlined" className={classes.inputTwitterMobile} />         
          </Box>
          <Box>
            <Typography className={classes.textWebMobile}>Web:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={webRef} size='small'  variant="outlined"  className={classes.inputWebMobile} />         
          </Box>
          <Box>
            <Typography className={classes.textFacebeookMobile}>Facebook:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={facebookRef} size='small' variant="outlined" className={classes.inputFacebookMobile} />         
          </Box>
          <Box>
            <Typography className={classes.textLinkedinMobile}>LinkedIn:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  inputRef={linkedinRef} size='small'  variant="outlined" className={classes.inputLinkedinMobile} />         
          </Box>
          <Box>
            <Typography  className={classes.textTelefonoMobile}>Teléfono:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={telefonoRef} size='small' variant="outlined" className={classes.inputTelefonoMobile} />         
          </Box>
          <Box className={classes.boxbotonEnviarMobile}>
            <img alt="" src={enviar} className={classes.botonEnviarMobile} onClick={enviarData}></img>         
          </Box>
          { formularioEnviado && <div className={classes.divExtioMobile}> <Typography className={classes.exitoMobile}>{EnviarMessage}</Typography> </div>}
            
            { errorFormulario &&<div className={classes.divErrorMobile}><Typography className={classes.errorMobile}> {errorMessage} </Typography> </div> }
            { errorValidacion &&<div className={classes.divErrorMobile}><Typography className={classes.errorMobile}> El correo electrónico no coincide </Typography> </div> }
            
        </Box>
        </Box>
        <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
    </Box>
    
  );
}



const displayDesktop = () => {

    
    return (
      <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>
        <img alt="" src={FondoArriba} className={classes.fondoArriba}></img>
        <img alt=""  src={circulos} className={classes.circulos}></img>
        <Box className={classes.boxHome}>
          <a href="#/"><img alt="" src={HomeImage} className={classes.homeFoto}></img></a>        
        </Box>    
        <Box>
          <Typography className={classes.registrarte}>
          Regístrate, ¡Es rápido y fácil!
          </Typography>  
        </Box>
        <Box className={classes.fondoTodo}>
        <Box className={classes.fondoFormulario} >
          <Box>
            <Typography  className={classes.textNombre}>Nombres:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={nombreRef} variant="outlined"  className={classes.inputNombre} />         
          </Box> 
          <Box>
            <Typography className={classes.textApellidos}>Apellidos:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={apellidoRef} variant="outlined"  className={classes.inputApellidos} />         
          </Box> 
          <Box>
            <Typography className={classes.textCorreo}>Correo electrónico:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={correoRef} variant="outlined"  className={classes.inputCorreo} />         
          </Box> 
          <Box>
            <Typography className={classes.textCorreo2}>Repite tu correo electrónico:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCorreo2} inputRef={correoRef2} variant="outlined"  />         
          </Box> 
          <Box>
            <Typography className={classes.textCargo}>Cargo:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={cargoRef} variant="outlined"  className={classes.inputCargo} />         
          </Box>
          <Box>
            <Typography className={classes.textGenero}>¿Con qué género te identificas?</Typography>
            <Box className={classes.boxGenero}>    
              <Box className={classes.inputsGenero}>        
              <Box className={classes.inputsF}> 
              <input  type="radio" name="Genero"  value="F" id="F" color="primary" onClick={ocultarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Femenino</label><br/>
              </Box> 
              <Box className={classes.inputsM}>
              <input type="radio" name="Genero"  value="M" id="M" color="primary" onClick={ocultarOtro}  onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Masculino</label><br/>
              </Box>
              <Box className={classes.inputsO}>
              <input type="radio" name="Genero"  value="O" id="O" color="primary" onClick={mostrarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF2} >Otro, específica:</label><br/>
              </Box>
              </Box>             
              { mostrarOtroG &&<TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}   inputRef={generoOtroRef} className={classes.mostrarO} /> }  

            </Box>  
          </Box>
          <Box>
            <Typography className={classes.textFecha}>Fecha de nacimiento:</Typography>
            <input  type="date" name="Fecha" className={classes.inputFecha} variant="outlined"  onChange={(e)=>setFecha(e.target.value)} />         
          </Box>
          <Box>
            <Typography className={classes.textPais}>País:</Typography>
            <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }}  inputRef={paisRef} name="paises"  variant="outlined" className={classes.inputPais}   defaultValue="0"> 
              <option value='0'> Seleccione... </option>
              {state.paises.map(elemento=>(
                <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                )
              )}
            </NativeSelect>      
          </Box>
          <Box>
            <Typography className={classes.textCiudad}>Ciudad:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={ciudadRef} variant="outlined"  className={classes.inputCiudad} />         
          </Box>        
          <Box>
            <Typography className={classes.textContraseña}>Contraseña:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef} variant="outlined"  className={classes.inputContraseña} />         
          </Box>            
          <Box>
            <Typography className={classes.textInstitucion}>Institución a la que pertenece (trabajo, universidad, ONG, centro de pensamiento, etc)</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={institucionRef} variant="outlined"  className={classes.inputInstitucion} />         
          </Box>
          <Box>
            <Typography className={classes.textInformacion}>Información de contacto (opcional)</Typography>
          </Box>
          <Box>
            <Typography className={classes.textTwitter}>Twitter: </Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={twitterRef}  variant="outlined" className={classes.inputTwitter} />         
          </Box>
          <Box>
            <Typography className={classes.textWeb}>Web:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={webRef}  variant="outlined"  className={classes.inputWeb} />         
          </Box>
          <Box>
            <Typography className={classes.textFacebeook}>Facebook:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={facebookRef}  variant="outlined" className={classes.inputFacebook} />         
          </Box>
          <Box>
            <Typography className={classes.textLinkedin}>LinkedIn:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={linkedinRef}  variant="outlined" className={classes.inputLinkedin} />         
          </Box>
          <Box>
            <Typography  className={classes.textTelefono}>Teléfono:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  inputRef={telefonoRef}  variant="outlined" className={classes.inputTelefono} />         
          </Box>
          <Box className={classes.boxbotonEnviar}>
            <img alt="" src={enviar} className={classes.botonEnviar} onClick={enviarData}></img>         
          </Box>
                
          { formularioEnviado && <div className={classes.divExtio}> <Typography className={classes.exito}>{EnviarMessage}</Typography> </div>}
            
            { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            { errorValidacion &&<div className={classes.divError}><Typography className={classes.error}> El correo electrónico no coincide </Typography> </div> }
            
        </Box>
        </Box>
        <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>


      </Box>
    
  );
}

return (
  
  <header>
      {mobileView ? displayMobile() : displayDesktop()}
  </header>
);
}
