import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HomeImage from './images/home-37.png';
import axios from 'axios';
import descargar from './images/descargar-pdf.png';
import Modal from '@material-ui/core/Modal';
import { useParams } from 'react-router';
import LegalArriba from './images/logos2-R.png';
import facebook from './images/fb.png';
import twitter from './images/twitter.png';
import linkedin from './images/linkedin.png';
import fondoNoticia from './images/fondoNoticia.png';
import conocerMas from './images/conocerMas.png';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LegalArriba2 from './images/logos2SinFondo.png';
import triangulos from './images/triangulos_Mesa de trabajo 1.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import ReactHtmlParser from 'react-html-parser'; 
import { CleanConsole } from '@eaboy/clean-console';





import {
  FacebookShareButton,  
  LinkedinShareButton,  
  TwitterShareButton,
} from "react-share";







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  fondo: {
    width:'100%',
    height:'30em',
  },
  boxFondo: {
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: '100%',
    width: '100%',
    height: '30%',
    position: 'relative'
    
  },
  boxFondoMobile: {
    backgroundImage: 'url(./fondo/InicialCortada.png)',
    backgroundSize: 'cover',
    width: '100%',
    height: '10em',
    position: 'relative'
    
  },
  legalImagen: {
    marginTop: '1em',
    marginBottom: '1em',
    left: '10%',
    width: '250px',
    position: 'relative',
    ['@media (max-width:1350px)']:{
      width: '200px',
    },    
    ['@media (max-width:1150px)']:{
      width: '150px'
    },
  },
  legalImagenMobile: {
    top: '15%',
    left: '15%',
    position: 'relative',
    width: '10em'

  },
  boxTitleModalInsertar: {
    position: 'relative',
    width: '90%',
    height: '5%',
    marginTop: '3%',

  },
  boxTitleModalInsertarMobile: {
    position: 'relative',
    width: '90%',
    height: '5%',
    fontFamily: 'Montserrat',
    marginTop: '10%',

  },
  titleModalInsertar: {
    fontWeight: 'bold',
    left: '3%',
    position: 'relative',
    color: '#002A68',
    fontFamily:'Montserrat',
    fontSize: '3em'
  },
  titleModalInsertarM: {
    fontWeight: 'bold',
    left: '3%',
    position: 'relative',
    color: '#002A68',
    fontFamily:'Montserrat',
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '75%',    
    left: '10%',
    position: 'relative',
    marginTop: '3%',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#8f9fbe',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '20px 15px 10px  #8f9fbe',
    marginBottom: '5%'
  },
  fondoFormulario2Mobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: '100%',
    width: '75%',    
    left: '10%',
    position: 'relative',
    marginTop: '3%',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#8f9fbe',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '20px 15px 10px  #8f9fbe',
    marginBottom: '5%',
    overflow: 'hidden'
    },
  textPais2: {
    color: 'white',
    position: 'relative',
    fontSize: '200%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '140%',
    marginTop:'5%',
    left: '7%',
    width: '92%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais3: {
    color: '#e3e4e5',
    fontWeight: '10'
  },
  textPais3Mobile: {
    color: '#e3e4e5',
    fontWeight: '10'
  },
  textAño2: {
    color: 'white',
    position: 'relative',
    fontSize: '200%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '140%',
    top: '3%',
    left: '7%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño3: {
    color: '#e3e4e5',
    fontWeight: '10'

  },
  textAño3Mobile: {
    color: '#e3e4e5',
    fontWeight: '10'
  },
  textDescripcion2: {
    color: 'white',
    position: 'relative',
    fontSize: '200%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textDescripcion2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '140%',
    top: '5%',
    left: '7%',
    fontWeight: 'bold',
    marginTop: '3%',
    fontFamily: 'Montserrat'
  },
  boxTextDescripcion3: {
    position: 'relative',
    width: '90%',
    left: '5%'   ,
    overflow: 'hidden',
    textAlign: 'justify'
  },
  boxTextDescripcion3Mobile: {
    position: 'relative',
    width: '87%',
    top: '7%',
    left: '7%'   ,
    overflow: 'hidden',
    textAlign: 'justify'

  },
  textDescripcion3: {
    color: '#e3e4e5',
    fontSize: '130%',
    fontFamily: 'Montserrat'
  },
  textDescripcion3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '110%',
    fontFamily: 'Montserrat'
      
  },
  boxFotoDescripcion1: {
    backgroundColor: 'white',
    width: '400px',
    height: '250px',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'right',
    marginLeft: '6%',
    overflow: 'hidden',
    ['@media (max-width:1300px)']:{
      width: '330px',
      height: '206px',
    },

    
  },
  boxFotoDescripcion1Mobile: {
    position: 'relative',
    width: '87%',
    height: '10%',
    maxHeight: '300px',
    maxWidth: '300px',
    margin: 'auto',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'

  },
  textResultados2: {
    color: 'white',
    position: 'relative',
    fontSize: '200%',
    left: '44%',
    width: '45%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textResultados2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '140%',
    left: '7%',
    marginTop: '2%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextResultados3: {
    position: 'relative',
    width: '90%',
    top: '57%',
    left: '5%'    ,
    overflow: 'hidden',
    textAlign: 'justify'

  },
  boxTextResultados3Mobile: {
    position: 'relative',
    width: '87%',
    left: '7%'    ,
    overflow: 'hidden',
    textAlign: 'justify'

  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '130%',
    fontFamily: 'Montserrat'
  },
  textResultados3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '110%',
    fontFamily: 'Montserrat'
  },
  boxFotoDescripcion2: {
    position: 'relative',
    backgroundColor: 'white',
    width: '400px',
    height: '250px',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    marginRight:'6%',
    overflow: 'hidden',
    ['@media (max-width:1300px)']:{
      width: '330px',
      height: '206px',
    },
  },
  boxFotoDescripcion2Mobile: {
    position: 'relative',
    maxHeight: '300px',
    maxWidth: '300px',
    width: '87%',
    margin: 'auto',
    minHeight: '150px',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'

  },
  textQuieres: {
    color: 'white',
    position: 'relative',
    fontSize: '200%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textQuieresMobile: {
    color: 'white',
    position: 'relative',
    fontSize: '130%',
    left: '7%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textQuieres2: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '140%',
    fontFamily: 'Montserrat'

  },
  textQuieres2Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '120%',
    left: '7%',
    width: '90%',
    fontFamily: 'Montserrat'
  },
  descargarFoto: {
    position: 'relative',
    width: '20em',
    cursor: 'pointer',
    ['@media (max-width:1150px)']:{
      width: '15em'
    },
  },
  homeFoto: {   
    height: '3em',
    position: 'relative',

  },
  conocerFoto: {
    position: 'relative',
    width: '19em',
    cursor: 'pointer',
    marginLeft: '5%',
    ['@media (max-width:1150px)']:{
      width: '14.5em'
    },
  },
  descargarFotoMobile: {
    position: 'relative',   
    width: '12em',
    cursor: 'pointer'
  },
  boxImagenesAbajo: {
    width: '100%',
    height: '2%',
    position: 'relative',   
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxImagenesAbajo2: {
    width: '100%',
    height: '2%',
    top: '90%',
    position: 'relative',   
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  conocerFotoMobile: {
    position: 'relative',
    width: '12em',
    cursor: 'pointer'
  },
  boxFondoTodo: {
    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)',
    backgroundSize: '50%',
    position: 'absolute',
    width: '100%',

  },
  boxFondoTodoMobile: {
    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)',
    backgroundSize: '100%',
    position: 'absolute',
    width: '100%',
    top: '10em'
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '2em',
    height: '3em',
    top: '0.01%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxRedes: {
    width: '5.5%',
    position: 'absolute',
    height: '15em',
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    left:'100%',
    top: '30%',
    display: 'flex',
    alignItems: 'center'
  },
  boxRedesMobile: {
    width: '100%',
    position: 'relative',
    height: '3%',
    top: '92%',
    display: 'flex',
    justifyContent: 'center'
  },
  boxOdsMobile: {
    width: '80%',
    left: '10%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5%',
    flexWrap: 'wrap'
  },
  facebookFoto: {
    width: '250%',
    position: 'relative',
    top: '-5em',
    left: '20%'
  },
  facebookFotoMobile: {
    width: '120%',
    position: 'relative',
    right: '50%'
  },
  instagramFoto: {
    width: '230%',
    position: 'relative',
    right: '70%'    
  },
  instagramFotoMobile: {
    width: '110%',
    position: 'relative',
    right: '10%'
  },
  linkedinFoto: {
    width: '250%',
    position: 'relative',
    top: '5em',
    right: '180%'
  },
  linkedinFotoMobile: {
    width: '120%',
    position: 'relative',
    left: '20%'
  },
  imagen1: {
    border: '3px',
    borderRadius: '20px',
    display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '5%'
  },
  textNoHay: {
    position: 'relative',    
    fontWeight: 'bold',
    color: '#002A68'
  },
  textNoHayMobile: {
    position: 'relative',
    fontWeight: 'bold',
    color: '#002A68'
  },
  boxTextQuieres: {
    position: 'relative',
    width: '100%',
    height: '2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxTextQuieres2: {
    position: 'relative',
    width: '92%',
    left: '5%',
    height: '2%',
    top: '84%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  titleModalInsertar2: {
    fontSize: '150%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'

  },
  boxtitleModalInsertar2: {
    position: 'relative',
    width: '90%',
    height: '5%',
    left: '5%',
    marginTop: '8%',
    marginBottom: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxtitleModalInsertar2Mobile: {
    position: 'relative',
    width: '90%',
    top: '10%',
    height: '5%',
    left: '5%',
    marginBottom: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Montserrat'
  },
  iconBtn: {
    left: '90%',
    top: '2%',
    position: 'absolute',
  },
  form: {
    position: 'absolute',
    top: '20%',
    left: '10%',    
    width: '85%',
    height: '80%'
  },
  inputEmail: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputNombre: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputContraseña: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    color: 'white',
    marginRight: '10%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    color: 'white',
    marginLeft: '5%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnCancelarMobile: {
    backgroundColor: '#d9534f',
    color: 'white',
    marginLeft: '5%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5em',
    height: '6em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ['@media (max-width:1150px)']:{
      width: '60px',
      height: '90px',
    },
  },
  boxBotones: {
    position: 'relative',
    width: '90%',
    height: '15%',
    top: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxBotonesD: {
    position: 'relative',
    width: '90%',
    left: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fondoModalInsertarMobile: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflowX: 'hidden',
  },
  iconBtnMobile: {
    left: '78%',
    top: '-1%',
    position: 'absolute',
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '7%',
    width: '35%',
    position: 'absolute',
    top: '90%',
    left: '80%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  homeFotoMobile: {   
    height: '1.8em',
    position: 'relative',

  },
  exito: {
    color: 'green',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '12%',
    width: '30%',
    position: 'absolute',
    top: '90%',
    left: '70%',
    border: '3px',
    borderRadius: '5px',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divExtioMobile: {
    backgroundColor: '#D3E9D0',
    height: '3%',
    width: '90%',
    position: 'absolute',
    top: '90%',
    left: '5%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'

  },
  divErrorMobile: {
    backgroundColor: '#ff9090',
    height: '20%',
    width: '90%',
    position: 'absolute',
    top: '80%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  textNombre: {
    fontFamily: 'Montserrat'
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes2: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  boxOdses: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    left: '5%',
    alignItems: 'center',  
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  fotoOds: {
    width: '6.5em',
    height: 'auto',
    margin: '1em'
  },
  fotoOdsM: {
    width: '3.5em',
    height: 'auto',
    margin: '0.2em',
    align: 'center'
  },
  triangulos: {
    position: 'absolute',
    width: '70%',
    height: '99%',
    left:'0.1%',
    top: '1%',
  }

}));

export default function MenuPage() {

  CleanConsole.init()

  const nombreRef = React.useRef();
  const correoRef = React.useRef();
  const telefonoRef = React.useRef(); 
  const comentarioRef = React.useRef(); 
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorMessage, setError] = useState([]); 
  const [EnviarMessage, setDataContacto] = useState([]); 


 
  

      
  const [state, setState] = React.useState({
    mobileView: false,
    experiencia: [],
    
    
    
  });

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [open1, setOpen1] = React.useState(false);
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  function noPdf(){
    alert("No se ha subido archivo PDF")
  }



  function enviarContacto(){
    const data = { 
      noticia_contacto: {
        noticia_id: state.experiencia.id,
        nombre: nombreRef.current.value,
        telefono: telefonoRef.current.value,
        email: correoRef.current.value,          
        comentario: comentarioRef.current.value,     

      }              
                    
    };
    axios.post(localStorage.getItem('URL') + '/main/api/noticia/contacto', data)
    .then((response) => {
      setDataContacto(response.data.message)
      cambiarFormularioEnviado(true);
      handleClose1();
      setTimeout(() => cambiarFormularioEnviado(false), 5000);
    })

    .catch((error) => {
      console.log(error);
      setError(error.response.data.message);
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000);
      
    });
  }


  
 

 

  React.useEffect(() => {    
    getNoticia();
  }, []);

  const  {hash}  = useParams();
  function getNoticia(){ 
    
    axios
    .get(localStorage.getItem('URL') + "/main/api/noticia/detail?hash="+hash, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, experiencia: response.data.data }));
      
      
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {    
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 

    const convertTextToLinks = (text) => {
      const urlRegex = /(?:https?:\/\/|www\.)[^\s<>]+/g;
      return text.replace(urlRegex, (url) => {
        // Agregar "https://" si la URL comienza con "www."
        if (url.startsWith('www.')) {
          return `<a href="https://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        }
        // Si la URL comienza con "http://" o "https://", utilizarla tal cual
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };

    return (

      <Box>
        <Box className={classes.boxFondoMobile}>
        <Box className={classes.boxHomeMobile}>
        { (localStorage.getItem('session_token')) == undefined ? <a href="#/"><img  alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a> :
              <a href="#/menu"><img  alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a> }
        </Box>  
            <img alt="" src={LegalArriba}className={classes.legalImagenMobile}></img>  
        </Box>      
        <Box className={classes.boxFondoTodoMobile}>
          <Box className={classes.boxTitleModalInsertarMobile}>
        <Typography className={classes.titleModalInsertarM} variant="h5" >
            {state.experiencia.nombre}
            </Typography>
            </Box>
            <Box className={classes.fondoFormulario2Mobile}>
            <Typography className={classes.textPais2Mobile}>País: &nbsp; <a className={classes.textPais3Mobile}> {state.experiencia.pais_nombre == "" ? "Sin información": state.experiencia.pais_nombre} </a></Typography>
            <Typography className={classes.textAño2Mobile}>Año: &nbsp; <a className={classes.textAño3Mobile}> {state.experiencia.ano == null ? "Sin información": state.experiencia.ano} </a></Typography>
            <Typography className={classes.textDescripcion2Mobile}>Descripción</Typography>
            <Box className={classes.boxTextDescripcion3Mobile}>            
            <Typography className={classes.textDescripcion3Mobile}>{ ReactHtmlParser(state.experiencia.descripcion)}</Typography>            
            </Box><br></br>
            <Box className={classes.boxFotoDescripcion1Mobile}>
            {state.experiencia.imagen1_filename != '' ? <img alt="" src={state.experiencia.imagen1_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} className={classes.imagen1}></img>}
            </Box><br></br>
            <Typography className={classes.textResultados2Mobile}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3Mobile}>

            {state.experiencia.resultados && (
        <Typography className={classes.textResultados3}>
          {ReactHtmlParser(convertTextToLinks(state.experiencia.resultados))}
        </Typography>
      )}     

            </Box><br></br>
            <Box className={classes.boxFotoDescripcion2Mobile}>
            {state.experiencia.imagen2_filename != '' ? <img alt="" src={state.experiencia.imagen2_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} className={classes.imagen1}></img>}
            </Box>
            
            {/* <br></br>
            <Typography className={classes.textQuieresMobile}>¿Quieres saber más?</Typography><br></br>
            <Typography className={classes.textQuieres2Mobile}>Para profundizar sobre la experiencia podrás descargar la ficha descriptiva completa, o, podrás contactarnos para obtener más información de tu interés.</Typography><br></br>
            <Box className={classes.boxImagenesAbajo}>
            {state.experiencia.pdf_filename != '' ? <a href={state.experiencia.pdf_filename} rel="noreferrer"  target="_blank"><img alt="" src={descargar} className={classes.descargarFotoMobile}  ></img></a> :<img src={descargar}  alt="" className={classes.descargarFotoMobile} onClick={noPdf} ></img>}
            </Box><br></br>
            <Box className={classes.boxImagenesAbajo2}>
            <img alt="" src={conocerMas} className={classes.conocerFotoMobile} onClick={handleOpen1}  ></img>
            </Box><br></br> */}
            
            <Modal 
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertarMobile}>
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1}                   
                    className={classes.iconBtnMobile}
                  >  
                    <CancelPresentationIcon fontSize="large" />
                  </IconButton>
                <Box  className={classes.boxtitleModalInsertar2Mobile}>
                <Typography className={classes.titleModalInsertar2} variant="h6" component="h2">
                    CONOCE MÁS
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <Typography className={classes.textNombre}  id="modal-modal-description" sx={{ mt: 2 }}>
                    Email:
                  </Typography>
                  <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputEmail} inputRef={correoRef} />
                  </Box>
                  <Box>
                    <Typography  className={classes.textNombre}>Nombre:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputNombre}  inputRef={nombreRef} />         
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Telefono:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" className={classes.inputContraseña} inputRef={telefonoRef}  /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Comentarios:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} multiline rows={5} className={classes.inputContraseña} inputRef={comentarioRef}  /> 
                  </Box>
                  { errorFormulario &&<div className={classes.divErrorMobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
                  <Box className={classes.boxBotones}> 
                  <Button
                    variant="contained" 
                    size="large"
                    className={classes.btnInsertar}   
                    onClick={enviarContacto}      
                      >
                    Enviar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelarMobile}
                  >
                    Cancelar
                  </Button>
                  </Box>
                  
                    </Box>                         
                  </Box>
                  
                  
            </Modal>  
            { formularioEnviado && <div className={classes.divExtioMobile}> <Typography className={classes.exito}>{EnviarMessage}</Typography> </div>}
              {state.experiencia.odses != undefined ?
                          <Box className={classes.boxOdsMobile}>                            
                            {state.experiencia.odses.map(noticia_ods=>(                        
                          <img alt="" className={classes.fotoOdsM} src={noticia_ods.imagen}></img> 
                        ))}

                          </Box>:<Box></Box>
                        }
            <Box className={classes.boxRedesMobile}>   
                                
              <FacebookShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"+state.experiencia.hash}
          quote={"¡Mira esta experiencia!"}          
          
        >
          <img alt="" src={facebook} className={classes.facebookFotoMobile} ></img>
        </FacebookShareButton>      
        <TwitterShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"+state.experiencia.hash}
          quote={"¡Mira esta experiencia!"} 
          
          
          
        >
          <img alt="" src={twitter} className={classes.instagramFotoMobile}></img>
        </TwitterShareButton>      
            
            <LinkedinShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"+state.experiencia.hash}
          quote={"¡Mira esta experiencia!"}
   
          
          
        >
          <img alt="" src={linkedin} className={classes.linkedinFotoMobile}></img>
        </LinkedinShareButton> 
            
              </Box> 
              <br></br>
            </Box>
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes2}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'  rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank"><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>     
            
          
        </Box>  
        
      </Box>
      
      );
    }
  
  const displayDesktop = () => {

    const convertTextToLinks = (text) => {
      const urlRegex = /(?:https?:\/\/|www\.)[^\s<>]+/g;
      return text.replace(urlRegex, (url) => {
        // Agregar "https://" si la URL comienza con "www."
        if (url.startsWith('www.')) {
          return `<a href="https://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        }
        // Si la URL comienza con "http://" o "https://", utilizarla tal cual
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };
  
    return (
        <Box>     
            <Box className={classes.boxFondo}>
            <img alt=""  src={triangulos} className={classes.triangulos}></img>
            <Box className={classes.boxHome}>
              { (localStorage.getItem('session_token')) == undefined ? <a href="#/"><img  alt="" src={HomeImage} className={classes.homeFoto}></img></a> :
              <a href="#/menu"><img  alt="" src={HomeImage} className={classes.homeFoto}></img></a> }        
            </Box>
            <img alt="" src={LegalArriba}className={classes.legalImagen}></img> 
            </Box>       
        <Box className={classes.boxFondoTodo}>
          <Box className={classes.boxTitleModalInsertar}>
        <Typography className={classes.titleModalInsertar}  >
            {state.experiencia.nombre}
            </Typography>
            </Box>
            <Box className={classes.fondoFormulario2}>
            <br></br><br></br><Typography className={classes.textPais2}>País:  &nbsp;<a className={classes.textPais3}>{state.experiencia.pais_nombre == "" ? "Sin información": state.experiencia.pais_nombre}</a></Typography><br></br>
            <Typography className={classes.textAño2}>Año:  &nbsp;<a className={classes.textAño3}>{state.experiencia.ano == null ? "Sin información": state.experiencia.ano} </a> </Typography><br></br>
            <Typography className={classes.textDescripcion2}>Descripción</Typography><br></br>
            <Box className={classes.boxTextDescripcion3}> 
            <Box className={classes.boxFotoDescripcion1}>
            {state.experiencia.imagen1_filename != '' ? <img alt="" src={state.experiencia.imagen1_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} style={{width: '100%', height: '100%'}} ></img>}            
            </Box>           
            <Typography className={classes.textDescripcion3}>{ ReactHtmlParser(state.experiencia.descripcion)}</Typography>            
            </Box>   <br></br><br></br><br></br>         
            <Typography className={classes.textResultados2}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3}>
            <Box className={classes.boxFotoDescripcion2}>
            {state.experiencia.imagen2_filename != '' ? <img alt="" src={state.experiencia.imagen2_filename} className={classes.imagen1} ></img> :<img alt="" src={fondoNoticia} style={{width: '100%', height: '100%'}} ></img>}
            </Box>
            {state.experiencia.resultados && (
        <Typography className={classes.textResultados3}>
          {ReactHtmlParser(convertTextToLinks(state.experiencia.resultados))}
        </Typography>
      )}           
            </Box><br></br><br></br>
            
            {/* <Box className={classes.boxTextQuieres}>
            <Typography className={classes.textQuieres}>¿Quieres saber más?</Typography>
            </Box><br></br>
            <Box className={classes.boxTextQuieres2}>
            <Typography className={classes.textQuieres2}>Para profundizar sobre la experiencia podrás descargar la ficha descriptiva completa, o, podrás contactarnos para obtener más información de tu interés.</Typography>
            </Box><br></br><br></br>
            <Box className={classes.boxBotonesD}>
            {state.experiencia.pdf_filename != '' ? <a href={state.experiencia.pdf_filename} rel="noreferrer"   target="_blank"><img alt="" src={descargar} className={classes.descargarFoto}  ></img></a> :<img alt="" src={descargar} className={classes.descargarFoto} onClick={noPdf} ></img>}    
            <img alt="" src={conocerMas} className={classes.conocerFoto} onClick={handleOpen1}  ></img>
            </Box><br></br><br></br> */}


            <Box >
            {state.experiencia.odses != undefined ?
                          <Box className={classes.boxOdses}>                            
                            {state.experiencia.odses.map(noticia_ods=>(                        
                          <img alt="" className={classes.fotoOds} src={noticia_ods.imagen}></img> 
                        ))}

                          </Box>:<Box></Box>
                        }
            </Box><br></br>
            <Modal 
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertar}>
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1}                   
                    className={classes.iconBtn}
                  >  
                    <CancelPresentationIcon fontSize="large" />
                  </IconButton>
                <Box  className={classes.boxtitleModalInsertar2}>
                <Typography className={classes.titleModalInsertar2} variant="h6" component="h2">
                    CONOCE MÁS
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <Typography  className={classes.textNombre} id="modal-modal-description" sx={{ mt: 2 }}>
                    Email:
                  </Typography>
                  <TextField variant="outlined" inputRef={correoRef} inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputEmail}  />
                  </Box>
                  <Box>
                    <Typography  className={classes.textNombre}>Nombre:</Typography>
                    <TextField variant="outlined" className={classes.inputNombre} inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={nombreRef}  />         
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Telefono:</Typography>
                    <TextField variant="outlined" type="number" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputContraseña} inputRef={telefonoRef}  /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Comentarios:</Typography>
                    <TextField variant="outlined" multiline inputProps={{ style: {  fontFamily: 'Montserrat' } }}s rows={5} className={classes.inputContraseña} inputRef={comentarioRef}   /> 
                  </Box>
                  { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
                  <Box className={classes.boxBotones}> 
                  <Button
                    variant="contained" 
                    size="large"
                    className={classes.btnInsertar} 
                    onClick={enviarContacto}     
                      >
                    Enviar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                  </Box>
                  
                    </Box>                         
                  </Box>
                  
            </Modal> 
            { formularioEnviado && <div className={classes.divExtio}> <Typography className={classes.exito}>{EnviarMessage}</Typography> </div>}
            <Box className={classes.boxRedes}>            
              <FacebookShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"+state.experiencia.hash}
          quote={"¡Mira esta experiencia!"}          
          
        >
          <img alt="" src={facebook} className={classes.facebookFoto} ></img>
        </FacebookShareButton>      
        <TwitterShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"+state.experiencia.hash}
          quote={"¡Mira esta experiencia!"} 
          
          
          
        >
          <img alt="" src={twitter} className={classes.instagramFoto}></img>
        </TwitterShareButton>      
            
            <LinkedinShareButton
          url={"https://innovacion-infosegura.org/#/experiencia/"}
          quote={"¡Mira esta experiencia!"}
        
          
          
        >
          <img alt="" src={linkedin} className={classes.linkedinFoto}></img>
        </LinkedinShareButton> 
            
              </Box> 
            </Box>  
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes2}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'  rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img  alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
                   
        </Box>  
                  
        </Box>
        
        
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }