import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-05.png';
import circulos from './images/circulos-06.png';
import HomeImage from './images/home-37.png';
import Acceder from './images/acceder-01-R.png';
import axios from 'axios';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Button from '@material-ui/core/Button';
import { CleanConsole } from '@eaboy/clean-console';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  registrarteMobile: {
    fontSize: '1em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '1em',
    left: '30%',
    fontFamily: 'Montserrat',
    // eslint-disable-next-line
    ['@media (min-width:550px)']:{
      fontSize: '2em',
      left: '15%',
    },
    // eslint-disable-next-line
    ['@media (min-width:750px)']:{
      fontSize: '3em',
      left: '15%',
    },
    
  },
  textoArriba: {
    fontSize: '4em',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    top: '30%',
    left: '15%'
  },
  textoArribaMobile: {
    fontSize: '0.8em',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    top: '3em',
    left: '40%'
  },
  fondoFormulario: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '70%',    
    height: '25em',
    left: '15%',
    marginTop: '5em',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    // eslint-disable-next-line
    ['@media (max-height:750px)']:{
      width: '60%',    
      height: '20rem',
      left: '20%',
      marginTop: '2em',

    },
  },
  fondoFormularioMobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '80%',    
    height: '15em',
    left: '10%',
    marginTop: '5%',
    marginBottom: '5%',
    position: 'relative',
    border: '3px',
    borderRadius: '20px'
  },
  fondoArriba: {
    width: '100%',
    height: '15em'
  },
  fondoArribaMobile: {
    width: '100%',
  },
  textCorreo: {
    color: 'white',
    fontSize: '180%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCorreoMobile: {
    color: 'white',
    fontSize: '110%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseña: {
    color: 'white',
    fontSize: '180%',
    top: '40%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textOlvidaste: {
    color: 'white',
    fontSize: '140%',
    position: 'absolute',
    fontFamily: 'Montserrat',
    cursor: 'pointer'
  },
  textContraseñaMobile: {
    color: 'white',
    fontSize: '110%',
    top: '40%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textRecuperarMobile: {
    color: 'white',
    fontSize: '100%',
    top: '58%',
    position: 'absolute',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    width: '100%',
  },
  inputCorreo: {
    backgroundColor: 'white',
    top: '20%',
    left: '31%',
    width: '60%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    // eslint-disable-next-line
    ['@media (max-width:1050px)']:{
      left: '40%',
      width: '50%'
    },
  },
  inputCorreoMobile: {
    backgroundColor: 'white',
    top: '20%',
    left: '47%',
    width: '48%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute'
  },
  inputContraseña: {
    backgroundColor: 'white',
    top: '40%',
    left: '31%',
    width: '60%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute',
    // eslint-disable-next-line
    ['@media (max-width:1050px)']:{
      left: '40%',
      width: '50%'
    },
  },
  inputContraseñaMobile: {
    backgroundColor: 'white',
    top: '40%',
    left: '47%',
    width: '48%',
    border: '3px',
    borderRadius: '5px',
    position: 'absolute'
  },
  botonAcceder: {   
    border: '3px',
    borderRadius: '10px'
  },
  boxbotonAcceder: {
    top: '70%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  botonAccederMobile: {   
    position: 'absolute',
    width: '10em',
    border: '3px',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  boxbotonAccederMobile: {   
    top: '75%',    
    position: 'absolute',
    width: '100%',
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  homeFoto: {   
    height: '3em',
    position: 'relative',

  },
  homeFotoMobile: {   
    height: '1.8em',
    position: 'relative',

  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '80px',
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // eslint-disable-next-line
    ['@media (max-width:1150px)']:{
      width: '60px',
      height: '90px',
    },
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '2em',
    height: '3em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '5%'
  },
  legalFotoMobile: {   
    width:'100%',
    top: '85%',
    height: '10%',
    position: 'fixed',
    marginTop: '10%'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '15%',
    position: 'absolute',
    top: '70%',
    left: '10%',
    border: '3px',
    borderRadius: '5px'

  },
  divExtioMobile: {
    backgroundColor: '#D3E9D0',
    height: '10%',
    width: '45%',
    position: 'absolute',
    top: '70%',
    left: '10%',
    border: '3px',
    borderRadius: '5px'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '25%',
    position: 'absolute',
    top: '70%',
    left: '65%',
    border: '3px',
    borderRadius: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    // eslint-disable-next-line
    ['@media (max-width:1350px)']:{
      width: '40%',
      left: '75%',
    },
  },
  divError2: {
    backgroundColor: '#ff9090',
    height: '20%',
    width: '70%',
    position: 'absolute',
    top: '75%',
    left: '15%',
    border: '3px',
    borderRadius: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',

  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    height: '20%',
    width: '80%',
    position: 'absolute',
    top: '75%',
    left: '10%',
    border: '3px',
    borderRadius: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',

  },
  divExtio2M: {
    backgroundColor: '#D3E9D0',
    height: '5em',
    width: '80%',
    position: 'relative',
    left: '10%',
    border: '3px',
    borderRadius: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',

  },
  divErrorMobile: {
    backgroundColor: '#ff9090',
    height: '20%',
    width: '80%',
    position: 'absolute',
    top: '105%',
    left: '10%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
    
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px',
    
  
  },
  contenedor: {
    position: 'relative',
    minHeight: '70vh',
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'
  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'
  },
  circulos: {
    position: 'absolute',
    left: '10%',
    height: '23%',
    top: '2%',
    width: '70%'
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  fondoModalInsertarM: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  titleModalInsertar2: {
    fontSize: '150%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    // eslint-disable-next-line
    ['@media (max-width:1050px)']:{
      fontSize: '120%'

    },
    

  },
  boxtitleModalInsertar2: {
    position: 'relative',
    width: '90%',
    height: '5%',
    left: '5%',
    marginTop: '15%',
    marginBottom: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxtitleModalInsertar2M: {
    position: 'relative',
    width: '90%',
    height: '5%',
    left: '5%',
    marginTop: '13vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  inputEmail: {
    width: '90%',
    marginBottom: '2%',
    left: '5%',
    border: '1px solid #1C3062'
  },
  inputEmailM: {
    width: '90%',
    marginBottom: '10%',
    left: '5%',
    border: '1px solid #1C3062',
    marginTop: '10%'
  },
  boxBotones: {
    position: 'relative',
    width: '90%',
    height: '15%',
    left: '5%',
    marginTop: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    color: 'white',
    marginRight: '10%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    color: 'white',
    marginLeft: '5%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  iconBtn: {
    right: '3%',
    top: '2%',
    position: 'absolute',
  },

}));

export default function AccederPage() {
  CleanConsole.init()

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen1M = () => {
    setOpen1M(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose1M = () => {
    setOpen1M(false);
  };
  const [open1, setOpen1] = React.useState(false);
  const [open1M, setOpen1M] = React.useState(false);

  const correoRef = React.useRef();
  const correoRecuperarRef = React.useRef();
  const contraseñaRef = React.useRef();
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [formularioEnviado2, cambiarFormularioEnviado2] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorFormulario2, cambiarErrorFormulario2] = useState(false);
  const [errorMessage, setError] = useState([]); 

      
  const [state, setState] = React.useState({
    mobileView: false,
    
  });


  

  function enviarUser(){
    const data = {               
          email: correoRef.current.value,          
          password: contraseñaRef.current.value,                
    };
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/login', data)
    .then((response) => {
      return response.data; 
      
    })
    .then(response => {
      // eslint-disable-next-line
      if(response.data.message="login exitoso"){
        
        cambiarFormularioEnviado(true);
        localStorage.setItem('usuario_id', response.data.usuario.id);
        localStorage.setItem('session_token', response.data.session_token);
        localStorage.setItem('email', response.data.usuario.email);
        localStorage.setItem('nombre', response.data.usuario.nombres);
        setTimeout(() => cambiarFormularioEnviado(false), 5000);
        window.location.href="/#/menu";
      }
      
    })
    .catch((error) => {
      setError(error.response.data.message)
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000);
    });
  }
  function redireccionar(){
     window.location.href="/#/"
   }
  function recuperarContraseña(){
    const data = {               
          email: correoRecuperarRef.current.value,    
    };
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/recuperar', data)
    .then((response) => {
      console.log(response.data)
      cambiarFormularioEnviado2(true);
      setTimeout(() => cambiarFormularioEnviado2(false), 5000);
      setTimeout(() => redireccionar(), 5000);
      handleClose1M();
      

      
    })    
    .catch((error) => {
      console.log(error)
      setError(error.response.data.message)
      cambiarErrorFormulario2(true);
      setTimeout(() => cambiarErrorFormulario2(false), 5000);
      
    });
  }
 

  const { mobileView } = state;
  const classes = useStyles();


  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box style={{ backgroundImage: 'url(./fondo/fonodo-azulclaro.png)', height: '100%', backgroundSize: 'cover'}}  > 
        <Box className={classes.contenedor}>
        <Box >
            <Box className={classes.boxHomeMobile}>
              <a href="#/"><img alt=""  src={HomeImage} className={classes.homeFotoMobile}></img></a>
            </Box>
            <img  alt=""  src={FondoArriba} className={classes.fondoArribaMobile}></img>
            <Typography className={classes.registrarteMobile}>
            Ingresa, ¡Es rápido y fácil!
            </Typography>
 
          </Box>                
          <Box className={classes.fondoFormularioMobile} >
            <Box>
              <Typography  className={classes.textCorreoMobile}>Correo:</Typography>
              <TextField variant="outlined"  className={classes.inputCorreoMobile} inputProps={{ style: {  fontFamily: 'Montserrat' } }} size="small" inputRef={correoRef} />         
            </Box> 
            <Box>
              <Typography className={classes.textContraseñaMobile}>Contraseña:</Typography>
              <TextField type="password" variant="outlined"  className={classes.inputContraseñaMobile} size="small" inputRef={contraseñaRef} />         
            </Box> 
            <Typography onClick={handleOpen1M}  className={classes.textRecuperarMobile}>¿Olvidaste tu contraseña?</Typography>
            <Box className={classes.boxbotonAccederMobile}>
              <img alt=""  src={Acceder} className={classes.botonAccederMobile} onClick={enviarUser} ></img>         
            </Box>
            
            { formularioEnviado && <div className={classes.divExtioMobile}> <Typography className={classes.exito}>¡Login Exitoso!</Typography> </div>}
            
            { errorFormulario &&<div className={classes.divErrorMobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            </Box>
            <Modal 
              open={open1M}
              onClose={handleClose1M}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              
              <Box className={classes.fondoModalInsertarM}>
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1M}                   
                    className={classes.iconBtn}
                  >  
                    <CancelPresentationIcon fontSize="large" />
                  </IconButton>
                <Box  className={classes.boxtitleModalInsertar2M}>
                <Typography className={classes.titleModalInsertar2} variant="h6" component="h2">
                  Ingresa tu correo electrónico
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputEmailM} inputRef={correoRecuperarRef} />
                  </Box>      
                  <Box className={classes.boxBotones}> 
                  <Button
                    variant="contained" 
                    size="large"
                    className={classes.btnInsertar} 
                    onClick={recuperarContraseña}  
                      >
                    Enviar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1M}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                  </Box>
                  
                    </Box>  
                    {errorFormulario2 && <div className={classes.divError2}><Typography className={classes.error}> {errorMessage} </Typography> </div>}                                          
                  
                  </Box>
            </Modal>
            {formularioEnviado2 && <div  className={classes.divExtio2M}><Typography className={classes.exito}> Contraseña reestablecida exitosamente, revise su correo e ingrese a la plataforma. </Typography> </div> }                                        
          </Box>
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img  alt=""  src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"   target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img src={msgFooter} alt=""  className={classes.redesFooter}></img></a>
                
                </Box>                
              </Box>
              
            </footer>
      </Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box>
           <Box style={{ backgroundImage: 'url(./fondo/fonodo-azulclaro.png)', height: '100%'}}>  
          <Box>
            <Box className={classes.boxHome}>
              <a href="#/"><img  alt=""  src={HomeImage} className={classes.homeFoto}></img></a>
            </Box>
            <img alt=""  src={FondoArriba} className={classes.fondoArriba}></img>
            <img alt=""  src={circulos} className={classes.circulos}></img>
            <Typography className={classes.registrarte}>
            Ingresa, ¡Es rápido y fácil!
            </Typography>
          
          </Box>
                      
          <Box className={classes.fondoFormulario} >
            <Box>
              <Typography  className={classes.textCorreo}>Correo:</Typography>
              <TextField variant="outlined"  className={classes.inputCorreo} inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={correoRef} />         
            </Box> 
            <Box>
              <Typography className={classes.textContraseña}>Contraseña:</Typography>
              <TextField type="password" variant="outlined"  className={classes.inputContraseña} inputRef={contraseñaRef} />         
            </Box> 
            <Box style={{width: '100%', position: 'absolute', top: '58%', height: '1em',  display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
              <Typography onClick={handleOpen1}  className={classes.textOlvidaste}>¿Olvidaste tu contraseña?</Typography>
            </Box> 
            <Box className={classes.boxbotonAcceder}>
              <img src={Acceder} alt=""  className={classes.botonAcceder} onClick={enviarUser} ></img>         
            </Box>
            
            { formularioEnviado && <div className={classes.divExtio}> <Typography className={classes.exito}>¡Login Exitoso!</Typography> </div>}
            
            { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            </Box>
                        

            <Modal 
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              
              <Box className={classes.fondoModalInsertar}>
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1}                   
                    className={classes.iconBtn}
                  >  
                    <CancelPresentationIcon fontSize="large" />
                  </IconButton>
                <Box  className={classes.boxtitleModalInsertar2}>
                <Typography className={classes.titleModalInsertar2} variant="h6" component="h2">
                  Ingresa tu correo electrónico
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputEmail} inputRef={correoRecuperarRef} />
                  </Box>      
                  <Box className={classes.boxBotones}> 
                  <Button
                    variant="contained" 
                    size="large"
                    className={classes.btnInsertar} 
                    onClick={recuperarContraseña}  
                      >
                    Enviar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                  </Box>
                  
                    </Box>  
                    {errorFormulario2 && <div className={classes.divError2}><Typography className={classes.error}> {errorMessage} </Typography> </div>}                                          
                    {formularioEnviado2 && <div className={classes.divExtio2}><Typography className={classes.exito}> Contraseña reestablecida exitosamente, revise su correo e ingrese a la plataforma. </Typography> </div>}                                          
                  </Box>
            </Modal>
             
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
            </Box> 
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }