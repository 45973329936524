import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HomeImage from './images/home-37.png';
import CargarImage from './images/cargar.png';
import EscribirImage from './images/escribir.png';
import logout from './images/logout.png';
import axios from 'axios';
import OpcionesImage from './images/opciones.png';
import Modal from '@material-ui/core/Modal';
import { useParams } from 'react-router';
import LegalArriba from './images/logos2-R.png';
import fondoNoticia from './images/fondoNoticia.png';
import conocerMas from './images/conocerMas.png';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LegalArriba2 from './images/logos2SinFondo.png';
import triangulos from './images/triangulos_Mesa de trabajo 1.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import FondoArriba from './images/fondo-usuarior.png';
import subeExperiencia from './images/sube-experiencia.png';
import enviar from './images/enviar-R.png';
import editarIcon from './images/editar_perfil-43-43.png';
import { CleanConsole } from '@eaboy/clean-console';








import {
  FacebookShareButton,  
  LinkedinShareButton,  
  TwitterShareButton,
} from "react-share";
import { NativeSelect } from '@material-ui/core';







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },

  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes2: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  opcionesFoto: {  
    height: '2.8em',
    position: 'absolute',
    left: '0.8%',
    top:'1%',
  
  },
  opcionesFotoMobile: {  
    height: '40px',
    position: 'absolute',
    left: '6%',
    top:'3em'     
  },
  opcionesFoto2: {  
    width: '65%',
    position: 'relative',
    marginTop: '0.5em'
 
  },
  opcionesFoto2Mobile: {  
    height: '100%',
    position: 'absolute',
    left: '5%',
  },
  escribirFoto: {   
    width: '65%',
    position: 'relative',

  },
  escribirFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '46%',
  },
  cargarFoto: {   
    width: '65%',
    position: 'relative',
  },
  logoutFoto: {   
    width: '100%',
    position: 'relative',
    marginBottom: '0.3em'
  },
  cargarFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '66%',
    
  },
  logoutFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '85%',
    
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '4.5em',
    height: '38vh',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '270px'

    
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '100%',
    height: '40px',
    top: '3em',
    display: 'flex'
  },
  homeFoto: {  
    width: '65%',
    position: 'relative',
    
  },
  fondoArriba: {
    width: '100%',
    height: '38vh',
    minHeight: '270px'

  },  
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '10%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  acceder: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    width: '300px'
  },
  fondoFormulario: {
      backgroundImage: 'url(./fondo/fondo4-20.png)',
      backgroundSize: '100%',
      width: '70%',
      height: '90em',
      left: '15%',
      position: 'relative',
      borderRadius: '20px',
      marginTop: '3%',
      ['@media (max-width:1200px)']:{
        width: '80%',
        left: '10%'
      },  
      
  },
  tituloForm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    marginTop: '1%',
    position: 'absolute'
  },
  inputNombre: {
    backgroundColor: 'white',
    top: '8%',
    left: '35%',
    width: '60%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  textNombre: {
    color: 'white',
    fontSize: '150%',
    top: '8%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputApellidos: {
    backgroundColor: 'white',
    top: '13%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  textApellidos: {
    color: 'white',
    fontSize: '150%',
    top: '13%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textApellidosMobile: {
    color: 'white',
    fontSize: '130%',
    top: '8%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCorreo: {
    color: 'white',
    fontSize: '150%',
    top: '18%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat',
    width: '38%',

  },
  textCorreoMobile: {
    color: 'white',
    fontSize: '130%',
    top: '13%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputCorreo: {
    backgroundColor: 'white',
    top: '18%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  textCargo: {
    color: 'white',
    fontSize: '150%',
    top: '23%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputCargo: {
    backgroundColor: 'white',
    top: '23%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputGeneroOtro: {
    backgroundColor: 'white',
    top: '27.5%',
    left: '70%',
    width: '25%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputPais: {
    backgroundColor: 'white',
    top: '37%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCiudad: {
    backgroundColor: 'white',
    top: '42%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputContraseña2: {
    backgroundColor: 'white',
    top: '47%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputContraseña3: {
    backgroundColor: 'white',
    top: '52%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputInstitucion: {
    backgroundColor: 'white',
    top: '57%',
    left: '45%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputGenero: {
    backgroundColor: 'white',
    top: '28%',
    left: '45%',
    width: '20%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  textGenero: {
    color: 'white',
    fontSize: '150%',
    top: '28%',
    left: '5%',
    width: '37%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },

  textFecha: {
    color: 'white',
    fontSize: '150%',
    top: '32%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat',
    width: '38%',


  },
  textPais: {
    color: 'white',
    fontSize: '150%',
    top: '37%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudad: {
    color: 'white',
    fontSize: '150%',
    top: '42%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseña: {
    color: 'white',
    fontSize: '150%',
    top: '46%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textContraseña2: {
    color: 'white',
    fontSize: '150%',
    top: '47%',
    left: '5%',
    width: '38%',
    position: 'absolute',
    fontFamily: 'Montserrat',


  },
  textContraseña3: {
    color: 'white',
    fontSize: '150%',
    top: '52%',
    left: '5%',
    width: '38%',
    position: 'absolute',
    fontFamily: 'Montserrat',

  },
  textInstitucion: {
    color: 'white',
    fontSize: '150%',
    top: '57%',
    left: '5%',
    width: '38%',
    position: 'absolute',
    fontFamily: 'Montserrat',
    ['@media (max-width:1400px)']:{
      top: '56%'
    },
  },
  textTwitter: {
    color: 'white',
    fontSize: '150%',
    top: '66%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textInfor: {
    color: 'white',
    fontSize: '150%',
    top: '62%',
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textWeb: {
    color: 'white',
    fontSize: '150%',
    top: '70%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFacebook: {
    color: 'white',
    fontSize: '150%',
    top: '75%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textLinkedin: {
    color: 'white',
    fontSize: '150%',
    top: '80%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textTelefono: {
    color: 'white',
    fontSize: '150%',
    top: '85%',
    left: '5%',
    width: '40%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFechaMobile: {
    color: 'white',
    fontSize: '130%',
    top: '43%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputFecha: {
    backgroundColor: 'white',
    top: '32%',
    left: '45%',
    width: '48.5%',
    height: '2.2%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute',
    padding: '0 7px'
  },
  inputTwitter: {
    backgroundColor: 'white',
    top: '66%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputWeb: {
    backgroundColor: 'white',
    top: '70.5%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputFacebook: {
    backgroundColor: 'white',
    top: '75%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputLinkedin: {
    backgroundColor: 'white',
    top: '79.5%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputTelefono: {
    backgroundColor: 'white',
    top: '84%',
    left: '35%',
    width: '60%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  boxbotonEnviar: {    
    top: '91%',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  boxbotonEnviarM: {    
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5%',
    marginBottom: '5%'

  },
  botonEnviarM: {     
    border: '2px',
    borderRadius: '10px',
    fontFamily: 'Montserrat',
    width: '160px'
  },
  botonEnviar: {     
    border: '2px',
    borderRadius: '10px',
    fontFamily: 'Montserrat',
    width: '200px'
  },

  divExtio2: {
    backgroundColor: '#D3E9D0',
    opacity: '10',
    height: '7%',
    width: '40%',
    position: 'absolute',
    top: '90%',
    left: '70%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  
  },
  divExtio2Mobile: {
    backgroundColor: '#D3E9D0',
    height: '5em',
    width: '80%',
    position: 'relative',
    left: '10%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:'5%'
  
  },
  divError2Mobile: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '80%',
    position: 'relative',
    left: '10%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:'5%'
  
  },

  exito2: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
   
  },
  divError2: {
    backgroundColor: '#ff9090',
    height: '8%',
    width: '40%',
    position: 'absolute',
    top: '90%',
    left: '70%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },

  homeFotoMobile: {  
    height: '99%',
    position: 'absolute',
    left: '25%',   
  }, 
  fondoArribaMobile: {
    width: '100%',
    height: '8em',
  },  
  registrarteMobile: {
    fontSize: '2em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '1%',
    left: '5%',
    width: '90%',
    height: '1.3em',
    fontFamily: 'Montserrat',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  fondoFormularioMobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: '100%',
    width: '85%',    
    left: '7.5%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    padding: '0.1em 0',
    marginTop: '2%'
    
  },
  textMobile: {
    color: 'white',
    textAlign: 'center',
    marginTop: '3%',
    marginBottom: '3%',
    fontFamily: 'Montserrat'
  },
  tituloMobile:{
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  inputMobile: {
    backgroundColor: 'white',
    width: '90%',
    left: '5%',
    border: '3px',    
    borderRadius: '15px',
  },
  inputMobileFecha: {
    backgroundColor: 'white',
    width: '85%',
    left: '5%',
    border: '3px',    
    borderRadius: '15px',
    position: 'relative'
  }


}));

export default function MenuPage() {

  CleanConsole.init()

  const [Fecha,setFecha] = React.useState("");
  const nombreRef = React.useRef();
  const generoRef = React.useRef();
  const generoOtroRef = React.useRef();
  const paisRef = React.useRef();
  const passwordRef = React.useRef();
  const passwordRef2 = React.useRef();
  const ciudadRef = React.useRef();
  const apellidoRef = React.useRef();
  const cargoRef = React.useRef();
  const correoRef = React.useRef();
  const twitterRef = React.useRef();
  const webRef = React.useRef();
  const facebookRef = React.useRef();
  const linkedinRef = React.useRef();
  const telefonoRef = React.useRef(); 
  const institucionRef = React.useRef();
  const [fechaUsuarioDefault,fechaDefault] = React.useState();   
  const comentarioRef = React.useRef(); 
  const [errorMessage, setError] = useState([]); 
  const [EnviarMessage, setDataContacto] = useState([]); 
  const [idRowNow, setIdRowNow] = useState(0);
  const [errorFormulario2, cambiarErrorFormulario2] = useState(false);




 
  

  const [stateShow, setShow] = React.useState({
    showDiv: false,

  });
      
  const [state, setState] = React.useState({
    mobileView: false,
    experiencia: [],
    paises: [],
    usuario:[],
    showDiv: false,


    
    
    
  });

  const { showDiv } = state


  function getUsuario(usuarioID){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/usuario/detail?id="+usuarioID, {
      headers: {        
        'Session-Token': localStorage.getItem("session_token"),
        'Usuario-Id': localStorage.getItem("usuario_id"),
        
      }      
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, usuario: response.data.data }));
      console.log(response.data)
      fechaDefault(response.data.data)
      setIdRowNow(response.data.data.id)

      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  function mostrarOtro(){
    MostrarMenu(true)
    
  }
  function mostrarOtroG(e){
    var value= ""
    value= e.target.value
    if (value == "O"){
       setState((prevState) => ({ ...prevState, showDiv: true }));       
    }
    else {
      setState((prevState) => ({ ...prevState, showDiv: false })); 
    }
    
  }
  const [errorValidacion, cambiarErrorValidacion] = useState(false);
  const [errorValidacion2, cambiarErrorValidacion2] = useState(false);


  function editarUsuario(){ 
    let generoOtro=""
    let FechaE = ""
      if (state.showDiv == true){
      generoOtro=generoOtroRef.current.value
      }
      if (Fecha == undefined ){
        FechaE = fechaUsuarioDefault.fecha_nacimiento
      }
      if (Fecha == "" ){
        FechaE = fechaUsuarioDefault.fecha_nacimiento
      }
      else {
        FechaE = Fecha
      }
      if (passwordRef.current.value != passwordRef2.current.value){
        cambiarErrorValidacion(true)
        setTimeout(() => cambiarErrorValidacion(false), 5000);   
      }
      if (nombreRef.current.value  == ""){
        cambiarErrorValidacion2(true)
        setTimeout(() => cambiarErrorValidacion2(false), 5000);   
      }

      else{
      if (passwordRef.current.value == passwordRef2.current.value){
    const data = {
      usuario:
        { 
          id: ""+idRowNow+"",
          nombres: nombreRef.current.value,
          apellidos: apellidoRef.current.value,
          cargo: cargoRef.current.value,
          fecha_nacimiento: FechaE,
          genero: generoRef.current.value,
          genero_otro: generoOtro,
          pais_id: paisRef.current.value,
          ciudad: ciudadRef.current.value,
          password: passwordRef.current.value,
          institucion: institucionRef.current.value,
          twitter: twitterRef.current.value,
          web: webRef.current.value,
          facebook: facebookRef.current.value,
          linkedin: linkedinRef.current.value,
          telefono: telefonoRef.current.value,    
        }          
    };
    axios
    .put(localStorage.getItem('URL') + "/main/api/usuario/update", data, {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    }
    )
    .then((response) => {
      console.log(response.data)
      cambiarFormularioEnviado3(true);
      if (passwordRef.current.value == ""){
      setTimeout(() => cambiarFormularioEnviado3(false), 5000);
      setTimeout(() => redireccionar(), 3000); }
      
      else {
        setTimeout(() => cambiarFormularioEnviado3(false), 5000);
      setTimeout(() => cerrarSesion(), 3000);
      setTimeout(() => redireccionar2(), 3000);
     }
      
    
    
      
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)  
      cambiarErrorFormulario2(true);
      setTimeout(() => cambiarErrorFormulario2(false), 5000); 
     

    });
  } }}

  function redireccionar(){
    window.location.href="/#/menu"
  }

  function redireccionar2(){
    window.location.href="/#/"
  }

  function ocultarOtro(){
    MostrarMenu(false)
  }
  const [formularioEnviado3, cambiarFormularioEnviado3] = useState(false);
  const [errorFormulario3, cambiarErrorFormulario3] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [mostrarMenu, MostrarMenu] = useState(false);


  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/logout', data, {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }

  
  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, paises: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function handleChangeEvent(event) {
  
    setState((prevState) => ({ ...prevState, usuario: {
      [event.target.name]: event.target.value,
    } 
  }));
  }
  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
  }
 

  React.useEffect(() => {  
    getPaises();  
    getUsuario(localStorage.getItem('usuario_id'));
    validateSession();


  }, []);

  const  {hash}  = useParams();
  

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {    
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>   
        <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFotoMobile}></img></a>
              { mostrarMenu && <Box className={classes.boxHomeMobile}>
              <a  onClick={ocultarOtro}><img alt=""  src={OpcionesImage} className={classes.opcionesFoto2Mobile}></img></a>
              <a href="#/menu"><img alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFotoMobile}></img></a>
              <a href="#/menu/validaciones"><img alt="" src={CargarImage} className={classes.cargarFotoMobile}></img></a>
              <a href="#/"><img  alt="" src={logout} className={classes.logoutFotoMobile} onClick={cerrarSesion}></img></a>
            </Box>  }
            <Box>
            <img alt="" src={FondoArriba} className={classes.fondoArribaMobile}></img>                   
            <Typography className={classes.registrarteMobile}>
            {localStorage.getItem("nombre")}
            </Typography> 
            </Box>
            <Box > 
            <Box className={classes.fondoFormularioMobile}>
            <Typography className={classes.tituloMobile} variant="h6">
              Administra tu perfil
              </Typography> 
              <Typography className={classes.textMobile}>
              Nombres
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={nombreRef} variant="outlined" size="small" className={classes.inputMobile} value={state.usuario.nombres} onChange={handleChangeEvent}  /> 
              <Typography className={classes.textMobile}>
              Apellidos
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={apellidoRef} variant="outlined" size="small" className={classes.inputMobile} value={state.usuario.apellidos} onChange={handleChangeEvent}  /> 
              <Typography className={classes.textMobile}>
              Correo electrónico
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" size="small" className={classes.inputMobile} value={state.usuario.email} disabled  />                 
              <Typography className={classes.textMobile}>
              Cargo
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={cargoRef} variant="outlined" size="small" className={classes.inputMobile} value={state.usuario.cargo} onChange={handleChangeEvent}   />    
              <Typography className={classes.textMobile}>
              Género
              </Typography> 
              <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat', padding: "0.5em 0.5em " } }} inputRef={generoRef} variant="outlined" size="small" className={classes.inputMobile} value={state.usuario.genero} onChange={(e) => {mostrarOtroG(e) ; handleChangeEvent(e);}} >
                  <option > Seleccione... </option>    
                  <option value="M" > Masculino </option>    
                  <option value="F" > Femenino </option>    
                  <option value="O" > Otro </option> 
              </NativeSelect>  
              {showDiv && <div style={{marginTop: '5%'}}>
                  <TextField  placeholder="Específica" inputProps={{ style: {  fontFamily: 'Montserrat', } }} inputRef={generoOtroRef} value={state.usuario.generoOtro} size="small" variant="outlined"  className={classes.inputMobile}  />         
                 </div>}  
              <Typography className={classes.textMobile}>
              Fecha de nacimiento
              </Typography> 
              <input style={{fontFamily: 'Montserrat', padding: '0.5em 0.5em'}} type="date" name="Fecha" variant="outlined" size="small" className={classes.inputMobileFecha} onChange={(e) => {setFecha(e.target.value); handleChangeEvent(e);}} value={state.usuario.fecha_nacimiento}  /> 
              <Typography className={classes.textMobile}>
              País
              </Typography> 
              <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat', padding: '0.4em 1em' } }}  inputRef={paisRef} name="paises"  variant="outlined" className={classes.inputMobile}   defaultValue="0" value={state.usuario.pais_id} onChange={handleChangeEvent}> 
              <option value='0'> Seleccione... </option>
              {state.paises.map(elemento=>(
                <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                )
              )}
            </NativeSelect>  
              <Typography className={classes.textMobile}>
              Ciudad
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={ciudadRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.ciudad} onChange={handleChangeEvent} />       
              <Typography className={classes.textMobile}>
              Contraseña Nueva
              </Typography> 
              <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef} variant="outlined" size="small" className={classes.inputMobile}  />            
              <Typography className={classes.textMobile}>
              Repite tu contraseña
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef2} variant="outlined" size="small" className={classes.inputMobile}   /> 
              <Typography className={classes.textMobile}>
              Institución a la que pertenece
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={institucionRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.institucion} onChange={handleChangeEvent}   />       
              <Typography className={classes.textMobile}>
              Información de contacto (opcional)
              </Typography> 
              <Typography className={classes.textMobile}>
                Twitter
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={twitterRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.twitter} onChange={handleChangeEvent}   />  
              <Typography className={classes.textMobile}>
                Web
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={webRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.web} onChange={handleChangeEvent}   />  
              <Typography className={classes.textMobile}>
                Facebook
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={facebookRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.facebook} onChange={handleChangeEvent}  />  
              <Typography className={classes.textMobile}>
              LinkedIn
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={linkedinRef} variant="outlined" size="small" className={classes.inputMobile}   value={state.usuario.linkedin} onChange={handleChangeEvent} />  
              <Typography className={classes.textMobile}>
              Teléfono
              </Typography> 
              <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={telefonoRef} variant="outlined" size="small" className={classes.inputMobile}  value={state.usuario.telefono} onChange={handleChangeEvent}  />  
              <Box className={classes.boxbotonEnviarM}>
            <img alt="" src={enviar} className={classes.botonEnviarM} onClick={editarUsuario} ></img>         
              </Box>
            </Box>
            </Box>     
           {formularioEnviado3 && <div className={classes.divExtio2Mobile}> <Typography className={classes.exito2}>Usuario actualizado con exito</Typography> </div>}
          {errorFormulario2 &&<div className={classes.divError2Mobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
          { errorValidacion &&<div className={classes.divError2Mobile}><Typography className={classes.error}> La contraseña no coincide </Typography> </div> }
          { errorValidacion2 &&<div className={classes.divError2Mobile}><Typography className={classes.error}> El nombre no puede ser vacio </Typography> </div> }             
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes2}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'  rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank"><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>     
            
          
        
      </Box>
      
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>  
            <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto}></img></a>
              { mostrarMenu && <Box className={classes.boxHome}>
              <a  onClick={ocultarOtro}><img  alt="" src={OpcionesImage} className={classes.opcionesFoto2}></img></a>
              <a href="#/menu" ><img  alt="" src={HomeImage} className={classes.homeFoto}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFoto}></img></a>
              <a href="#/menu/validaciones"><img alt=""  src={CargarImage} className={classes.cargarFoto}></img></a>
              <a href="#/"><img alt="" src={logout} className={classes.logoutFoto} onClick={cerrarSesion}></img></a>
            </Box>  }
            <Box>
              <a href="#/menu/subeExperiencia" ><img alt=""  src={subeExperiencia}className={classes.acceder}></img></a>
            </Box>            
            <img  alt="" src={FondoArriba} className={classes.fondoArriba}></img>   
            <Typography className={classes.registrarte}>
            {localStorage.getItem("nombre")}
            </Typography>
            <Box >
            <Box className={classes.fondoFormulario}>
                <Typography variant="h3" className={classes.tituloForm}>
                    Administra tu perfil
                </Typography>
                <Box>
                    <Typography  className={classes.textNombre}>Nombres:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={nombreRef} variant="outlined" size="small"  className={classes.inputNombre} value={state.usuario.nombres} onChange={handleChangeEvent} />         
                </Box> 
                <Box>
                    <Typography className={classes.textApellidos}>Apellidos:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={apellidoRef} variant="outlined" size="small"  className={classes.inputApellidos}  value={state.usuario.apellidos} onChange={handleChangeEvent}/>         
                </Box> 
                <Box>
                    <Typography className={classes.textCorreo}>Correo electrónico:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  variant="outlined" size="small" className={classes.inputCorreo} value={state.usuario.email} disabled   />         
                </Box>
                <Box>
                    <Typography className={classes.textCargo}>Cargo:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={cargoRef}  size="small" variant="outlined"  className={classes.inputCargo} value={state.usuario.cargo} onChange={handleChangeEvent} />         
                </Box>
                <Box>
                <Typography  className={classes.textGenero}>Género:</Typography>
                <NativeSelect  value={state.usuario.genero} onChange={(e) => {mostrarOtroG(e) ; handleChangeEvent(e);}} inputProps={{ style: {  fontFamily: 'Montserrat', padding: '0.5em 1em' } }} inputRef={generoRef}   size="small" variant="outlined"  className={classes.inputGenero} >
                  <option > Seleccione... </option>    
                  <option value="M" > Masculino </option>    
                  <option value="F" > Femenino </option>    
                  <option value="O" > Otro </option>    
                </NativeSelect> 
                {showDiv && <div>
                  <TextField  placeholder="Específica" inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={generoOtroRef} value={state.usuario.generoOtro} size="small" variant="outlined"  className={classes.inputGeneroOtro}  />         
                 </div>}    
                </Box>
                <Box>
                    <Typography className={classes.textFecha}>Fecha de nacimiento:</Typography>
                    <input style={{fontFamily: 'Montserrat', padding: '0.1em 1em'}} type="date" name="Fecha" className={classes.inputFecha} variant="outlined"  onChange={(e) => {setFecha(e.target.value); handleChangeEvent(e);}} value={state.usuario.fecha_nacimiento}  />         
                </Box>
                <Box>
            <Typography className={classes.textPais}>País:</Typography>
            <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat', padding: '0.4em 1em' } }}  inputRef={paisRef} name="paises"  variant="outlined" className={classes.inputPais}   defaultValue="0" value={state.usuario.pais_id} onChange={handleChangeEvent}> 
              <option value='0'> Seleccione... </option>
              {state.paises.map(elemento=>(
                <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                )
              )}
            </NativeSelect>      
          </Box>
          <Box>
            <Typography className={classes.textCiudad}>Ciudad:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={ciudadRef} variant="outlined" size='small'  className={classes.inputCiudad} value={state.usuario.ciudad} onChange={handleChangeEvent} />         
          </Box>
          <Box>
            <Typography className={classes.textContraseña2}>Contraseña nueva:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef} variant="outlined" size='small' className={classes.inputContraseña2} />         
          </Box>      
          <Box>
            <Typography className={classes.textContraseña3}>Repite tu Contraseña:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" inputRef={passwordRef2} variant="outlined" size='small' className={classes.inputContraseña3} />         
          </Box>    
          <Box>
            <Typography className={classes.textInstitucion}>Institución a la que pertenece:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={institucionRef} variant="outlined" size='small' className={classes.inputInstitucion} value={state.usuario.institucion} onChange={handleChangeEvent} />         
          </Box>
          <Box>
          <Typography className={classes.textInfor}>Información de contacto (opcional) </Typography>
          <Box>
            <Typography className={classes.textTwitter}>Twitter: </Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={twitterRef}  variant="outlined" size='small' className={classes.inputTwitter} value={state.usuario.twitter} onChange={handleChangeEvent} />         
          </Box>
          <Box>
            <Typography className={classes.textWeb}>Web:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={webRef}  variant="outlined" size='small'  className={classes.inputWeb} value={state.usuario.web} onChange={handleChangeEvent} />         
          </Box>
          <Box>
            <Typography className={classes.textFacebook}>Facebook:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={facebookRef}  variant="outlined"size='small' className={classes.inputFacebook} value={state.usuario.facebook} onChange={handleChangeEvent} />         
          </Box>
          <Box>
            <Typography className={classes.textLinkedin}>LinkedIn:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={linkedinRef}  variant="outlined" size='small'className={classes.inputLinkedin} value={state.usuario.linkedin} onChange={handleChangeEvent} />         
          </Box>
          <Box>
            <Typography  className={classes.textTelefono}>Teléfono:</Typography>
            <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  inputRef={telefonoRef}  variant="outlined" size='small'className={classes.inputTelefono} value={state.usuario.telefono} onChange={handleChangeEvent} />         
          </Box>
          <Box className={classes.boxbotonEnviar}>
            <img alt="" src={enviar} className={classes.botonEnviar} onClick={editarUsuario} ></img>         
          </Box>
          </Box>
          {formularioEnviado3 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Usuario actualizado con exito</Typography> </div>}
          {errorFormulario2 &&<div className={classes.divError2}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
          { errorValidacion &&<div className={classes.divError2}><Typography className={classes.error}> La contraseña no coincide </Typography> </div> }
          { errorValidacion2 &&<div className={classes.divError2}><Typography className={classes.error}> El nombre no puede ser vacio </Typography> </div> }


            </Box>
            </Box> 
        
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes2}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'  rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img  alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
                   
        </Box> 
                  
        
        
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }