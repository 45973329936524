import React from 'react';
import InicioPage from './InicioPage';
import AccederPage from './AccederPage';
import RegistrarsePage from './RegistrarsePage';
import AccederAdminPage from './AccederAdminPage';
import MenuPage from './MenuPage';
import EditarPage from './Editar';
import MenuAdminPage from './MenuAdminPage';
import AdminAdministradores from './AdministrarAdmin';
import AdminReportes from './AdministrarReportes';
import AdministrarNoticias from './AdministrarNoticias';
import { HashRouter, Route} from 'react-router-dom';
import SubeExperiencia from './SubeExperiencia';
import AdministrarUsuarios from './administrarUsuarios';
import AdministrarCategorias from './AdministrarCategorias';
import Validaciones from './Validaciones';
import VerMas from './VerMas';



export default function Routes() {


    return (
        
        <HashRouter>
            <Route exact path={`/`}>
                <InicioPage />
            </Route>
            <Route exact path={`/acceder`}>
                <AccederPage />
            </Route>
            <Route exact path={`/acceder/admin`}>
                <AccederAdminPage />
            </Route>
            <Route exact path={`/menu`}>
                <MenuPage />
            </Route>
            <Route exact path={`/menu/admin`}>
                <MenuAdminPage />
            </Route>
            <Route exact path={`/registrarse`}>
                <RegistrarsePage />
            </Route>
            <Route exact path={`/menu/admin/administradores`}>
                <AdminAdministradores />
            </Route>
            <Route exact path={`/menu/admin/reportes`}>
                <AdminReportes />
            </Route>
            <Route exact path={`/menu/admin/noticias`}>
                <AdministrarNoticias />
            </Route>
            <Route exact path={`/menu/subeExperiencia`}>
                <SubeExperiencia />
            </Route>
            <Route exact path={`/menu/admin/usuarios`}>
                <AdministrarUsuarios />
            </Route>
            <Route exact path={`/menu/admin/categorias`}>
                <AdministrarCategorias />
            </Route>
            <Route exact path={`/menu/validaciones`}>
                <Validaciones />
            </Route>
            <Route exact path={`/experiencia/:hash`}>
                <VerMas />
            </Route>
            <Route exact path={`/menu/editar`}>
                <EditarPage />
            </Route>
        </HashRouter>

    );
  
}