import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import  NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import HomeImage from './images/home-37.png';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CargarImage from './images/cargar.png';
import EscribirImage from './images/escribir.png';
import OpcionesImage from './images/opciones.png';
import enviar from './images/enviar-R.png';
import previsualizar from './images/previsualizar.png';
import editarIcon from './images/editar_perfil-43-43.png';
import Modal from '@material-ui/core/Modal';
import logout from './images/logout.png';
import Checkbox from '@material-ui/core/Checkbox';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ReactHtmlParser from 'react-html-parser'; 
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CleanConsole } from '@eaboy/clean-console';












const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  fondoArriba: {
    width: '100%',
    height: '38vh',
    minHeight: '270px'

  }, 
  homeFoto: {  
    width: '65%',
    position: 'relative',
    
  },
  odsDerecha: {
    position: 'absolute',
    left: '5%',
    height: '15%',
    width: '50%',
    top: '61%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'

  },
  odsIzquierda: {
    width: '45%',
    left: '55%',
    top: '61%',
    position: 'absolute',

  },
  opcionesFoto: {  
    height: '2.8em',
    position: 'absolute',
    left: '0.8%',
    top:'1%'     
  },
  opcionesFoto2: {  
    width: '65%',
    position: 'relative', 
    marginTop: '0.3em'   
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  fondoModalInsertarMobile: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflowX: 'hidden',

  },
  escribirFoto: {   
    width: '65%',
    position: 'relative',
  },
  cargarFoto: {   
    width: '65%',
    position: 'relative',
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '4.5em',
    height: '38vh',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '270px'

    
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '5%'
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '10%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  acceder: {
    position: 'absolute',
    top: '3%',
    left: '77%',
    height: '8%'
  },
  fondo: {
      width: '80%',
      position: 'relative',
      left: '10%',
      marginTop: '2%',
      
  },
  fondoMobile: {
    width: '80%',
    position: 'relative',
    left: '10%',
    marginTop: '5%',
    marginBottom: '5%'
    
},
  textHeader: {
      fontSize: '1.5em',
      color: '#002A68',
      fontFamily: 'Montserrat',
      ['@media (max-width:1350px)']:{
        fontSize: '1.2em'
      },
  },
  textHeaderMobile: {
    fontSize: '1em',
    color: '#002A68',
    fontFamily: 'Montserrat'
},
  fondoFormulario: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '100%',
    width: '70%',    
    height: '170em',
    left: '15%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '2%',
    ['@media (max-width:1050px)']:{
      left: '10%',
      width: '80%'
    },
  },
  fondoFormularioMobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: '100%',
    width: '85%',    
    height: '150em',
    left: '7.5%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: '100%',
    width: '80%',    
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '5%',
    marginBottom: '2%'
  },
  fondoFormulario2Mobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: '100%',
    width: '80%',    
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '20%',
    marginBottom: '5%'
  },
  inputComparte: {
    backgroundColor: 'white',
    top: '5%',
    left: '10%',
    width: '82%',
    height: '3%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    "& input::placeholder": {
        color: "blue",
        fontSize: '180%',
        left: '30%',
        position: 'absolute',
      }
  },
  textCategoria: {
    color: 'white',
    fontSize: '170%',
    top: '8%',
    left: '5%',
    position: 'absolute',
    width: '30%',
    fontFamily: 'Montserrat'
  },
  textCategoriaMobile: {
    color: 'white',
    fontSize: '130%',
    top: '5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  inputCategoria: {
    backgroundColor: 'white',
    top: '8.3%',
    left: '38%',
    width: '20%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 5px'
  },
  inputCategoriaMobile: {
    backgroundColor: 'white',
    top: '7%',
    left: '15%',
    width: '70%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 5px'
  },
  inputAño: {
    backgroundColor: 'white',
    top: '16%',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
  },
  inputAñoMobile: {
    backgroundColor: 'white',
    top: '14%',
    left: '25%',
    width: '60%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputPais: {
    backgroundColor: 'white',
    top: '20%',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 5px'
  },
  imagen1: {
    border: '3px',
    borderRadius: '20px',
    display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'
  },
  inputPaisMobile: {
    backgroundColor: 'white',
    top: '17%',
    left: '25%',
    width: '60%',
    border: '3px',    
    borderRadius: '10px',
    position: 'absolute',
    padding: '0 5px'

  },
  inputCiudad: {
    backgroundColor: 'white',
    top: '23%',
    left: '20%',
    width: '20%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCiudadMobile: {
    backgroundColor: 'white',
    top: '20%',
    left: '35%',
    width: '50%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputDescripcion: {
    backgroundColor: 'white',
    top: '29%',
    left: '5%',
    width: '90%',
    height: '14.5em',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.5em'
    
  },
  inputDescripcionMobile: {
    backgroundColor: 'white',
    top: '26%',
    left: '5%',
    height: '12em',
    width: '89%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.2em'
  },
  inputPorque: {
    backgroundColor: 'white',
    top: '40.7%',
    left: '5%',
    width: '90%',
    height: '13em',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.5em'

  },
  inputPorqueMobile: {
    backgroundColor: 'white',
    top: '37%',
    left: '5%',
    width: '89%',
    border: '3px',   
    height: '12em', 
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.2em'
  },
  inputResultados: {
    backgroundColor: 'white',
    top: '51%',
    left: '5%',
    height: '13em',
    width: '90%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.5em'
  },
  inputResultadosMobile: {
    backgroundColor: 'white',
    top: '46.5%',
    left: '5%',
    width: '89%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute',
    overflowY: 'scroll',
    padding: '0 0.2em',
    height: '12em',

  },
  inputImagen1: {
    backgroundColor: 'white',
    top: '78%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen1Mobile: {
    backgroundColor: 'white',
    top: '73%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen2: {
    backgroundColor: 'white',
    top: '82%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen2Mobile: {
    backgroundColor: 'white',
    top: '77.5%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen3: {
    backgroundColor: 'white',
    top: '87%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  inputImagen3Mobile: {
    backgroundColor: 'white',
    top: '82%',
    left: '5%',
    width: '91%',
    border: '3px',    
    borderRadius: '15px',
    position: 'absolute'
  },
  textCorreo: {
    color: 'white',
    fontSize: '170%',
    top: '12%',
    left: '5%',
    position: 'absolute',
    width: '30%',
    fontFamily: 'Montserrat'
  },
  textCorreoMobile: {
    color: 'white',
    fontSize: '123%',
    top: '9%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textAño: {
    color: 'white',
    fontSize: '170%',
    top: '16%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textAñoMobile: {
    color: 'white',
    fontSize: '130%',
    top: '14%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPais: {
    color: 'white',
    fontSize: '170%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPaisMobile: {
    color: 'white',
    fontSize: '130%',
    top: '17%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudad: {
    color: 'white',
    fontSize: '170%',
    top: '23%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textCiudadMobile: {
    color: 'white',
    fontSize: '130%',
    top: '20%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textDescripcion: {
    color: 'white',
    fontSize: '170%',
    top: '27%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textDescripcionMobile: {
    color: 'white',
    fontSize: '130%',
    top: '23%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textPorque: {
    color: 'white',
    fontSize: '170%',
    top: '38%',
    left: '5%',
    width: '95%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },  
  textPorqueMobile: {
    color: 'white',
    fontSize: '130%',
    top: '34%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },  
  textResultados: {
    color: 'white',
    fontSize: '170%',
    top: '49%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textResultadosMobile: {
    color: 'white',
    fontSize: '130%',
    top: '45%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes: {
    color: 'white',
    fontSize: '170%',
    top: '76%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenesMobile: {
    color: 'white',
    fontSize: '130%',
    top: '70%',
    left: '5%',
    width: '94%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes2: {
    color: 'white',
    fontSize: '170%',
    top: '80%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes2Mobile: {
    color: 'white',
    fontSize: '130%',
    top: '75%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes3: {
    color: 'white',
    fontSize: '170%',
    top: '85%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textImagenes3Mobile: {
    color: 'white',
    fontSize: '130%',
    top: '80%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFormato: {
    color: 'white',
    fontSize: '1.5vw',
    top: '91%',
    left: '5%',
    width: '90%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  textFormatoMobile: {
    color: 'white',
    fontSize: '100%',
    top: '85%',
    width: '90%',
    left: '5%',    
    textAlign: 'center',
    position: 'absolute',
    fontFamily: 'Montserrat',
  },
  
  inputCorreo: {
    backgroundColor: 'white',
    top: '12%',
    left: '46%',
    width: '50%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  inputCorreoMobile: {
    backgroundColor: 'white',
    top: '11%',
    left: '15%',
    width: '70%',
    border: '3px',
    borderRadius: '15px',
    position: 'absolute'
  },
  boxTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '3%',
    left: '5%',
    width: '90%',
    height: '4%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxTitleMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '1%',
    left: '5%',
    width: '90%',
    height: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleComparte: {
    fontSize: '270%',
    position: 'relative',
    color: '#9eb5d9',
    fontFamily: 'Montserrat'
  },
  titleComparteMobile: {
    fontSize: '120%',    
    position: 'relative',
    color: '#9eb5d9',
    fontFamily: 'Montserrat'
  },
  ocultar: {
    display: 'none',
  },
  botonAcceder: {
    backgroundImage: 'url(./fondo/archivo.png)',
    backgroundSize:'cover',    
    width: '20%',
    height: '60px',
    top: '79%',
    left: '70%',
    position: 'absolute',
    cursor: 'pointer'
    
  },
  botonAcceder2: {
    backgroundImage: 'url(./fondo/pdf.png)',
    backgroundSize:'cover',    
    width: '20%',
    height: '70px',
    top: '90%',
    left: '40%',
    position: 'absolute',
    cursor: 'pointer'
    
  },
  botonAcceder3: {  
    height: '64px', 
    top: '95%',
    left: '60%',
    position: 'absolute',
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px'
    
  },
  botonAcceder3Mobile: {        
    width: '180px',
    top: '90%',
    left: '60%',
    position: 'absolute',
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px',
    ['@media (max-width:650px)']:{
      width: '130px'
    },
    ['@media (max-width:400px)']:{
      width: '90px'
    },
    
  },
  botonAcceder4: {  
    width: '250px',    
    top: '95%',
    left: '20%',
    position: 'absolute',
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px'
    
  },
  botonAcceder4Mobile: {       
    height: '64px',
    top: '90%',
    left: '8%',
    position: 'absolute',
    cursor: 'pointer',
    border: '1px',
    borderRadius: '10px',
    ['@media (max-width:650px)']:{
      height: '45px'
    },
    ['@media (max-width:400px)']:{
      height: '30px'
    },
    
  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    height: '3%',
    width: '30%',
    left: '80%',
    top: '95%',
    position: 'absolute',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  
  },
  divExtio2Mobile: {
    backgroundColor: '#D3E9D0',
    height: '3%',
    width: '90%',
    left: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '93%',
    position: 'absolute',
    border: '3px',
    borderRadius: '5px'
  
  },
  
  
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divError4: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '40%',
    left: '50%',
    top: '65%',
    position: 'fixed',  
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divError4Mobile: {
    backgroundColor: '#ff9090',
    height: '3%',
    width: '70%',
    left: '15%',
    top: '93%',
    position: 'absolute',  
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  titleModalInsertar: {
    fontSize: '250%',
    fontWeight: 'bold',
    left: '10%',
    width: '75%',
    position: 'relative',
    color: '#002A68',
    marginTop: '3%',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  titleModalInsertarMobile: {
    fontSize: '150%',
    position: 'relative',
    fontWeight: 'bold',
    color: '#002A68',
    fontFamily: 'Montserrat'
  },
  iconBtn: {
    left: '90%',
    top: '2%',
    position: 'absolute',
  },
  iconBtnMobile: {
    left: '80%',
    top: '1%',
    position: 'relative',
  },
  textPais2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    top: '4%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '120%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    
  },
  textPais3: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10'
  },
  textPais3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10'
  },
  textAño2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    top: '8%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '120%',
    top: '5%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '170%',
    top: '8%',
    left: '20%',
  },
  textAño3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10'

  },
  textDescripcion2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textDescripcion2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '120%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textResultados2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    left: '45%',
    width: '45%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textResultados2Mobile: {
    color: 'white',
    position: 'relative',
    fontSize: '120%',
    top: '52%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '120%',
    width: '97%',
    fontFamily: 'Montserrat'
  },
  textResultados3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '80%',
    fontFamily: 'Montserrat'
  },
  textDescripcion3: {
    color: '#e3e4e5',
    fontSize: '110%',
    fontFamily: 'Montserrat'  
  },
  textDescripcion3Mobile: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '80%',
    fontFamily: 'Montserrat'
  },
  textOds: {
    position: 'absolute',
    color: 'white',
    top: '59%',
    left: '5%',
    fontWeight:'bold',
    fontFamily: 'Montserrat'
  },
  boxTextDescripcion3: {
    position: 'relative',
    width: '90%',
    left: '5%'   ,
    overflow: 'hidden',
  },
  boxTextDescripcion3Mobile: {
    position: 'relative',
    width: '80%',
    left: '10%'   ,
  },
  boxTextResultados3: {
    position: 'relative',
    width: '95%',
    left: '5%'    ,
    overflow: 'hidden'
  },
  boxTextResultados3Mobile: {
    position: 'relative',
    width: '80%',
    height: '31.7%',
    top: '55%',
    left: '10%'    ,
    overflow:'hidden'
  },
  homeFotoMobile: {  
    height: '99%',
    position: 'absolute',
    left: '25%',
    
    
  },
  opcionesFotoMobile: {  
    height: '40px',
    position: 'absolute',
    left: '4.6%',
    top:'3em'     
  },
  escribirFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '46%',
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '100%',
    height: '40px',
    top: '3em'
  },
  fondoArribaMobile: {
    width: '100%',
    height: '8em',
  }, 
  cargarFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '66%',
    
  },
  logoutFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '85%',
    
  },
  opcionesFoto2Mobile: {  
    height: '100%',
    position: 'absolute',
    left: '5%',
  },
  registrarteMobile: {
    fontSize: '2em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '1%',
    left: '5%',
    fontFamily: 'Montserrat'
  },
  boxFotoDescripcion1Mobile: {
    position: 'relative',
    backgroundColor: 'white',
    width: '85%',
    height: '10em',
    left: '8%'   ,
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxFotoDescripcion2Mobile: {
    position: 'relative',
    backgroundColor: 'white',
    width: '85%',
    height: '10em',
    left: '8%'   ,
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoutFoto: {   
    width: '100%',
    position: 'relative',
    marginBottom: '0.3em'

  },
  boxFotoDescripcion1: {
    backgroundColor: 'white',
    width: '400px',
    height: '250px',
    top: '16%',
    left: '60%'   ,
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
    marginLeft: '5%',
    ['@media (max-width:1300px)']:{
      width: '220px',
      height: '138px',
    },

  },
  boxFotoDescripcion2: {
    position: 'relative',
    backgroundColor: 'white',
    width: '400px',
    height: '250px',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
    marginRight: '5%',
    ['@media (max-width:1300px)']:{
      width: '220px',
      height: '138px',
    },
  },
  boxtitleModalInsertarMobile: {
    position: 'relative',
    width: '94%',
    left: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10%'
  },
  textodsD: {
    color: 'white'
  },
  boxOdsMobile: {
    width: '89%',
    position: 'absolute',
    left: '5%',
    height: '17em',
    top: '58%',
    backgroundColor: 'white',
    border: '1px',
    borderRadius: '10px',
    overflowX: 'auto',
    padding: '1%'
  },
  textOdsMobile: {
    color: 'white',
    position: 'absolute',
    top: '56%',
    left: '5%',
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '20%',
    border: '1px',
    borderRadius: '10px'
  
  },
  boxFooterMobile: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  fondoTodo: {
    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)',
    position: 'absolute'
  },
  checkOds: {
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  textOdsM: {
    fontSize: '90%'
  },
  textCaracteres: {
    fontWeight: 'lighter',
    fontSize: '50%',
    color: '#EEEDED',
    fontFamily: 'Montserrat'
  },
  textCaracteresM: {
    fontWeight: 'lighter',
    fontSize: '50%',
    color: '#EEEDED',
    fontFamily: 'Montserrat'
  },
  boxOdses: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    left: '5%',
    alignItems: 'center',  
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  fotoOds: {
    width: '6.5em',
    height: 'auto',
    margin: '0.5em'
  },
  fotoOdsM: {
    width: '3.5em',
    height: 'auto',
    margin: '0.2em',
    align: 'center'
  },
  boxOdsMobile2: {
    width: '80%',
    left: '10%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5%',
    flexWrap: 'wrap'
  },
  



}));


export default function MenuPage(props) {


  CleanConsole.init()




  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen1(false);
  };

  const [mostrarMenu, MostrarMenu] = useState(false);

  
  function mostrarOtro(){
    MostrarMenu(true)
    
  }
  function ocultarOtro(){
    MostrarMenu(false)
  }
 

  const [open1, setOpen1] = React.useState(false);
  const imagen1S = React.useRef();
  const imagen2S = React.useRef();
  const pdfS = React.useRef();

  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [validacionMensaje, cambiarValidacionMensaje] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorMessage, setError] = useState([]); 

  
  

  

  const [state, setState] = React.useState({
    mobileView: false,
    categorias: [],
    paises: [],
    odses: [],
    MostrarMenu: false,
    data: "",    
  });

  const MAX_LENGTH = 1000;
  const [stateText, setStateText] = React.useState({
    editorState: EditorState.createEmpty(),   
  });

  
  const {editorState} = stateText;
  const DescripcionText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        console.log(DescripcionText)


       const  _handleBeforeInput = () => {
          const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length
    
          if (currentContentLength > MAX_LENGTH - 1) {
            alert('El contenido tiene mas de 1000 caracteres');
    
            return 'handled';
        }
      }
      
      const _handlePastedText = (pastedText) => {
          const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length
    
          if (currentContentLength + pastedText.length > MAX_LENGTH) {
            alert('El contenido tiene mas de 1000 caracteres');
    
            return 'handled';
        }
      }
  
  const onEditorStateChange = (editorState) => {
    setStateText({
        editorState,
    });}

    const [stateText2, setStateText2] = React.useState({
      editorState2: EditorState.createEmpty(),   
    });
  
    
    const {editorState2} = stateText2;
    const DescripcionText2 = draftToHtml(convertToRaw(editorState2.getCurrentContent()))
          console.log(DescripcionText2)
  
  
         const  _handleBeforeInput2 = () => {
            const currentContent = editorState2.getCurrentContent();
          const currentContentLength = currentContent.getPlainText('').length
      
            if (currentContentLength > MAX_LENGTH - 1) {
              alert('El contenido tiene mas de 1000 caracteres');
      
              return 'handled';
          }
        }
        
        const _handlePastedText2 = (pastedText) => {
            const currentContent = editorState2.getCurrentContent();
          const currentContentLength = currentContent.getPlainText('').length
      
            if (currentContentLength + pastedText.length > MAX_LENGTH) {
              alert('El contenido tiene mas de 1000 caracteres');
      
              return 'handled';
          }
        }
    
    const onEditorStateChange2 = (editorState2) => {
      setStateText2({
          editorState2,
      });}

      const [stateText3, setStateText3] = React.useState({
        editorState3: EditorState.createEmpty(),   
      });
    
      
      const {editorState3} = stateText3;
      const DescripcionText3 = draftToHtml(convertToRaw(editorState3.getCurrentContent()))
            console.log(DescripcionText3)
    
    
           const  _handleBeforeInput3 = () => {
              const currentContent = editorState3.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length
        
              if (currentContentLength > MAX_LENGTH - 1) {
                alert('El contenido tiene mas de 1000 caracteres');
        
                return 'handled';
            }
          }
          
          const _handlePastedText3 = (pastedText) => {
              const currentContent = editorState3.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length
        
              if (currentContentLength + pastedText.length > MAX_LENGTH) {
                alert('El contenido tiene mas de 1000 caracteres');
        
                return 'handled';
            }
          }
      
      const onEditorStateChange3 = (editorState3) => {
        setStateText3({
            editorState3,
        });
  };




  
       


  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/logout', data, {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [picture2, setPicture2] = useState(null);
  const [imgData2, setImgData2] = useState(null);
  const onChangePicture = e => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePicture2 = e => {
    if (e.target.files[0]) {
      setPicture2(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData2(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  

  function getCategorias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/list", {
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, categorias: response.data.data })
      );
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  

  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, paises: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function validarPeso(){
    const oFile = document.getElementById("imagen1").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen1").value = "";
      cambiarValidacionMensaje(true)
      setTimeout(() => cambiarValidacionMensaje(false), 5000);    
    return;
  }
  }
  function validarPesoM(){
    const oFile = document.getElementById("imagen1M").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen1M").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso2(){
    const oFile = document.getElementById("imagen2").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen2").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso2M(){
    const oFile = document.getElementById("imagen2M").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("imagen2M").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso3(){
    const oFile = document.getElementById("pdf").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("pdf").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  function validarPeso3M(){
    const oFile = document.getElementById("pdfM").files[0];
    if (oFile.size > 3145728) {
    document.getElementById("pdfM").value = "";
    cambiarValidacionMensaje(true)
    setTimeout(() => cambiarValidacionMensaje(false), 5000); 
    return;
  }
  }
  
  function getOdses(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/ods/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, odses: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function enviarNoticia(){   
    var bodyFormData = new FormData();
      bodyFormData.append('categoria_id', categoriaRef.current.value);
      bodyFormData.append('nombre', nombreExperienciaRef.current.value);
      bodyFormData.append('ano', añoRef.current.value);
      bodyFormData.append('pais_id', paisRef.current.value);
      bodyFormData.append('ciudad', ciudadRef.current.value);
      bodyFormData.append('descripcion', DescripcionText);
      bodyFormData.append('razon_innovadora', DescripcionText2);
      bodyFormData.append('resultados', DescripcionText3);
      bodyFormData.append('imagen1', imagen1S.current.files[0]); 
      bodyFormData.append('imagen2', imagen2S.current.files[0]); 
      bodyFormData.append('pdf', pdfS.current.files[0]);      
      
      var markedCheckbox = document.getElementsByName('ods');
    for (var checkbox of markedCheckbox) {
      if (checkbox.checked)
        bodyFormData.append("ods",checkbox.value);
    }
    
    axios({
      method: "post",
      url: localStorage.getItem('URL') + "/main/api/noticia",
      data: bodyFormData,
      headers: { 'Usuario-Id': localStorage.getItem("usuario_id"),
      'Session-Token': localStorage.getItem("session_token"),   },
    })
    .then((response) => {
     cambiarFormularioEnviado(true);
     setTimeout(() => cambiarFormularioEnviado(false), 5000);    
     setTimeout(() => redireccionar(), 2000);    
       
      
          
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message);
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000); 
      
      
      });
  }

  const [previsualizarEnviar, setPrevisualizar] = useState([]); 


  function previsualizarNoticia(){   

   const datosPrevisualizar = {
      nombre: nombreExperienciaRef.current.value,
      ano: añoRef.current.value,
      paisId : paisRef.current.children[paisRef.current.selectedIndex].innerHTML,
      ciudad:  ciudadRef.current.value,
      descripcion: DescripcionText,
      resultados: DescripcionText3,
    }
    var OdsList = []
    var markedCheckbox = document.getElementsByName('ods');
    for (var checkbox of markedCheckbox) {
      if (checkbox.checked){
        var odsFound = state.odses.find( ods => ods.id == checkbox.value );
        OdsList.push(odsFound.imagen)
      }
        
    }
    datosPrevisualizar["odses"] = OdsList
    setPrevisualizar(datosPrevisualizar)    
   };

  function redireccionar(){
    window.location.href="/#/menu/validaciones"
  }

  
 
 
  const categoriaRef = React.useRef();
  const añoRef = React.useRef();
  const nombreExperienciaRef = React.useRef();
  const paisRef = React.useRef();
  const ciudadRef = React.useRef(); 
  const descripcionRef = React.useRef(); 
  const porqueRef = React.useRef(); 
  const resultadosRef = React.useRef(); 
  const { mobileView } = state;
  const classes = useStyles();
  React.useEffect(() => {
    validateSession();
    getCategorias();
    getPaises();
    getOdses();
    
  }, []);

  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
  }
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box>
        <Box>
        <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFotoMobile}></img></a>
              { mostrarMenu && <Box className={classes.boxHomeMobile}>
              <a  onClick={ocultarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto2Mobile}></img></a>
              <a  href="#/menu" ><img alt="" src={HomeImage} className={classes.homeFotoMobile}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFotoMobile}></img></a>
              <a href="#/menu/validaciones"><img  alt="" src={CargarImage} className={classes.cargarFotoMobile}></img></a>
              <a href="#/"><img alt="" src={logout} className={classes.logoutFotoMobile} onClick={cerrarSesion}></img></a>
            </Box>  }
            <img alt="" src={FondoArriba} className={classes.fondoArribaMobile}></img>            
            <Typography className={classes.registrarteMobile}>
            {localStorage.getItem("nombre")}
            </Typography>             
            <Box>
            <Box className={classes.fondoTodo}>
            <Box className={classes.fondoMobile}>
                <Typography className={classes.textHeaderMobile} >Un comité de Infosegura revisará la postulación de su experiencia, y de acuerdo con los criterios de publicación, se realizará la difusión en la plataforma web y en los diferentes medios digitales del proyecto.<br></br><br></br>

Para postular su experiencia comparta la siguiente información:</Typography>
            </Box>
            <Box className={classes.fondoFormularioMobile} >
                <Box className={classes.boxTitleMobile}>
                  <Typography className={classes.titleComparteMobile} >Comparte tu experiencia</Typography>  
                </Box>
                <Box>
                    <Typography  className={classes.textCategoriaMobile}>Elige una categoría</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCategoriaMobile} inputRef={categoriaRef} size="small" >
                    <option value='d'> Categorias... </option>    
                    {state.categorias.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect>     
                </Box> 
                <Box>
                    <Typography className={classes.textCorreoMobile}>Nombre de la experiencia</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCorreoMobile} placeholder='Máximo 255 caracteres' variant="outlined" size="small" inputRef={nombreExperienciaRef} />         
                </Box> 
                <Box>
                    <Typography className={classes.textAñoMobile}>Año</Typography>
                    <TextField type="number" className={classes.inputAñoMobile} inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" size='small' inputRef={añoRef} />         
                </Box>
                <Box>
                    <Typography className={classes.textPaisMobile}>País</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputPaisMobile} size="small" variant="outlined" inputRef={paisRef} > 
                    <option value="">Selecciona...</option>
                      {state.paises.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}   
                    </NativeSelect>        
                </Box>
                <Box>
                    <Typography className={classes.textCiudadMobile}>Ciudad</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputCiudadMobile} size='small' variant="outlined" inputRef={ciudadRef} />         
                </Box>
                <Box>
                    <Typography className={classes.textDescripcionMobile}>Descripción de la innovación: <a className={classes.textCaracteresM}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputDescripcionMobile}>
                    <Editor
                      editorState={editorState}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange} 
                      handleBeforeInput={_handleBeforeInput}
                      handlePastedText={_handlePastedText}   
                     />
                    </Box>         
                </Box>
                <Box>
                    <Typography className={classes.textPorqueMobile}>¿Por qué es una solución innovadora?: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputPorqueMobile}>
                    <Editor
                      editorState={editorState2}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2} 
                      handleBeforeInput={_handleBeforeInput2}
                      handlePastedText={_handlePastedText2}   
                     />
                    </Box>          
                </Box>
                <Box>
                    <Typography className={classes.textResultadosMobile}>Resultados: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputResultadosMobile}>
                    <Editor
                      editorState={editorState3}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                          textAlign: { inDropdown: true },
                          list: {inDropdown: true},
                          inline: {inDropdown: true}
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange3} 
                      handleBeforeInput={_handleBeforeInput3}
                      handlePastedText={_handlePastedText3}   
                     />
                    </Box>                 </Box>
                <Typography variant='h6' className={classes.textOdsMobile}>ODS</Typography>
                <Box className={classes.boxOdsMobile}>
                  {state.odses.map(elemento=>(
                      <Box >
                        <Checkbox name="ods" value={elemento.id} key={elemento.imagen} color="primary"></Checkbox><label className={classes.textOdsM}>{elemento.codigo}-{elemento.nombre}</label></Box>
                        )
                      )}
                </Box>
                <Box>
                    <Typography className={classes.textImagenesMobile}>Imagen 1<a style={{fontFamily: "Montserrat", fontSize: '9px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>
                    <TextField inputProps={{accept:"image/*",style: {fontFamily: 'Montserrat'}}} type="file" inputRef={imagen1S} id="imagen1M"  className={classes.inputImagen1Mobile} onChange={(e) => { onChangePicture(e); validarPesoM();  }} size='small' variant="outlined"  />                             
                    <Typography className={classes.textImagenes2Mobile}>Imagen 2<a style={{fontFamily: "Montserrat", fontSize: '9px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>
                    <TextField type="file" inputProps={{accept:"image/*",style: {fontFamily: 'Montserrat'}}} inputRef={imagen2S} className={classes.inputImagen2Mobile} id="imagen2M"  size='small' variant="outlined" onChange={(e) => { onChangePicture2(e); validarPeso2M(); }}  placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField>                    
                    <Typography className={classes.textImagenes3Mobile}>Subir PDF <a className={classes.textCaracteresM}>(Máximo 3MB)</a></Typography>
                    <TextField type="file"  inputProps={{accept:"application/pdf",style: {fontFamily: 'Montserrat'}}} inputRef={pdfS}  className={classes.inputImagen3Mobile} size='small' variant="outlined" id="pdfM" onChange={(e) => { validarPeso3M(); }} placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField> 
                    
                     
                    <Typography className={classes.textFormatoMobile}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
máximo de 3MB.</Typography>      
                </Box>                   
                  <img  alt="" src={enviar} className={classes.botonAcceder3Mobile} onClick={enviarNoticia} >                    
                  </img> 
                  <img  alt="" src={previsualizar} className={classes.botonAcceder4Mobile}  onClick={() => {
          previsualizarNoticia();
          handleOpen1();
       }}>                                        
                  </img> 
                  <Modal
          open={open1}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertarMobile}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtnMobile}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box className={classes.boxtitleModalInsertarMobile}>
            <Typography className={classes.titleModalInsertarMobile} variant="h6" component="h2">
            {previsualizarEnviar.nombre}
            </Typography>
            </Box>
            <Box className={classes.fondoFormulario2Mobile}> <br></br> <br></br>
            <Typography className={classes.textPais2Mobile}>País: <a className={classes.textPais3Mobile}>{previsualizarEnviar.paisId}</a></Typography><br></br>
            <Typography className={classes.textAño2Mobile}>Año: <a className={classes.textAño3Mobile}>{previsualizarEnviar.ano}</a></Typography><br></br>
            <Typography className={classes.textDescripcion2Mobile}>Descripcion</Typography><br></br>
            <Box className={classes.boxTextDescripcion3Mobile}>
            <Typography className={classes.textDescripcion3Mobile}>{ ReactHtmlParser(previsualizarEnviar.descripcion)}</Typography><br></br>            
            </Box>
            <Box className={classes.boxFotoDescripcion1Mobile}>
            <img src={imgData} alt="" className={classes.imagen1} ></img>
            </Box><br></br>
            <Typography className={classes.textResultados2Mobile}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3Mobile}>
            <Typography className={classes.textResultados3Mobile}>{ ReactHtmlParser(previsualizarEnviar.resultados)}</Typography>            
            </Box><br></br>
            <Box className={classes.boxFotoDescripcion2Mobile}>
            <img alt="" src={imgData2}  className={classes.imagen1} ></img>
            </Box>
            <br></br>
            <Box>
            {previsualizarEnviar.odses != undefined ?
                          <Box className={classes.boxOdsMobile2}>                            
                            {previsualizarEnviar.odses.map(ods=>(                        
                          <img  alt="" className={classes.fotoOdsM} src={ods}></img> 
                        ))}

                          </Box>:<Box></Box>
                        }
            </Box>
            </Box>
              
          </Box>
        </Modal>
                  { validacionMensaje && <div className={classes.divError4Mobile}><Typography className={classes.error}> El archivo pesa más de 3MB </Typography> </div> }
                  { formularioEnviado && <div className={classes.divExtio2Mobile}> <Typography className={classes.exito}>Experiencia creada correctamente</Typography> </div>}
                  { errorFormulario &&<div className={classes.divError4Mobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            </Box>
            <footer>
              <Box  className={classes.boxFooterMobile}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
            </Box>            
            </Box>            
          </Box>
          
      </Box>
      );
    }
  
  const displayDesktop = () => {
    
    return (      
        <Box>
          <Box>
            <a  onClick={mostrarOtro}><img  alt="" src={OpcionesImage} className={classes.opcionesFoto}></img></a>
              { mostrarMenu && <Box className={classes.boxHome}>
              <a  onClick={ocultarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto2}></img></a>
              <a href="#/menu" ><img  alt="" src={HomeImage} className={classes.homeFoto}></img></a>              
              <a href="#/menu/editar"><img alt="" src={editarIcon} className={classes.escribirFoto}></img></a>
              <a href="#/menu/validaciones"><img alt=""  src={CargarImage} className={classes.cargarFoto}></img></a>
              <a href="#/"><img alt=""  src={logout} className={classes.logoutFoto} onClick={cerrarSesion}></img></a>
            </Box>  }
            <img  alt="" src={FondoArriba} className={classes.fondoArriba}></img>            
            <Typography className={classes.registrarte}>
            {localStorage.getItem("nombre")}
            </Typography>            
            <Box className={classes.fondoTodo} >
            <Box className={classes.fondo}>
                <Typography className={classes.textHeader} >Un comité de Infosegura revisará la postulación de su experiencia, y de acuerdo con los criterios de publicación, se realizará la difusión en la plataforma web y en los diferentes medios digitales del proyecto.<br></br><br></br>

Para postular su experiencia comparta la siguiente información:</Typography>
            </Box>
            <Box>
            <Box className={classes.fondoFormulario} >
                <Box className={classes.boxTitle}>
                  <Typography className={classes.titleComparte} >Comparte tu experiencia</Typography>  
                </Box>
                <Box>
                    <Typography  className={classes.textCategoria}>Elige una categoría</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCategoria} inputRef={categoriaRef} >
                    <option value='d'> Categorias... </option>    
                    {state.categorias.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect>     
                </Box> 
                <Box>
                    <Typography className={classes.textCorreo}>Nombre de la experiencia</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputCorreo} placeholder='El nombre de la experiencia no puede contener mas 255 caracteres' variant="outlined" inputRef={nombreExperienciaRef} />         
                </Box> 
                <Box>
                    <Typography className={classes.textAño}>Año</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" className={classes.inputAño} variant="outlined" inputRef={añoRef} />         
                </Box>
                <Box>
                    <Typography className={classes.textPais}>País</Typography>
                    <NativeSelect  inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputPais} variant="outlined" inputRef={paisRef} > 
                    <option value="">Selecciona...</option>
                      {state.paises.map(elemento=>(
                        <option key={elemento.id}  value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}   
                    </NativeSelect>        
                </Box>
                <Box>
                    <Typography className={classes.textCiudad}>Ciudad</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputCiudad} variant="outlined" inputRef={ciudadRef} />         
                </Box>
                <Box>
                    <Typography className={classes.textDescripcion}>Descripción de la innovación: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputDescripcion}>
                    <Editor
                      editorState={editorState}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange} 
                      handleBeforeInput={_handleBeforeInput}
                      handlePastedText={_handlePastedText}   
                     />
                    </Box>
                </Box>
                <Box>
                    <Typography className={classes.textPorque}>¿Por qué es una solución innovadora?: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputPorque}>
                    <Editor
                      editorState={editorState2}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2} 
                      handleBeforeInput={_handleBeforeInput2}
                      handlePastedText={_handlePastedText2}   
                     /> 
                     </Box>        
                </Box>
                <Box>
                    <Typography className={classes.textResultados}>Resultados: <a className={classes.textCaracteres}>(Máximo 1.000 caracteres)</a></Typography>
                    <Box className={classes.inputResultados}>
                    <Editor
                      editorState={editorState3}        
                      toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange3} 
                      handleBeforeInput={_handleBeforeInput3}
                      handlePastedText={_handlePastedText3}   
                     /> 
                     </Box>           
                </Box>
                <Box >                  
                    <Typography className={classes.textOds} variant='h4'>ODS</Typography> 
                    <Box className={classes.odsDerecha} >
                    {state.odses.slice(0,9).map(elemento=>(
                      <Box  key={elemento.id} className={classes.checkOds} >
                        <Checkbox id={elemento.codigo} name="ods" value={elemento.id} key={elemento.imagen} color="primary"></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box>
                        )
                      )}
                    </Box>
                    <Box className={classes.odsIzquierda} >
                    {state.odses.slice(9,20).map(elemento=>(
                      <Box  key={elemento.id} className={classes.checkOds} >
                        <Checkbox id={elemento.codigo}  name="ods" value={elemento.id} key={elemento.imagen} color="primary"></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box>
                        )
                      )}
                    </Box>                                          
                  </Box>
                <Box>
                    <Typography className={classes.textImagenes}>Imagen 1<a style={{fontFamily: "Montserrat", fontSize: '12px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>
                    <TextField inputProps={{accept:"image/*",style: {fontFamily: 'Montserrat'}}} id="imagen1" type="file" inputRef={imagen1S}  className={classes.inputImagen1} onChange={(e) => { onChangePicture(e); validarPeso(); }} variant="outlined"  />                     
                    
                    <Typography className={classes.textImagenes2}>Imagen 2<a style={{fontFamily: "Montserrat", fontSize: '12px', marginLeft:'5px'}}>(Tamaño sugerido es 400x250 px, o un tamaño que mantenga está misma relación.)</a></Typography>
                    <TextField type="file"  inputProps={{accept:"image/*",style: {fontFamily: 'Montserrat'}}} inputRef={imagen2S} id="imagen2" className={classes.inputImagen2} variant="outlined"  onChange={(e) => { onChangePicture2(e); validarPeso2(); }}  placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField>
                    <Typography className={classes.textImagenes3}>Subir PDF <a className={classes.textCaracteres}>(Máximo 3MB)</a></Typography>
                    <TextField type="file" inputProps={{accept:"application/pdf",style: {fontFamily: 'Montserrat'}}} inputRef={pdfS} className={classes.inputImagen3} variant="outlined" id="pdf" onChange={(e) => { validarPeso3(); }}  placeholder='Agregar imágenes/tablas relevantes de la experiencia' >                    
                    </TextField>
                     
                    <Typography className={classes.textFormato}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
máximo de 3MB.</Typography>      
                </Box>                   
                  <img  alt="" src={enviar} className={classes.botonAcceder3}   onClick={() => {
          enviarNoticia();          
       }} >                    
                  </img> 
                  <img alt=""  src={previsualizar}   className={classes.botonAcceder4}  onClick={() => {
          previsualizarNoticia();
          handleOpen1();
       }}>                    
                  </img> 
                  <Modal
          open={open1}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertar}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>

            <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
            {previsualizarEnviar.nombre}
            </Typography>
            <Box className={classes.fondoFormulario2}><br></br><br></br>
            <Typography className={classes.textPais2}>País: <a className={classes.textPais3}>{previsualizarEnviar.paisId}</a></Typography><br></br>
            <Typography className={classes.textAño2}>Año: <a className={classes.textPais3}> {previsualizarEnviar.ano} </a></Typography><br></br>
            <Typography className={classes.textDescripcion2}>Descripcion</Typography><br></br>
            <Box className={classes.boxTextDescripcion3}>
            <Box className={classes.boxFotoDescripcion1}> 
              <img alt=""  src={imgData}  className={classes.imagen1} ></img>
            </Box><br></br>
            <Typography className={classes.textDescripcion3}>{ ReactHtmlParser(previsualizarEnviar.descripcion)}</Typography>            
            </Box> <br></br>           
            <Typography className={classes.textResultados2}>Resultados</Typography><br></br>
            <Box className={classes.boxTextResultados3}>
            <Box className={classes.boxFotoDescripcion2}>
            <img alt="" src={imgData2}  className={classes.imagen1} ></img>
            </Box><br></br>
            <Typography className={classes.textResultados3}>{ ReactHtmlParser(previsualizarEnviar.resultados)}</Typography>            
            </Box><br></br>
            <Box>
              {previsualizarEnviar.odses != undefined ?
                          <Box className={classes.boxOdses}>                            
                            {previsualizarEnviar.odses.map(ods=>(                        
                          <img alt="" className={classes.fotoOds} src={ods}></img> 
                        ))}

                          </Box>:<Box></Box>
                        }
            </Box><br></br><br></br>
            
            </Box>
              
          </Box>
        </Modal>
                  { validacionMensaje && <div className={classes.divError4}><Typography className={classes.error}> El archivo pesa más de 3MB </Typography> </div> }
                  { formularioEnviado && <div className={classes.divExtio2}> <Typography className={classes.exito}>Experiencia creada correctamente</Typography> </div>}
                  { errorFormulario &&<div className={classes.divError4}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
            </Box>
          </Box>
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'  rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
            </Box>
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }