import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import HomeImage from './images/home-37.png';
import editarIcon from './images/editar_perfil-43-43.png';
import CargarImage from './images/cargar.png';
import EscribirImage from './images/escribir.png';
import OpcionesImage from './images/opciones.png';
import axios from 'axios';
import subeExperiencia from './images/sube-experiencia.png';
import verMas from './images/ver-mas.png';
import logout from './images/logout.png';
import fondoNoticia from './images/fondoNoticia.png';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import StarIcon from '@material-ui/icons/Star';
import ReactHtmlParser from 'react-html-parser'; 
import { CleanConsole } from '@eaboy/clean-console';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  fondoArriba: {
    width: '100%',
    height: '38vh',
    minHeight: '270px'
  },  
  fondoArribaMobile: {
    width: '100%',
    height: '8em',
  },  
  homeFoto: {  
    width: '65%',
    position: 'relative',
    
  },
  star: {
    color: '#EABE3F',
    position: 'absolute',
    left: '80%',
    marginTop: '-2%'

  },
  homeFotoMobile: {  
    height: '99%',
    position: 'absolute',
    left: '25%',   
  }, 
  opcionesFoto: {  
    height: '2.8em',
    position: 'absolute',
    left: '0.8%',
    top:'1%',
  
  },
  opcionesFotoMobile: {  
    height: '40px',
    position: 'absolute',
    left: '4.6%',
    top:'3em'     
  },
  opcionesFoto2: {  
    width: '65%',
    position: 'relative',
    marginTop: '0.5em'
 
  },
  opcionesFoto2Mobile: {  
    height: '100%',
    position: 'absolute',
    left: '5%',
  },
  escribirFoto: {   
    width: '65%',
    position: 'relative',

  },
  escribirFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '46%',
  },
  cargarFoto: {   
    width: '65%',
    position: 'relative',
  },
  logoutFoto: {   
    width: '100%',
    position: 'relative',
    marginBottom: '0.3em'
  },
  cargarFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '66%',
    
  },
  logoutFotoMobile: {   
    height: '99%',
    position: 'absolute',
    left: '85%',
    
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '4.5em',
    height: '38vh',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '270px'
    
  },
  boxHomeMobile: {
    backgroundColor: 'white',
    position:'absolute',
    width: '100%',
    height: '40px',
    top: '3em'
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '30%'
  },
  registrarte: {
    fontSize: '4em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '10%',
    left: '15%',
    fontFamily: 'Montserrat'
  },
  registrarteMobile: {
    fontSize: '2em',
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '1%',
    left: '5%',
    width: '90%',
    height: '1.3em',
    fontFamily: 'Montserrat',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  acceder: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    width: '300px'
  },
  editar: {
    position: 'absolute',
    top: '3%',
    right: '28%',
    width: '50px',
    backgroundColor: 'white',
    borderRadius: '40%'
  },

  experienciasMobile: { 
    fontSize: '1.8em',
    fontWeight: '1000',
    color: '#213361',
    marginTop: '3.5em',
    marginBottom: '5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  boxNoticia: {
    width: '370px',
    height: '800px',
    marginTop: '10%',
    left: '5%',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#86b0cc',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '3px 3px 10px  #86b0cc',   
    backgroundColor: 'white',
  },
  boxNoticiaMobile: {
    width: '65%',
    maxWidth: '380px',
    marginTop: '10%',
    margin: 'auto',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#86b0cc',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '3px 3px 10px  #86b0cc', 
    backgroundColor: 'white'  
  },
  textTitulo:  {
    fontSize: '110%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textTituloMobile:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textCategoria:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textCategoriaMobile:  {
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
  },
  textPais:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textPaisMobile:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textAñoN:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textAñoNMobile:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textPorque:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    width: '95%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textPorqueMobile:  {
    fontSize: '90%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    width: '95%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textCategoria2:  {
    color: '#213361',
    fontFamily: 'Montserrat'

  },
  boxtextcategoria2:  {
    fontSize: '100%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '95%',
    display: 'flex',
    justifyContent: 'left',
    maxHeight: '9%',
    overflow: 'hidden',
  },
  textCategoria2Mobile:  {
    fontSize: '90%',
    color: '#213361', 
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  boxtextCategoria2Mobile:  {
    width: '92%',
    left: '5%',
    maxHeight: '9%',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  textPais2N:  {
    fontSize: '100%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '95%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textPais2NMobile:  {
    fontSize: '90%',
    color: '#213361',
    left: '5%',
    width: '94%',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textAño2N:  {
    fontSize: '100%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '95%',
    fontFamily: 'Montserrat'
  },
  textAño2NMobile:  {
    fontSize: '90%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textPorque2:  {
    fontSize: '100%',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textPorque2Mobile:  {
    fontSize: '80%',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textNombre: {
    color: '#213361',
    fontSize: '110%',
    width: '94%',
    overflow: 'hidden',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textNombreMobile: {
    color: '#213361',
    fontSize: '100%',
    left: '5%',
    width: '95%',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  verMasFoto: {
    width: '10em',
    cursor: 'pointer'
  },
  boxverMasFoto: {
    position: 'absolute',
    top: '91%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  verMasFotoMobile: {
    position: 'relative',
    cursor: 'pointer',
    width: '8em'
  },
  boxverMasFotoMobile: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3%',
    marginBottom:' 3%'
  },
  descargarFoto: {
    position: 'relative',
    top: '90%',
    width: '25%',
    left: '40%',
    cursor: 'pointer'
  },
  descargarFotoMobile: {
    position: 'relative',
    top: '90%',
    width: '70%',
    left: '17%',
    cursor: 'pointer'
  },
  imagenNoticia: {
    position: 'relative',
    width: '400px',
    height: '250px',
    top: '-1%',
    border: '0.5px',
    borderStyle: 'solid',
    borderRadius: '1em',
    borderColor: '#86b0cc',
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imagenNoticiaMobile: {
    position: 'relative',
    maxWidth: '400px',
    width: '110%',
    maxHeight: '250px',
    top: '-1%',
    border: '0.5px',
    borderStyle: 'solid',
    borderRadius: '1em',
    borderColor: '#86b0cc',
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTextPorque: {
    width: '91%',
    left: '5%',
    position: 'relative',
    overflow: 'hidden',
    textAlign: "justify",

  },
  boxTextPorqueMobile: {
    width: '90%',
    left: '5%',
    position: 'relative',
    overflow:'hidden',
    textAlign: "justify",

  },
  boxNoticias: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 480px)',
    marginTop: '2%',
    gap: '0',
    justifyContent: 'center',
    padding: '0 0 5% 0'
  },
  boxNoticiasMobile: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
    marginTop: '5%'
  },
  linea: {
    backgroundColor: '#e8793b',
    height: '3px',
    width: '93%',
    left: '5%',
    position: 'relative',
    
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  fondoModalInsertarMobile: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '45%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto',
  },
  iconBtn: {
    left: '90%',
    top: '2%',
    position: 'absolute',
  },
  iconBtnMobile: {
    left: '87%',
    top: '-1%',
    position: 'absolute',
  },
  titleModalInsertar: {
    fontSize: '250%',
    fontWeight: 'bold',
    left: '15%',
    position: 'absolute',
    color: '#002A68',
    marginTop: '3%'
  },
  titleModalInsertarMobile: {
    fontSize: '180%',
    fontWeight: 'bold',
    left: '10%',
    position: 'absolute',
    color: '#002A68',
    marginTop: '8%'
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '80%',    
    height: '110em',
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1.2em',
    marginTop: '10%',
    marginBottom: '2% '
  },
  fondoFormulario2Mobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '80%',    
    height: '110em',
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '38%',
    marginBottom: '5%'
  },
  textPais2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '5%',
    left: '10%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '2%',
    left: '10%',
    fontWeight: 'bold'
  },
  textPais3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '150%',
    top: '5%',
    left: '20%',
    fontFamily: 'Montserrat'
  },
  textPais3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '130%',
    top: '2%',
    left: '40%',
  },
  textAño2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '10%',
    left: '10%',
    fontWeight: 'bold'
  },
  textAño2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '5%',
    left: '10%',
    fontWeight: 'bold'
  },
  textAño3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '150%',
    top: '10%',
    left: '20%',
  },
  textAño3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '130%',
    top: '5%',
    left: '40%',
  },
  textDescripcion2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '16%',
    left: '10%',
    fontWeight: 'bold'
  },
  textDescripcion2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '8%',
    left: '10%',
    fontWeight: 'bold'
  },
  textResultados2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '44%',
    left: '50%',
    fontWeight: 'bold'
  },
  textResultados2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '42.5%',
    left: '10%',
    fontWeight: 'bold'
  },
  textQuieres: {
    color: 'white',
    position: 'absolute',
    fontSize: '180%',
    top: '83%',
    left: '35%',
    fontWeight: 'bold'
  },
  textQuieresMobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '115%',
    top: '80%',
    left: '10%',
    fontWeight: 'bold'
  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '120%',
  },
  textResultados3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '95%',
  },
  textQuieres2: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '120%',
    top: '87%',
    left: '20%'
  },
  textQuieres2Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '100%',
    top: '83%',
    left: '10%'
  },
  textDescripcion3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '110%',
      
  },
  textDescripcion3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '95%',
      
  },
  boxTextDescripcion3: {
    position: 'absolute',
    width: '45%',
    height: '20%',
    top: '21%',
    left: '10%'   ,
  },
  boxTextDescripcion3Mobile: {
    position: 'absolute',
    width: '85%',
    height: '20%',
    top: '11%',
    left: '10%'   ,
  },
  boxFotoDescripcion1: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '40%',
    height: '12%',
    top: '20%',
    left: '55%'   ,
  },
  boxFotoDescripcion1Mobile: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '85%',
    height: '10%',
    top: '32%',
    left: '10%'   ,
  },
  boxFotoDescripcion2Mobile: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '85%',
    height: '10%',
    top: '67%',
    left: '10%'   ,
  },
  buscadorIcon: {
    color: 'white',
    top: '1%',
    position: 'relative'
,  },
  boxFotoDescripcion2: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '35%',
    height: '12%',
    top: '50%',
    left: '10%'   ,
  },
  boxTextResultados3: {
    position: 'absolute',
    width: '45%',
    height: '20%',
    top: '49%',
    left: '50%'    ,
  },
  boxTextResultados3Mobile: {
    position: 'absolute',
    width: '85%',
    height: '20%',
    top: '45%',
    left: '10%'    ,
  },
  accederMobile: {
    width: '10em',
    ['@media (min-width:750px)']:{
      width: '15em',
    },
  },
  boxaccederMobile: {
    width: '100%',
    marginTop: '2%',
    height: '10%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxtodoMobile: {
    overflowX: 'hidden',
    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'      
  },
  buscador: {
    position: 'relative',
    marginBottom: '1%',
    marginTop: '1%',
    width: '40%',
    height:'3em',
    backgroundColor: '#213361',
    left: '5%',
    border: '5px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '0 15px'

  },
  buscadorMobile: {
    position: 'relative',
    marginBottom: '2%',
    width: '83%',
    height:'2em',
    backgroundColor: '#213361',
    marginTop: '2%',
    left: '5%',
    border: '5px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: '0 0 0 0.5em',


  },
  buscador2: {
    position: 'relative',
    width: '91%',
    height:'3em',
    backgroundColor: '#213361',
    left: '5%',
    border: '5px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    verticalAlign: 'middle',

  },
  buscador2Mobile: {
  position: 'relative',
  width: '85%',
  backgroundColor: '#213361',
  left: '5%', 
  listStyle: 'none',
  margin: '0',
  padding: '0',

},
  textFiltros: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '1',
    marginLeft: '3%',
    fontFamily: 'Montserrat'
  },
  textFiltrosMobile: {
    color: 'white',
    display: 'block',
    padding: '10px',
    border: 'black solid 1px',
    fontFamily: 'Montserrat'

  },
  textpaisBarra: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '1',
    fontFamily: 'Montserrat'


  },
  textpaisBarraMobile: {
    color: 'white',
    left: '40%',
    top: '20%',
    position: 'absolute',
    fontSize: '100%',
    fontFamily: 'Montserrat'

  },
  textNivel: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    marginRight: '3%',
    fontFamily: 'Montserrat'

  },
  textCategoriaBarra: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '0.8',
    fontFamily: 'Montserrat'
  },
  textCategoriaBarraMobile: {
    color: 'white',
    left: '64%',
    top: '20%',
    position: 'absolute',
    fontSize: '100%',
    fontFamily: 'Montserrat'

  },

  noBorder: {
    border: "none",
    color: 'white',
    float: 'right',
  },
  optionsFechaMobile: {
    backgroundColor: '#2A7DE1',
    position: 'relative',
    padding: '0',
    display: 'block',
    zIndex: '1',
  },


  flechaFiltro2: {
    color: 'white',
    position: 'relative',
  },
  flechaFiltroMobile: {
    color: 'white',
    left: '23%',
    top: '20%',
    position: 'absolute',
  },
  flechaPaisMobile: {
    color: 'white',
    left: '50%',
    top: '20%',
    position: 'absolute',
  },
  flechaPais: {
    color: 'white',
    position: 'relative'
  },
  flechaCategoria: {
    color: 'white',
    position: 'relative'
  },
  flechaCategoriaMobile: {
    color: 'white',
    left: '90%',
    top: '20%',
    position: 'absolute'
  },
  flechaNivel: {
    color: 'white',
    position: 'relative'
  },
  flechaFiltro: {
    color: 'white',
    position: 'absolute',
    left: '85%'
  },

  iconFiltroMobile: {
    color: 'white',
    right: '2%',
    top: '20%',
    position: 'relative',
  },
  iconPais: {
    color: 'white',
    position: 'relative',
    top: '0.3em'
  },
  iconFiltro: {
    color: 'white',
    position: 'relative',
    top: '0.2em'
  },
  iconPaisMobile: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: '33%'
  },
  iconCategoria: {
    color: 'white',
    position: 'relative',
  },
  iconCategoriaMobile: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: '57%'
  },
  iconNivel: {
    color: 'white',
    position: 'relative',
  },
  optionsFecha: {
    backgroundColor: '#213361',
    position: 'absolute',
    left: '8%',
    padding: '0',
    display: 'block',
    zIndex: '1',
  },
  optionsPais: {
    backgroundColor: '#213361',
    position: 'absolute',
    left: '25%',
    padding: '0',
    display: 'block',
    zIndex: '1',
    overflow: 'auto',
    height: '20%'
  },
  optionsCategorias: {
    backgroundColor: '#213361',
    position: 'absolute',
    left: '45%',
    padding: '0',
    display: 'block',
    zIndex: '1',
    overflow: 'auto',
    height: '20%'
  },
  optionsNivel: {
    backgroundColor: '#213361',
    position: 'absolute',
    left: '77%',
    padding: '0',
    display: 'block',
    zIndex: '1',
  },
  optionsFecha2: {
  float: 'none',
  color: 'white',
  padding: '1rem 1rem',
  display: 'block',
  border: '1px solid black',
  cursor: 'pointer'

  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'
  
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'

  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  imagenFooter2M: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  optionsFecha2Mobile: {
    float: 'none',
    color: 'white',
    padding: '0.5rem 0.5rem',
    display: 'block',
    border: '1px solid black',
    cursor: 'pointer'

    },
    verMasFoto2: {
      position: 'relative',
      width: '10em',
      cursor: 'pointer'
    },
    verMasFoto2M: {
      position: 'relative',
      width: '8',
      cursor: 'pointer'
    },
    boxverMasFoto2: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxverMasFoto2M: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '25%'
    },
  
  

}));

export default function MenuPage() {
  CleanConsole.init()


  
  React.useEffect(() => {    
    validateSession();
    getNoticias();
    getPaises();
    getCategorias();



  }, []);

 

  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
  }

  

  const [spacing, setSpacing] = React.useState(2);

  const [noticias, setNoticias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [paises, setPaises] = useState([]);
  const buscadorRef = React.useRef("");
  
  const [mobileFiltro, setMobile] = React.useState({
    showDivMobile: false,
    showDivMobile2: false,
    showDivMobile3: false,
    showDivMobile4: false,
  });

      
  const [state, setState] = React.useState({
    mobileView: false,
    experiencia: [],
    showDiv: false,
    showDiv2: false,
    showDiv3: false,
    showDiv4: false,
    countNoticias: 6,
    
  });

  const { showDiv } = state
  const { showDiv2 } = state
  const { showDiv3 } = state
  const { showDiv4 } = state
  const { showDivMobile } = mobileFiltro
  const { showDivMobile2 } = mobileFiltro
  const { showDivMobile3 } = mobileFiltro
  const { showDivMobile4 } = mobileFiltro

  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/usuario/logout', data, {
      headers: {
        'Usuario-Id': localStorage.getItem("usuario_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }

  function getCategorias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/list", {

    }
    )
    .then((response) => {
      setCategorias(response.data.data);

      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 
  
 
  const inputChanged = e => {

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      getNoticias()
    }, 500)

    setTimer(newTimer)
  }


  function getNoticias(order="", nivel="", categoria_id="", pais=""){ 
    var url=localStorage.getItem('URL') + "/main/api/noticia/list?estado=A";
    if (order != "" ) {
      url+="&order_by="+order
    };

    if (categoria_id != "" ) {
      url+="&categoria_id="+categoria_id
    };

    if (pais != "" ) {
      url+="&pais_id="+pais
    };

    if (nivel != "" ) {
      url+="&nivel_complejidad="+nivel
    };

    if (buscadorRef.current.value != "") {
      url+="&noticia_nombre="+buscadorRef.current.value 
    };
    
    axios
    .get(url, {

    }
    )
    .then((response) => {
      setNoticias(response.data.data)
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

 

  const [mostrarMenu, MostrarMenu] = useState(false);

  
  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setPaises (response.data.data );
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function mostrarOtro(){
    MostrarMenu(true)
    
  }
  function ocultarOtro(){
    MostrarMenu(false)
  }

  function MostrarMasNoticias(){
    state.countNoticias = state.countNoticias+6
    setState((prevState) => ({ ...prevState, countNoticias: state.countNoticias+6 }))

    
  }
 
  const [open1, setOpen1] = React.useState(false);
  const [timer, setTimer] = useState(null) 


  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
 

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box className={classes.boxtodoMobile}>
        <Box>
              <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFotoMobile}></img></a>
              { mostrarMenu && <Box className={classes.boxHomeMobile}>
              <a  onClick={ocultarOtro}><img alt=""  src={OpcionesImage} className={classes.opcionesFoto2Mobile}></img></a>
              <a href="#/menu/editar"><img alt="" src={editarIcon} className={classes.homeFotoMobile}></img></a>              
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFotoMobile}></img></a>
              <a href="#/menu/validaciones"><img alt="" src={CargarImage} className={classes.cargarFotoMobile}></img></a>
              <a href="#/"><img  alt="" src={logout} className={classes.logoutFotoMobile} onClick={cerrarSesion}></img></a>
            </Box>  }
            <Box>
            <img alt="" src={FondoArriba} className={classes.fondoArribaMobile}></img>            
            <Typography className={classes.registrarteMobile}>
            {localStorage.getItem("nombre")}
            </Typography> 
            </Box>
            <Box className={classes.boxaccederMobile}>
            <a href="#/menu/subeExperiencia" ><img alt="" src={subeExperiencia}className={classes.accederMobile}></img></a>    
            </Box>     
            <Box className={classes.buscadorMobile}> 
            <SearchIcon className={classes.buscadorIcon} fontSize='small'></SearchIcon>           
            <TextField
      variant="outlined"
      placeholder="Buscador"
      fullWidth
      InputProps={{        
        classes:{notchedOutline:classes.noBorder}        
      }}
      inputProps={{ style: { color: "white", fontFamily: 'Montserrat' } }}
      onChange={inputChanged}
      inputRef={buscadorRef}
    ></TextField>
            </Box>
            <Box className={classes.buscador2Mobile}>
            <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile: !showDivMobile })}><ClearAllIcon className={classes.iconFiltro} fontSize='small'></ClearAllIcon> Fecha
              <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile && <div className={classes.optionsFechaMobile}>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile: !showDivMobile }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("asc","",""); setMobile({ showDivMobile: !showDivMobile }) }}>Más antiguas</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("desc","",""); setMobile({ showDivMobile: !showDivMobile }) } }>Más recientes</a>
            </div>}
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile2: !showDivMobile2 })}><GolfCourseIcon className={classes.iconFiltro} fontSize='small'></GolfCourseIcon>País
              <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile2 &&  (<div className={classes.optionsFechaMobile}>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile2: !showDivMobile2 }) }}>Selecciona...</a>
              {paises.map(pais =>
                <a  className={classes.optionsFecha2Mobile} onClick={() => {getNoticias("","","",pais.id); setMobile({ showDivMobile2: !showDivMobile2})}}> {pais.nombre} </a>)}
            </div>)}
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile3: !showDivMobile3 })} > <BorderAllIcon className={classes.iconFiltro} fontSize='small' ></BorderAllIcon>Categorías de innovación<ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile3 && (<div className={classes.optionsFechaMobile}>
                <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile3: !showDivMobile3 }) }}>Selecciona...</a>
              {categorias.map(categoria =>
                <a  className={classes.optionsFecha2Mobile} onClick={() => {getNoticias("","",categoria.id); setMobile({ showDivMobile3: !showDivMobile3})}}> {categoria.nombre} </a>)}
            </div>)}   
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile4: !showDivMobile4 })}><GraphicEqIcon className={classes.iconFiltro}fontSize='small' ></GraphicEqIcon>Nivel de complejidad <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile4 && (<div className={classes.optionsFechaMobile}>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile4: !showDivMobile4 }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2Mobile} onClick={() =>  {getNoticias("","1",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Primer nivel de generación de información</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","2",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Segundo nivel de interoperabilidad</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","3",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Tercer nivel de gestión de información </a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","4",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Cuarto nivel de gestión de conocimiento</a>
            </div>)}        
            </Box>            
            
            <Box className={classes.boxNoticiasMobile}> 
            {noticias.slice(0,state.countNoticias).map(noticia =>
               <div key={noticia.id} className={classes.boxNoticiaMobile}>    
               <Box className={classes.imagenNoticiaMobile}>              
                 {noticia.imagen1_filename != '' ? <img  alt="" src={noticia.imagen1_filename} style={{display: 'block', maxWidth: '100%',  minHeight: '75%'}} ></img> : <img alt="" src={fondoNoticia} style={{display: 'block', width: '100%',  height: '100%'}}></img>}
                </Box>
                 <hr className={classes.linea}></hr>
                 {noticia.destacada == "1" ? <Box><StarIcon  className={classes.star}></StarIcon></Box> : <Box></Box>}
                 <Typography className={classes.textTituloMobile} >Título:</Typography>                 
                 <Typography className={classes.textNombreMobile} >{noticia.nombre}</Typography>
                 <Box className={classes.boxtextCategoria2Mobile}>
                 <Typography className={classes.textCategoria2Mobile} ><a className={classes.textCategoriaMobile}>Categoría:  &nbsp;</a>{noticia.categoria_nombre}</Typography>
                 </Box>
                 <Typography className={classes.textPais2NMobile} ><a className={classes.textPaisMobile}>País: &nbsp;</a>{noticia.pais_nombre == "" ? "Sin información": noticia.pais_nombre}</Typography>
                 <Typography className={classes.textAño2NMobile} ><a className={classes.textAñoNMobile}>Año: &nbsp;</a>{noticia.ano == null ? "Sin información": noticia.ano}</Typography>
                 <Typography className={classes.textPorqueMobile} >¿Por qué es una solución
innovadora?:</Typography>
                <Box className={classes.boxTextPorqueMobile}>
                 <Typography className={classes.textPorque2Mobile} >{ ReactHtmlParser(noticia.razon_innovadora_tarjeta)}</Typography>
                 </Box>
                 <Box className={classes.boxverMasFotoMobile}>
                 <a href={"#/experiencia/"+noticia.hash} rel="noreferrer"  ><img alt="" src={verMas} className={classes.verMasFotoMobile}></img></a>
                 </Box>
                 
               </div>
            )}  
            </Box>
            <Box className={classes.boxverMasFoto2M}> 
            {state.countNoticias > noticias.length ? <Box></Box>: <img alt=""  src={verMas} className={classes.verMasFoto2M} onClick={MostrarMasNoticias}></img>}   
            </Box> 
    
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img  alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
      </Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)'    }}>
          <Box>
              <a  onClick={mostrarOtro}><img alt="" src={OpcionesImage} className={classes.opcionesFoto}></img></a>
              { mostrarMenu && <Box className={classes.boxHome}>
              <a  onClick={ocultarOtro}><img  alt="" src={OpcionesImage} className={classes.opcionesFoto2}></img></a>
              <a href="#/menu/subeExperiencia"><img alt="" src={EscribirImage} className={classes.escribirFoto}></img></a>
              <a href="#/menu/editar"><img alt="" src={editarIcon} className={classes.escribirFoto}></img></a>
              <a href="#/menu/validaciones"><img alt=""  src={CargarImage} className={classes.cargarFoto}></img></a>
              <a href="#/"><img alt="" src={logout} className={classes.logoutFoto} onClick={cerrarSesion}></img></a>
            </Box>  }
            
            <img  alt="" src={FondoArriba} className={classes.fondoArriba}></img>
            <Box>
            </Box>
            <Box>
              <a href="#/menu/subeExperiencia" ><img alt=""  src={subeExperiencia}className={classes.acceder}></img></a>
            </Box>
            <Typography className={classes.registrarte}>
            {localStorage.getItem("nombre")}
            </Typography> 
            <Box className={classes.buscador}> 
            <SearchIcon className={classes.buscadorIcon} fontSize='small'></SearchIcon>           
            <TextField
      variant="outlined"
      placeholder="Buscador"
      fullWidth    
      InputProps={{        
        classes:{notchedOutline:classes.noBorder}        
      }}
      inputProps={{ style: { color: "white", fontSize: '130%',  fontFamily: 'Montserrat'  } }}
      
      onChange={inputChanged}
      inputRef={buscadorRef}
    ></TextField>
            </Box>
            <Box className={classes.buscador2}>                          
            <Typography className={classes.textFiltros} variant='h6' ><ClearAllIcon className={classes.iconFiltro}  ></ClearAllIcon>
            Fecha
            <ArrowDropDownIcon className={classes.flechaFiltro2} fontSize='large' onClick={() => setState({ showDiv: !showDiv })} ></ArrowDropDownIcon>
            </Typography>      
            <Typography className={classes.textpaisBarra} variant='h6' > <GolfCourseIcon className={classes.iconPais} fontSize='small'></GolfCourseIcon>
            País
            <ArrowDropDownIcon className={classes.flechaPais} fontSize='large' onClick={() => setState({ showDiv3: !showDiv3})}></ArrowDropDownIcon>
            </Typography>             
            <Typography  className={classes.textCategoriaBarra} variant='h6'><BorderAllIcon className={classes.iconFiltro} ></BorderAllIcon>
            Categorías de innovación
            <ArrowDropDownIcon className={classes.flechaCategoria} fontSize='large' onClick={() => setState({ showDiv4: !showDiv4})}></ArrowDropDownIcon>
            </Typography>           
            <Typography className={classes.textNivel} variant='h6' ><GraphicEqIcon className={classes.iconFiltro} ></GraphicEqIcon>
            Nivel de complejidad
            <ArrowDropDownIcon className={classes.flechaNivel} onClick={() => setState({ showDiv2: !showDiv2 })} fontSize='large'></ArrowDropDownIcon>
            </Typography>             
            </Box>
            {showDiv && <div className={classes.optionsFecha}>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","","");  setState({ showDiv: !showDiv })}}>Selecciona...</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("asc","","");  setState({ showDiv: !showDiv })}}>Más antiguas</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("desc","","" ); setState({ showDiv: !showDiv })}}>Más recientes</a>
            </div>}
            {showDiv2 && (<div className={classes.optionsNivel}>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","","");  setState({ showDiv2: !showDiv2 })}}>Selecciona...</a>
              <a className={classes.optionsFecha2} onClick={() =>  {getNoticias("","1",""); setState({ showDiv2: !showDiv2 })}}>Primer nivel de generación de información</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","2",""); setState({ showDiv2: !showDiv2 })}}>Segundo nivel de interoperabilidad</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","3",""); setState({ showDiv2: !showDiv2 })}}>Tercer nivel de gestión de información </a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","4",""); setState({ showDiv2: !showDiv2 })}}>Cuarto nivel de gestión de conocimiento</a>
            </div>)}
            {showDiv3 && (<div className={classes.optionsPais}>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","","");  setState({ showDiv3: !showDiv3 })}}>Selecciona...</a>
              {paises.map(pais =>
                <a  className={classes.optionsFecha2} onClick={() => {getNoticias("","","",pais.id); setState({ showDiv3: !showDiv3})}}> {pais.nombre} </a>)}
            </div>)}
            {showDiv4 && (<div className={classes.optionsCategorias}>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","","");  setState({ showDiv4: !showDiv4 })}}>Selecciona...</a>
              {categorias.map(categoria =>
                <a  className={classes.optionsFecha2} onClick={() => {getNoticias("","",categoria.id); setState({ showDiv4: !showDiv4})}}> {categoria.nombre} </a>)}
            </div>)}           
           
           
            <Box className={classes.boxNoticias}> 
            {noticias.slice(0,state.countNoticias).map(noticia =>
               <div key={noticia.id} className={classes.boxNoticia}> 
               <Box className={classes.imagenNoticia}>                    
                 {noticia.imagen1_filename != '' ? <img alt="" src={noticia.imagen1_filename} style={{display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'}}  ></img> :<img  alt="" src={fondoNoticia} style={{width: '100%', height: '100%'}}></img>}
                 </Box> 
                 <hr className={classes.linea}></hr>
                 {noticia.destacada == "1" ? <Box><StarIcon fontSize='large'  className={classes.star}></StarIcon></Box> : <Box></Box>}
                 <Typography className={classes.textTitulo} >Título:</Typography>                 
                 <Typography className={classes.textNombre} >{noticia.nombre}</Typography>                 
                 <Box className={classes.boxtextcategoria2}>                 
                 <Typography className={classes.textCategoria2} ><a className={classes.textCategoria}>Categoría: &nbsp;</a>{noticia.categoria_nombre}</Typography>
                 </Box>
                 <Typography className={classes.textPais2N} ><a className={classes.textPais}>País: &nbsp;</a>{noticia.pais_nombre == "" ? "Sin información": noticia.pais_nombre}</Typography>
                 <Typography className={classes.textAño2N} ><a  className={classes.textAñoN}>Año:  &nbsp;</a>{noticia.ano == null ? "Sin información": noticia.ano}</Typography>
                 <Typography className={classes.textPorque} >¿Por qué es una solución
innovadora?:</Typography>
                <Box className={classes.boxTextPorque}>
                 <Typography className={classes.textPorque2} >{ ReactHtmlParser(noticia.razon_innovadora_tarjeta)}</Typography>
                 </Box>           
                 <Box  className={classes.boxverMasFoto}>    
                 <a href={"#/experiencia/"+noticia.hash} rel="noreferrer"  ><img  alt="" src={verMas} className={classes.verMasFoto}></img></a>               
                 </Box>  
               </div>
            )}  
            
            </Box>
            <Box className={classes.boxverMasFoto2}> 
            {state.countNoticias > noticias.length ? <Box></Box>: <img alt="" src={verMas} className={classes.verMasFoto2} onClick={MostrarMasNoticias}></img>} 
            </Box> 
          
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }