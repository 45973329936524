import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Modal from '@material-ui/core/Modal';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { ReactTableDefaults } from 'react-table-6';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { CleanConsole } from '@eaboy/clean-console';





const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondo: {
    width: '100%',
    height: '90em',
    backgroundColor: '#002A68',
    
  },
  homeFoto: {   
    height: '60%',
    position: 'absolute',
    left: '10%',
    top:'10%'
  },
  btnCrear: { 
    color: 'white',
    backgroundColor: '#5cb85c',
    marginTop: '5%',
    left: '5%',
    marginBottom: '3%',
    fontFamily: 'Montserrat'
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },  
  titleModalInsertar: {
    fontSize: '150%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'


  },
  form: {
    position: 'absolute',
    top: '15%',
    left: '10%',    
    width: '85%',
    height: '80%'
  },
  inputEmail: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputNombre: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputContraseña: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    left: '25%',
    color: 'white',
    marginBottom: '5%',
    fontFamily: 'Montserrat' 
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '3%',
    color: 'white',
    marginBottom: '5%',
    fontFamily: 'Montserrat' 

  },
  title: {
      position: 'absolute',
      color: 'white',
      fontWeight: 'bold',
      top: '10%',
      left: '15%'
  },
  fondoArriba: {
    width: '100%',
    height: '8em',
    position: 'abosolute'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5%',
    height: '12%'
  },
  legalFoto: {   
    width:'100%',
    marginTop: '5%',
    position: 'relative',
  },    
  btnAdministradores: {
    position: 'absolute',
    top: '2%',
    left: '3%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnNoticias: {
    position: 'absolute',
    top: '2%',
    left: '40%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  bienvenido: {
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '65%',
    fontFamily: 'Montserrat'
  },
  btnCerrar: {
    position: 'absolute',
    top: '2%',
    left: '90%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnUsuarios: {
    position: 'absolute',
    top: '2%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  barraMenu: {
    position: 'absolute',
    backgroundColor: '#213361',
    width: '95%',
    height: '2.5em',
    marginTop: '1%',
    left: '2.5%',
    border: '5px',
    borderRadius: '10px'
  }, 
  textNombreCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textNombre: {
    fontFamily: 'Montserrat'
  },
  textCorreoCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textIdCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textEditarCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  btnCategorias: {
    position: 'absolute',
    top: '2%',
    left: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  otroGenero: {
    display: 'none'
  },
  otroGenero2: {
    display: 'block',
    
  },
  inputFecha: {
    width: '90%',
    fontFamily: 'Montserrat'
  },
  inputF:{
    fontFamily: 'Montserrat'
  },
  textPais: {
    marginTop: '4%',
    fontFamily: 'Montserrat'
  },
  inputPais: {
    width: '90%',
    marginBottom: '4%'
  },
  tablaAdmin: {
    top: '32%',      
    width: '85%',
    minWidth: 'auto',
    left: '5%',
    marginBottom: '5%',
    overflowX: 'scroll',
},
btnEditar: {
  backgroundColor: '#0275d8',
  marginRight: '10%',
  marginLeft: '5%',
  color: 'white',
  fontFamily: 'Montserrat'
},
btnEliminar: {
  backgroundColor: '#d9534f',
  color: 'white',
  fontFamily: 'Montserrat',
  
  
},
btnReportes: {
  position: 'absolute',
  top: '2%',
  left: '75%',
  color: 'white',
  fontFamily: 'Montserrat'
},
iconBtn: {
  left: '90%',
  top: '2%',
  position: 'absolute',
},
btnInsertar2: {
  backgroundColor: '#5cb85c',
  marginTop: '3%',
  marginBottom: '5%',
  left: '25%',
  color: 'white',
  fontFamily: 'Montserrat' 
},
btnCancelar2: {
  backgroundColor: '#d9534f',
  left: '40%',
  marginTop: '3%',
  marginBottom: '5%',
  color: 'white',
  fontFamily: 'Montserrat' 
},
inputId: {
  width: '90%',
  height: '10%',
  position: 'absolute',
  marginBottom: '5%',
},
textEmail: {
  marginTop: '10%',
},
titleModalEliminar: {
  fontSize: '150%',
  fontWeight: 'bold',
  position: 'absolute',
  top: '20%',
  fontFamily: 'Montserrat'
},
fondoModalEliminar: {
  position: 'absolute',
  backgroundColor: 'white',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  overflow: 'auto'
},
btnInsertar3: {
  backgroundColor: '#5cb85c',
  marginTop: '3%',
  marginBottom: '5%',
  left: '25%',
  top: '60%',
  color: 'white',
  fontFamily: 'Montserrat' 
},
btnCancelar3: {
  backgroundColor: '#d9534f',
  left: '40%',
  marginTop: '3%',
  top: '60%',
  marginBottom: '5%',
  color: 'white',
  fontFamily: 'Montserrat' 
},
divExtio: {
  backgroundColor: '#D3E9D0',
  height: '15%',
  width: '20%',
  position: 'absolute',
  top: '75%',
  left: '3%',
  border: '3px',
  borderRadius: '5px'

},
divExtio2: {
  backgroundColor: '#D3E9D0',
  opacity: '10',
  height: '10%',
  width: '20%',
  position: 'absolute',
  top: '75%',
  left: '3%',
  border: '3px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'

},
exito: {
  color: 'green',
  position: 'absolute',
  fontFamily: 'Montserrat'
},
exito2: {
  color: 'green',
  position: 'absolute',
  fontFamily: 'Montserrat'
 
},
boxtitleModalInsertar: {
  left: '15%',
  position: 'relative',    
  width: '70%',
  height: '5%',
  marginTop: '3%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
},
divError: {
  backgroundColor: '#ff9090',
  height: '15%',
  width: '50%',
  left: '-5%',
  top: '280%', 
  position: 'absolute',  
  border: '3px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
},
divError4: {
  backgroundColor: '#ff9090',
  height: '15%',
  width: '40%',
  left: '-5%',
  top: '250%',
  position: 'absolute',  
  border: '3px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
},
divError2: {
  backgroundColor: '#ff9090',
  height: '8%',
  width: '20%',
  position: 'absolute',
  top: '75%',
  left: '3%',
  border: '3px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
},
error: {
  color: 'red',
  position: 'absolute',
  fontFamily: 'Montserrat'
},
boxFooter: {
  width: '100%',
  height: '20em',
  backgroundColor: '#222222',
  position: 'relative',
  marginTop: '10%',
  border: '1px',
  borderRadius: '10px'

},
imagenFooter: {
  width: '100%',
  height:'40%',
},
imagenFooter2: {
  left: '5%',
  top: '5%',
  position: 'relative',
  width: '10em'
},
boxRedes: {
  position: 'absolute',
  width: '13%',
  top: '50%',
  left: '87%',
  height: '10%',
},
redesFooter: {
  width: '2em',
  margin: '0 2px'
},
lineaFooter:{
  height:'2%',
  backgroundColor: 'white'
},
textFooter: {
  left: '5%',
  position: 'relative',
  color: 'white',
  width: '95%',
  fontSize: '90%',
  marginBottom: '0.5%',
  fontFamily: 'Montserrat'

},


}));


export default function AdminAdministradores() { 
  CleanConsole.init()

  const [mostrarOtroG, cambiarDisplay] = useState(false);
  const [formularioEnviado3, cambiarFormularioEnviado3] = useState(false);

  const [errorFormulario3, cambiarErrorFormulario3] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorMessage, setError] = useState([]); 


  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen2 = (rowId) => {
    setIdRowNow(rowId);
    setOpen2(true);
  };
  const handleOpen3 = (rowId) => {
    setIdRowNow(rowId);    
    setOpen3(true);
  };
  
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [idRowNow, setIdRowNow] = useState(0);
  const [Genero,setGenero] = React.useState();   
  const [GeneroDefault,setUsuarioGenero] = React.useState();   
  const [fechaUsuarioDefault,fechaDefault] = React.useState();   
  const [Fecha,setFecha] = React.useState();
  const [state, setState] = React.useState({
    mobileView: false,    
    usuarios: [],
    usuario: [],
    usuarioGenero: [],
    paises: [],
  });

  function refreshPage() {
    window.location.reload(false);
  }
  
  
  function editarUsuario(){ 
    let generoOtro=""
    let GeneroE = "" 
    let FechaE = ""
      if (mostrarOtroG === true){
      generoOtro=generoOtroRef.current.value
      }        
       
      if (Genero === undefined){
        GeneroE=GeneroDefault
      }
      else {
        GeneroE = Genero
      }
    
      if (Fecha === undefined){
        FechaE = fechaUsuarioDefault.fecha_nacimiento
      }
      else {
        FechaE = Fecha
      }
      console.log(Genero)
      console.log(state.usuario.genero)
      console.log(fechaUsuarioDefault)
    const data = {
      usuario:
        { 
          id: idRowNow,
          nombres: nombreRefEditar.current.value,
          apellidos: apellidoRefEditar.current.value,
          cargo: cargoRefEditar.current.value,
          fecha_nacimiento: FechaE,
          genero: GeneroE,
          genero_otro: generoOtro,
          pais_id: paisRef.current.value,
          ciudad: ciudadRefEditar.current.value,
          password: passwordRefEditar.current.value,
          institucion: institucionRefEditar.current.value,
          twitter: twitterRefEditar.current.value,
          web: webRefEditar.current.value,
          facebook: facebookRefEditar.current.value,
          linkedin: linkedinRefEditar.current.value,
          telefono: telefonoRefEditar.current.value,    
          estado: estadoRefEditar.current.value,      
        }          
    };
    axios
    .put(localStorage.getItem('URL') + "/main/api/usuario/update", data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    }
    )
    .then((response) => {
      handleClose2()
     cambiarFormularioEnviado3(true);
     setTimeout(() => cambiarFormularioEnviado3(false), 5000)
     setTimeout(() => refreshPage(), 2000)
    
    
      
    })
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)  
      cambiarErrorFormulario3(true);
      setTimeout(() => cambiarErrorFormulario3(false), 5000); 
    });
  } 


  
  Object.assign(ReactTableDefaults, {
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando..',
    noDataText: 'No rows found',
    pageText: 'Pagina',
    ofText: 'de',
    rowsText: 'Filas',
  })

  const columns = [{
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    width: 60,
    Cell: row => <div style={{ textAlign: "center", }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold', backgroundColor: '#e3e4e5'}
    
  }, {
    id: 'nombre',
    Header: 'NOMBRES',
    accessor: 'nombres',
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },{
    id: 'apellidos',
    Header: 'APELLIDOS',
    accessor: 'apellidos',
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },{
    id: 'cargo',
    Header: 'CARGO',
    accessor: 'cargo',
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },
  {
    id: 'genero',
    Header: 'GENERO',
    accessor: 'genero',
    width: 120,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value === 'M' ? 'MASCULINO' : row.value === 'F' ? "FEMENINO" : "OTRO"}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },
   {
    id: 'email',
    Header: 'EMAIL',
    accessor: 'email' ,
    width: 200,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },
  {
    id: 'institucion',
    Header: 'INSTITUCION',
    accessor: 'institucion' ,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },  
  {
    id: 'ciudad',
    Header: 'CIUDAD',
    accessor: 'ciudad' ,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },{
    id: 'fecha_nacimiento',
    Header: 'FECHA NACIMIENTO',
    accessor: 'fecha_nacimiento' ,
    width: 180,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  },
   {
    id: 'estado',
    Header: 'ESTADO',
    accessor: 'estado' ,
    width: 80,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value === 'A' ? 'ACTIVO' : 'INACTIVO'}</div>,
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'}
  }, {
    id: 'acciones',
    Header: 'ACCIONES',
    accessor: 'id',
    headerStyle: {fontWeight: 'bold',backgroundColor: '#e3e4e5'},
    width: 200,
    Cell: (row) => {     
      return <div>
        <button className={classes.btnEditar} 
        onClick={() => {
          handleOpen2(row.value);
          getUsuario(row.value);
          
       }}
        >EDITAR </button>
        
        <button className={classes.btnEliminar} onClick={() => handleOpen3(row.value)}>ELIMINAR </button>
        
      </div>;
    }     
  }]
  

  const [open1, setOpen1] = React.useState(false);
  
 
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [formularioEnviado2, cambiarFormularioEnviado2] = useState(false);
  const [errorFormulario2, cambiarErrorFormulario2] = useState(false);





  const { mobileView } = state;
  const classes = useStyles();

  const nombreRef = React.useRef();
  const apellidoRef = React.useRef();
  const cargoRef = React.useRef();
  const generoOtroRef = React.useRef("");
  const paisRef = React.useRef();
  const ciudadRef = React.useRef();
  const institucionRef = React.useRef();
  const twitterRef = React.useRef();
  const webRef = React.useRef();
  const facebookRef = React.useRef();
  const linkedinRef = React.useRef();
  const telefonoRef = React.useRef();
  const correoRef = React.useRef();
  const passwordRef = React.useRef(); 

  const nombreRefEditar = React.useRef();
  const apellidoRefEditar = React.useRef();
  const cargoRefEditar = React.useRef();
  const ciudadRefEditar = React.useRef();
  const institucionRefEditar = React.useRef();
  const twitterRefEditar = React.useRef();
  const webRefEditar = React.useRef();
  const facebookRefEditar = React.useRef();
  const linkedinRefEditar = React.useRef();
  const telefonoRefEditar = React.useRef();
  const estadoRefEditar = React.useRef();
  const passwordRefEditar = React.useRef(); 
  

  React.useEffect(() => {
    validateSession();
    getUsuarios();
    getPaises();
    getUsuario();
  }, []);

  function  validateSession(){
    if (localStorage.getItem('session_token') === undefined){
      window.location.href="/#/acceder"
    }
    if (localStorage.getItem('administrador_id') === undefined){
      window.location.href="/#/acceder"
    }
  }

  function handleChangeEvent(event) {
  
    setState((prevState) => ({ ...prevState, usuario: {
      [event.target.name]: event.target.value,
    } 
  }));
  }

  function mostrarOtro(){
    cambiarDisplay(true)
    
  }
  function ocultarOtro(){
    cambiarDisplay(false)
  }
  
  function eliminarUsuario(usuarioID){ 
    axios
    .delete(localStorage.getItem('URL') + "/main/api/usuario/delete?id="+usuarioID, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
      handleClose3()
      cambiarFormularioEnviado2(true);
      setTimeout(() => cambiarFormularioEnviado2(false), 5000)
      setTimeout(() => refreshPage(), 2000) 
          
    })
    .catch((error) => {
      console.log(error);    
      setError(error.response.data.message)  
      handleClose3()
      cambiarErrorFormulario2(true);
      setTimeout(() => cambiarErrorFormulario2(false), 5000); 
    });
  } 

  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setState((prevState) => ({ ...prevState, paises: response.data.data }));
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function getUsuarios(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/usuario/list", {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token")
      }
    }
    )
    .then((response) => {
    
      setDataTable(response.data.data);
      response.data.data.map((usuario) =>
        console.log("")
      );
      setState((prevState) => ({ ...prevState, usuarios: response.data.data })
      );
      
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function getUsuario(usuarioID){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/usuario/detail?id="+usuarioID, {
      headers: {        
        'Session-Token': localStorage.getItem("session_token"),
        'Administrador-Id': localStorage.getItem("administrador_id"),
        
      }      
      
    }
    )
    .then((response) => {
      setState((prevState) => ({ ...prevState, usuario: response.data.data }));
      setUsuarioGenero(response.data.data.genero)
      fechaDefault(response.data.data)
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function enviarUsuario(){
    var generoOtro=""
    if (mostrarOtroG === true){
    generoOtro=generoOtroRef.current.value
    }
    const data = {
      usuario:
        { 
          nombres: nombreRef.current.value,
          apellidos: apellidoRef.current.value,
          email: correoRef.current.value,
          cargo: cargoRef.current.value,
          fecha_nacimiento: Fecha,
          genero: Genero,
          genero_otro: generoOtro,
          pais_id: paisRef.current.value,
          ciudad: ciudadRef.current.value,
          password: passwordRef.current.value,
          institucion: institucionRef.current.value,
          twitter: twitterRef.current.value,
          web: webRef.current.value,
          facebook: facebookRef.current.value,
          linkedin: linkedinRef.current.value,
          telefono: telefonoRef.current.value,          
        }          
    };
    axios.post(localStorage.getItem('URL') + '/main/api/usuario', data)
    .then((response) => {
      handleClose1();
      cambiarFormularioEnviado(true);
      setTimeout(() => cambiarFormularioEnviado(false), 5000);    
      setTimeout(() => refreshPage(), 2000);    

    })
    .catch((error) => {
      console.log(error);  
      setError(error.response.data.message)     
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000); 

    });
  }

  function cerrarSesion(){   
    
    const data = {
        
          email: localStorage.getItem("email"),
                
            
    };
  
    axios.post(localStorage.getItem('URL') + '/main/api/administrador/logout', data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),        
      }
    })
    .then((response) => {
      localStorage.clear();   
    })
    .catch((error) => {
      console.log(error);
      
    });
  }
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box></Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box>
          <Box > 
          <Box>
          <Box>            
            <img alt=""  src={FondoArriba} className={classes.fondoArriba}></img>            
            <Box>
              <Typography variant="h4" className={classes.bienvenido}>
                Bienvenido {localStorage.getItem("nombre")}
              </Typography>                        
            </Box>
            
            <Box className={classes.barraMenu} >
            <Button className={classes.btnAdministradores} variant="text" href="#/menu/admin/administradores">Administradores</Button>
            <Button className={classes.btnUsuarios} variant="text" href="#/menu/admin/usuarios">Usuarios</Button>
            <Button className={classes.btnCategorias} variant="text" href="#/menu/admin/categorias">Categorías</Button>
            <Button className={classes.btnNoticias} variant="text" href="#/menu/admin/noticias">Admins. Experiencias</Button>
            <Button className={classes.btnReportes} variant="text" href="#/menu/admin/reportes">Reportes</Button>
            <Button className={classes.btnCerrar} variant="text" onClick={cerrarSesion} href="#/">Cerrar</Button>
            </Box>            
          </Box>
        </Box>                  
            <Button className={classes.btnCrear} variant="contained" onClick={handleOpen1}>
                Crear Usuario
            </Button>
            <Modal 
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.fondoModalInsertar}>
              <div align="right" className={classes.divIconBtn} >
                  <IconButton        
                    aria-label="close"
                    onClick={handleClose1}          
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                    className={classes.iconBtn}
                  >  
                    <CancelPresentationIcon fontSize="large"  />
                  </IconButton>
              </div>
              <Box className={classes.boxtitleModalInsertar}>
                <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
                    CREAR USUARIO
                </Typography>
                </Box>
                <Box className={classes.form}>
                  <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Email:
                  </Typography>
                  <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputEmail} inputRef={correoRef} />
                  </Box>
                  <Box>
                    <Typography  className={classes.textNombre}>Nombres:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputNombre} inputRef={nombreRef} />         
                  </Box>
                  <Box>
                    <Typography  className={classes.textApellidos}>Apellidos:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputNombre} inputRef={apellidoRef} />         
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>contraseña:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="password" className={classes.inputContraseña} inputRef={passwordRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Cargo:</Typography>
                    <TextField variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }}  className={classes.inputContraseña} inputRef={cargoRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Genero:</Typography>
                    <Box  className={classes.inputContraseña} > 
                    <Box className={classes.inputsF}> 
                      <input  type="radio" name="Genero"  value="F" id="F" color="primary" onClick={ocultarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Femenino</label><br/>
                      </Box> 
                      <Box className={classes.inputsM}>
                      <input type="radio" name="Genero"  value="M" id="M" color="primary" onClick={ocultarOtro}  onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Masculino</label><br/>
                      </Box>
                      <Box className={classes.inputsO}>
                      <input type="radio" name="Genero"  value="O" id="O" color="primary" onClick={mostrarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Otro, especiﬁca:</label><br/>
                      </Box>
                    </Box>
                  </Box>
                  { mostrarOtroG &&<Box className={classes.otroGenero2}>                   
                    
                    <TextField placeholder='Especifica' variant="outlined" inputProps={{ style: {  fontFamily: 'Montserrat' } }} className={classes.inputContraseña} inputRef={generoOtroRef} /> 
                  </Box>  }
                  
                  
                  <Box>                  
                    <Typography  className={classes.textNombre}>Fecha de nacimiento:</Typography>
                    <input  type="date" name="Fecha" className={classes.inputFecha}  onChange={(e)=>setFecha(e.target.value)} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textPais}>Pais:</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={paisRef} name="paises" className={classes.inputPais} defaultValue="0"> 
                      <option value='0'> Seleccione... </option>
                      {state.paises.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Ciudad:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={ciudadRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Institución a la que pertenece (trabajo, universidad, ONG,
centro de pensamiento, etc)</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={institucionRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Usuario de twitter</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={twitterRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Web</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={webRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Facebook</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={facebookRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>LinkedIn</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={linkedinRef} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Teléfono</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" variant="outlined"  className={classes.inputContraseña} inputRef={telefonoRef} /> 
                  </Box>
                  
                  { errorFormulario &&<div className={classes.divError4}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={enviarUsuario}     
                    className={classes.btnInsertar}      
                      >
                    Guardar
                  </Button>
                  <Button
                    variant="contained" 
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                  
                    </Box>
                  </Box>
            </Modal>
            <Modal
          open={open2}
          onClose={handleClose2}

        >
          <Box className={classes.fondoModalInsertar}>
            <IconButton
              aria-label="close"
              onClick={handleClose2}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box className={classes.boxtitleModalInsertar}>
            <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
              EDITAR USUARIO
            </Typography>
            </Box>
            <Box className={classes.form}>
              <Box>
                <Typography id="modal-modal-description" className={classes.textNombre} sx={{ mt: 2 }}>
                  Id:
                </Typography>
                <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputContraseña}  disabled="disabled"  readOnly value={idRowNow} />
              </Box>
                  <Box>
                    <Typography  className={classes.textNombre}>Email:</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} disabled="disabled"  value={state.usuario.email}  />         
                  </Box>
                  <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }} className={classes.textNombre}>
                  Nombres:
                  </Typography>
                  <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={nombreRefEditar} value={state.usuario.nombres} onChange={handleChangeEvent} />
                  </Box>                  
                  <Box>
                    <Typography  className={classes.textNombre}>Apellidos:</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={apellidoRefEditar} value={state.usuario.apellidos} onChange={handleChangeEvent} />         
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>contraseña:</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined" type="password" className={classes.inputContraseña} inputRef={passwordRefEditar} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Cargo:</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={cargoRefEditar}  value={state.usuario.cargo} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Genero:</Typography>
                    <Box  className={classes.inputContraseña} > 
                    <Box className={classes.inputsF}> 
                      <input  type="radio" name="Genero"  value="F" id="F" color="primary" onClick={ocultarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Femenino</label><br/>
                      </Box> 
                      <Box className={classes.inputsM}>
                      <input type="radio" name="Genero"  value="M" id="M" color="primary" onClick={ocultarOtro}  onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Masculino</label><br/>
                      </Box>
                      <Box className={classes.inputsO}>
                      <input type="radio" name="Genero"  value="O" id="O" color="primary" onClick={mostrarOtro} onChange={(e)=>setGenero(e.target.value)}/><label className={classes.inputF} >Otro, especiﬁca:</label><br/>
                      </Box>
                    </Box>
                  </Box>
                  { mostrarOtroG &&<Box className={classes.otroGenero2}>     
                    
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} placeholder='Especifica' variant="outlined"  className={classes.inputContraseña} inputRef={generoOtroRef} /> 
                  </Box>  }
                  
                  
                  <Box>                  
                    <Typography  className={classes.textNombre}>Fecha de nacimiento:</Typography>
                    <input  type="date" name="Fecha" className={classes.inputFecha} onChange={(e) => {setFecha(e.target.value); handleChangeEvent(e);}} value={state.usuario.fecha_nacimiento}  /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textPais}>Pais:</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }} inputRef={paisRef} name="paises" className={classes.inputPais}  value={state.usuario.pais_id} onChange={handleChangeEvent}> 
                      <option value='0'> Seleccione... </option>
                      {state.paises.map(elemento=>(
                        <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                        )
                      )}
                    </NativeSelect> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Ciudad:</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={ciudadRefEditar}  value={state.usuario.ciudad} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Institución a la que pertenece (trabajo, universidad, ONG,
centro de pensamiento, etc)</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={institucionRefEditar} value={state.usuario.institucion} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Usuario de twitter</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={twitterRefEditar} value={state.usuario.twitter} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Web</Typography>
                    <TextField  inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={webRefEditar} value={state.usuario.web} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Facebook</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={facebookRefEditar} value={state.usuario.facebook} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>LinkedIn</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} variant="outlined"  className={classes.inputContraseña} inputRef={linkedinRefEditar} value={state.usuario.linkedin} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Teléfono</Typography>
                    <TextField inputProps={{ style: {  fontFamily: 'Montserrat' } }} type="number" variant="outlined"  className={classes.inputContraseña} inputRef={telefonoRefEditar} value={state.usuario.telefono} onChange={handleChangeEvent} /> 
                  </Box>
                  <Box>                  
                    <Typography  className={classes.textNombre}>Estado:</Typography>
                    <NativeSelect inputProps={{ style: {  fontFamily: 'Montserrat' } }}  variant="outlined"  className={classes.inputContraseña} inputRef={estadoRefEditar} value={state.usuario.estado} onChange={handleChangeEvent} >
                      <option>Selecciona...</option>
                      <option value="A">Activo</option>
                      <option value="I">Inactivo</option>
                    </NativeSelect> 
                  </Box>
                  { errorFormulario3 &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }
              <Button
                variant="contained"
                size="large"
                className={classes.btnInsertar2}
                onClick={editarUsuario}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose2}
                className={classes.btnCancelar2}
              >
                Cancelar
              </Button>              
            </Box>
            
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleClose3}

        >
          <Box className={classes.fondoModalEliminar}>
            <IconButton
              aria-label="close"
              onClick={handleClose3}
              className={classes.iconBtn}
            >
              <CancelPresentationIcon fontSize="large" />
            </IconButton>
            <Box className={classes.boxtitleModalInsertar}>
            <Typography className={classes.titleModalEliminar} variant="h6" component="h2">
              ¿Seguro que desea eliminar este Usuario?
            </Typography>
            </Box>
            <Box className={classes.form}>


              <Button
                variant="contained"
                size="large"
                className={classes.btnInsertar3}
                onClick={() => eliminarUsuario(idRowNow)}
              >
                Eliminar
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose3}
                className={classes.btnCancelar3}
              >
                Cancelar
              </Button>
            </Box>
            
          </Box>
        </Modal>
            
            <ReactTable  className={classes.tablaAdmin}
              getTdProps={() => ({
                style: { border: `1px solid rgb(200 200 200)` },
              })}
              data={dataTable}
              columns={columns}
              pagination             
              
            />   
            { formularioEnviado && <div className={classes.divExtio2}> <Typography className={classes.exito}>Usuario creado correctamente</Typography> </div>}
            {formularioEnviado2 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Usuario Eliminado correctamente</Typography> </div>}
            {formularioEnviado3 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Usuario Editado correctamente</Typography> </div>}
            { errorFormulario2 &&<div className={classes.divError2}><Typography className={classes.error}> {errorMessage} </Typography> </div> }             
            
            
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt=""  src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>
          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }