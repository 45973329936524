import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Acceder from './images/Acceder.png';
import Fondo from './images/fondo-05.png';
import FondoPrueba from './images/fonodo-5.png';
import Registrarse from './images/Registrarse.png';
import LegalArriba from './images/logos2-R.png';
import LegalArriba2 from './images/logos2SinFondo.png';
import axios from 'axios';
import verMas from './images/ver-mas.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import fondoNoticia from './images/fondoNoticia.png';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import StarIcon from '@material-ui/icons/Star';
import ReactHtmlParser from 'react-html-parser'; 
import ImagenHeader from './images/Imagen- Resolucion2-06-06.png';
import cuadroHeader from './images/cuadro-azul-40.png';
import triangulos from './images/triangulos_Mesa de trabajo 1.png';
import { CleanConsole } from '@eaboy/clean-console';








const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondo: {
    width:'100%',
    height: '40%',
    position: 'absolute',
    backgroundColor: '#223362'
  },
  fondo2: {
    width:'100%',
    height: '60.5%',
    position: 'absolute',
    top: '39.5%',
  },
  fondo2M: {
    width:'100%',
    position: 'relative',
    height: '240px',
    //maxHeight:'120px'
  },
  fondo3: {
    width:'45%',
    height: '29%',
    position: 'absolute',
    top: '65%',
    left: '47%',
  },
  fondo3M: {
    width:'100%',
    height: '28%',
    position: 'relative',
    marginTop: '-0.5%',

  },
  fondoMobile: {
    width:'100%',
    height: '10',
    backgroundColor: '#223362',
    minHeight: '100px',
    marginBottom: '-0.2%'

  },
  acceder: {
    position: 'relative',
    left: '70%',
    ['@media (max-width:1350px)']:{
      left: '65%'
    },
    ['@media (max-width:1150px)']:{
      left: '58%'
    },
  },
  accederMobile: {
    position: 'relative',
    marginTop: '2vh',
    maxWidth: '120px',
    width: '15%',
    minHeight:'4%',
    minWidth: '75px',
    left: '70%',
    marginBottom: '1.5vh'
  },
  registrarse: {
    position: 'relative',
    left:'73%',
    marginRight: '5%',
    marginTop: '4%',
    ['@media (max-width:1350px)']:{
      left: '68%'
    },
    ['@media (max-width:1150px)']:{
      left: '61%'
    },



  },
  registrarseMobile: {
    position: 'relative',
    left: '70%',
    width: '15%',
    minHeight:'4%',
    minWidth: '75px',
    maxWidth: '120px',


  },
  texto: {
    position: 'absolute',
    width: '43%',
    top: '67%',
    left: '48%',
    height: '12em',
    color: 'white',
    display: 'flex',
    overflow: 'hidden',

  },
  textoMobile: {
    position: 'relative',
    width: '100%',
    left: '0%',
    overflow: 'hidden',
    color: 'white',
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: 'cover',
    textAlign: 'justify',
    marginTop: '-1.2%'
  },
  legalFoto: {   
    width:'100%',
    top:'90%',
    marginTop: '5%'
  },
  legalFotoMobile: {   
    width:'100%',
    top:'26vh',
    position: 'relative',
  },
  legalImagen: {
    position: 'absolute',
    top: '7%',
    left: '5%',  
    ['@media (max-height:730px)']:{
      top: '4%',
    },  
    ['@media (max-width:1350px)']:{
      width: '250px',
    },    
    ['@media (max-width:1150px)']:{
      width: '220px'
    },
  },
  legalImagenMobile: {
    position: 'absolute',
    width: '135px',
    top: '2vh',
    left: '5%',
    ['@media (max-height:490px)']:{
      width: '120px',
    },  
    ['@media (max-width:650px)']:{
      width: '110px',
    },   
    ['@media (max-width:899px)']:{
      width: '100px',
    },    
    ['@media (max-width:500px)']:{
      width: '90px'
    },
  },
  textoMobile2: {
    fontSize:  'calc(0.30em + 1vmin)',
    fontFamily: 'Montserrat',
    left: '10%',
    width: '80%',
    position: 'relative',
    marginTop: '2em',
    marginBottom: '2em'
  },
  boxNoticia: {
    width: '370px',
    height: '800px',
    marginTop: '20%',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#86b0cc',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '3px 3px 10px  #86b0cc',
    backgroundColor: 'white',
    margin: 'auto'
    
    
  },
  textTitulo:  {
    fontSize: '120%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textCategoria:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
  },
  textPais:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textAñoN:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textPorque:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  boxtextPorque:  {    
    position: 'relative',
    width: '95%',
    left: '5%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',

  },
  textCategoria2:  {
    fontSize: '100%',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'

  },
  boxTextCategoria2:  {
    left: '5%',
    position: 'relative',
    width: '94%',
    display: 'flex',
    justifyContent: 'left',
    maxHeight: '9%',
    overflow: 'hidden',
  },
  textPais2N:  {
    fontSize: '100%',
    color: '#213361',
    position: 'relative',
    left: '5%',
    width: '95%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textAño2N:  {
    fontSize: '100%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '95%',
    fontFamily: 'Montserrat'
  },
  textPorque2:  {
    fontSize: '100%',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textNombre: {
    color: '#213361',
    fontSize: '110%',
    left: '5%',
    position: 'relative',
    width: '95%',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  verMasFoto: {
    width: '10em',
    cursor: 'pointer'
  },
  verMasFoto2: {
    position: 'relative',
    width: '10em',
    cursor: 'pointer'
  },
  verMasFoto2M: {
    position: 'relative',
    width: '8em',
    cursor: 'pointer'
  },
  boxverMasFoto2: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vh'
  },
  boxverMasFoto2M: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25%'
  },
  boxverMasFoto: {
    position: 'absolute', 
    top: '91%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imagenNoticia: {
    position: 'relative',
    width: '400px',
    height: '250px',
    top: '-1%',
    border: '0.5px',
    borderStyle: 'solid',
    borderRadius: '1em',
    borderColor: '#86b0cc',
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxFotoDescripcion1: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '40%',
    height: '12%',
    top: '20%',
    left: '55%'   ,
  },
  boxFotoDescripcion2: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '35%',
    height: '12%',
    top: '50%',
    left: '10%'   ,
  },
  boxTextPorque: {
    width: '90%',
    overflow: 'hidden',
    left: '5%',
    position: 'relative',
    textAlign: "justify",

  },
  boxNoticias: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 480px)',
    justifyContent: 'center',
    padding: '0 0 5% 0',
  },
  linea: {
    backgroundColor: '#e8793b',
    height: '0.5%',
    width: '93%',
    left: '5%',
    position: 'relative',    
  },
  lineaM: {
    backgroundColor: '#e8793b',
    height: '2px',
    width: '93%',
    left: '5%',
    position: 'relative',    
  },
  buscadorFoto: {
    marginTop: '3%',
    position: 'relative',
    width: '30%',
    left: '3%'
  },
  filtroFoto: {
    marginTop: '3%',
    width: '90%',
    height: '4.5em',
    left: '3%',
    position: 'relative'
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  iconBtn: {
    left: '90%',
    top: '2%',
    position: 'absolute',
  },
  titleModalInsertar: {
    fontSize: '250%',
    fontWeight: 'bold',
    left: '15%',
    position: 'absolute',
    color: '#002A68',
    marginTop: '3%'
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '80%',    
    height: '110em',
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '8%',
    marginBottom: '5%'
  },
  textPais2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '8%',
    left: '10%',
    fontWeight: 'bold'
  },
  textPais3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '150%',
    top: '8%',
    left: '20%',
  },
  textAño2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '12%',
    left: '10%',
    fontWeight: 'bold'
  },
  textAño3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '150%',
    top: '12%',
    left: '20%',
  },
  textDescripcion2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '16%',
    left: '10%',
    fontWeight: 'bold'
  },
  textResultados2: {
    color: 'white',
    position: 'absolute',
    fontSize: '150%',
    top: '44%',
    left: '50%',
    fontWeight: 'bold'
  },
  textQuieres: {
    color: 'white',
    position: 'absolute',
    fontSize: '180%',
    top: '83%',
    left: '35%',
    fontWeight: 'bold'
  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '120%',
  },
  textQuieres2: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '120%',
    top: '87%',
    left: '20%'
  },
  textDescripcion3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '110%',
      
  },
  boxTextDescripcion3: {
    position: 'absolute',
    width: '45%',
    height: '20%',
    top: '21%',
    left: '10%'   ,
  },
  boxTextResultados3: {
    position: 'absolute',
    width: '45%',
    height: '20%',
    top: '49%',
    left: '50%'    ,
  },
  descargarFoto: {
    position: 'relative',
    top: '90%',
    width: '25%',
    left: '40%',
    cursor: 'pointer'
  },
  text: {
    fontSize:  'clamp(0.8rem, 1vw, 5rem)',
    fontFamily: 'Montserrat',
  },
  experienciasMobile: { 
    fontSize: '1.8em',
    fontWeight: '1000',
    color: '#213361',
    marginTop: '4em',
    marginBottom: '5%',
    left: '5%',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },  
  boxNoticiasMobile: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
    marginTop: '10%',
  },
  boxNoticiaMobile: {
    width: '65%',
    maxWidth: '380px',
    marginTop: '10%',
    margin: 'auto',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    borderColor: '#86b0cc',
    borderWidth: '2px',
    borderStyle: 'solid ',
    boxShadow: '3px 3px 10px  #86b0cc', 
    backgroundColor: 'white'  
  },
  imagenNoticiaMobile: {
    position: 'relative',
    maxWidth: '400px',
    width: '110%',
    maxHeight: '250px',
    top: '-1%',
    border: '0.5px',
    borderStyle: 'solid',
    borderRadius: '1em',
    borderColor: '#86b0cc',
    backgroundImage: 'url(./fondo/fondo-05.png)',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textTituloMobile:  {
    fontSize: '100%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  textNombreMobile: {
    color: '#213361',
    fontSize: '100%',
    left: '5%',
    width: '94%',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textCategoriaMobile:  {
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
  },
  textCategoria2Mobile:  {
    fontSize: '90%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '94%',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textPaisMobile:  {
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative',
  },
  textPais2NMobile:  {
    fontSize: '90%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '94%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat'
  },
  textAñoNMobile:  {
    fontWeight: 'bold',
    color: '#213361',
    position: 'relative'
  },
  textAño2NMobile:  {
    fontSize: '90%',
    color: '#213361',
    left: '5%',
    position: 'relative',
    width: '60%',
    fontFamily: 'Montserrat'
  },
  textPorqueMobile:  {
    fontSize: '90%',
    fontWeight: 'bold',
    color: '#213361',
    left: '5%',
    width: '95%',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  boxTextPorqueMobile: {
    width: '90%',
    left: '5%',
    position: 'relative',
    overflow: 'hidden',
    textAlign: "justify",

  },
  textPorque2Mobile:  {
    fontSize: '85%',
    color: '#213361',
    position: 'relative',
    fontFamily: 'Montserrat'
  },
  verMasFotoMobile: {
    cursor: 'pointer',
    width: '8em'

  },
  boxverMasFotoMobile: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3%',
    marginTop: '3%'
  },
  fondoModalInsertarMobile: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '45%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto',
  },
  iconBtnMobile: {
    left: '84%',
    top: '-1%',
    position: 'absolute',
  },
  titleModalInsertarMobile: {
    fontSize: '180%',
    fontWeight: 'bold',
    left: '10%',
    position: 'absolute',
    color: '#002A68',
    marginTop: '8%'
  },
  fondoFormulario2Mobile: {
    backgroundImage: 'url(./fondo/fondo-4.png)',
    backgroundSize: 'cover',
    width: '80%',    
    height: '110em',
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1em',
    marginTop: '38%',
    marginBottom: '5%'
  },
  textPais2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '2%',
    left: '10%',
    fontWeight: 'bold'
  },
  textPais3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '130%',
    top: '2%',
    left: '40%',
  },
  textAño2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '5%',
    left: '10%',
    fontWeight: 'bold'
  },
  textAño3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '130%',
    top: '5%',
    left: '40%',
  },
  textDescripcion2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '8%',
    left: '10%',
    fontWeight: 'bold'
  },
  boxTextDescripcion3Mobile: {
    position: 'absolute',
    width: '85%',
    height: '20%',
    top: '11%',
    left: '10%'   ,
  },
  textDescripcion3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '95%',
      
  },
  boxFotoDescripcion1Mobile: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '85%',
    height: '10%',
    top: '32%',
    left: '10%'   ,
  },
  textResultados2Mobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '130%',
    top: '42.5%',
    left: '10%',
    fontWeight: 'bold'
  },
  boxTextResultados3Mobile: {
    position: 'absolute',
    width: '85%',
    height: '20%',
    top: '45%',
    left: '10%'    ,
  },
  textResultados3Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '95%',
  },
  boxFotoDescripcion2Mobile: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '85%',
    height: '10%',
    top: '67%',
    left: '10%'   ,
  },
  textQuieresMobile: {
    color: 'white',
    position: 'absolute',
    fontSize: '115%',
    top: '80%',
    left: '10%',
    fontWeight: 'bold'
  },
  textQuieres2Mobile: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '100%',
    top: '83%',
    left: '10%'
  },
  descargarFotoMobile: {
    position: 'relative',
    top: '90%',
    width: '70%',
    left: '17%',
    cursor: 'pointer'
  },
  buscador2: {
    position: 'relative',
    width: '91%',
    height:'3em',
    backgroundColor: '#213361',
    top: '120%',
    left: '5%',
    border: '5px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',

  },
  flechaFiltro2: {
    color: 'white',
    position: 'relative',
  },
  flechaFiltro: {
    color: 'white',
    position: 'absolute',
    left: '85%'
  },
  flechaPais: {
    color: 'white',
    position: 'relative'
  },
  flechaCategoria: {
    color: 'white',
    position: 'relative'
  },
  flechaNivel: {
    color: 'white',
    position: 'relative'
  },
  iconFiltro: {
    color: 'white',
    position: 'relative',
    top: '0.2em'
  },
  iconPais: {
    color: 'white',
    position: 'relative',
    top: '0.3em'
  },
  iconCategoria: {
    color: 'white',
    position: 'relative',
    top: '0.2em'
  },
  iconNivel: {
    color: 'white',
    position: 'relative',
    top: '0.2em'
  }
  ,textFiltros: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '1',
    marginLeft: '3%',
    fontFamily: 'Montserrat',
    },
  textpaisBarra: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '1',
    fontFamily: 'Montserrat',
  },
  textNivel: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    marginRight: '3%',
    fontFamily: 'Montserrat',

  },
  textCategoriaBarra: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexGrow: '0.8',
    fontFamily: 'Montserrat',
  },
  buscador: {
    position: 'relative',
    marginBottom: '1%',
    width: '40%',
    height:'3em',
    backgroundColor: '#213361',
    left: '5%',
    border: '5px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 15px',

  },
  iconFiltroMobile: {
    color: 'white',
    right: '2%',
    top: '20%',
    position: 'relative',
  },
  iconPaisMobile: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: '33%'
  },
  iconCategoriaMobile: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: '57%'
  },
  textFiltrosMobile: {
    color: 'white',
    display: 'block',
    padding: '10px',
    border: 'black solid 1px',
    fontFamily: 'Montserrat'

  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textpaisBarraMobile: {
    color: 'white',
    left: '40%',
    top: '20%',
    position: 'absolute',
    fontSize: '100%'

  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'
  },
  textFooterMobile: {
    left: '1%',
    marginTop: '2%',
    position: 'relative',
    color: 'white',
    width: '85%',
    fontSize: '70%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'
  },
  textCategoriaBarraMobile: {
    color: 'white',
    left: '64%',
    top: '20%',
    position: 'absolute',
    fontSize: '100%'

  },
  buscadorIcon: {
    color: 'white',
    top: '1%',
    position: 'relative'
,  },
noBorder: {
  border: "none",
  color: 'white',
  float: 'right',
  
},
buscadorMobile: {
  position: 'relative',
  marginBottom: '5%',
  width: '83%',
  height:'2.5em',
  backgroundColor: '#213361',
  left: '5%',
  border: '5px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  padding: '0 0 0 0.5em'

},
buscador2Mobile: {
  position: 'relative',
  width: '85%',
  backgroundColor: '#213361',
  left: '5%', 
  listStyle: 'none',
  margin: '0',
  padding: '0',

},
flechaFiltroMobile: {
  color: 'white',
  left: '23%',
  top: '20%',
  position: 'absolute',
},
flechaPaisMobile: {
  color: 'white',
  left: '50%',
  top: '20%',
  position: 'absolute',
},
flechaCategoriaMobile: {
  color: 'white',
  left: '90%',
  top: '20%',
  position: 'absolute'
},
boxFooter: {
  width: '100%',
  height: '20em',
  backgroundColor: '#222222',
  position: 'relative',
  marginTop: '10%',
  border: '1px',
  borderRadius: '10px'

},
imagenFooter: {
  width: '100%',
  height:'40%',
},

imagenFooter2: {
  left: '5%',
  top: '5%',
  position: 'relative',
  width: '10em'
},
imagenFooter2M: {
  left: '5%',
  top: '5%',
  position: 'relative',
  width: '10em'
},
boxRedes: {
  position: 'absolute',
  width: '13%',
  top: '50%',
  left: '87%',
  height: '10%',
},
redesFooter: {
  width: '2em',
  margin: '0 2px'
},
optionsFecha: {
  backgroundColor: '#213361',
  position: 'absolute',
  left: '8%',
  padding: '0',
  display: 'block',
  zIndex: '1',
},
optionsFechaMobile: {
  backgroundColor: '#2A7DE1',
  position: 'relative',
  padding: '0',
  display: 'block',
  zIndex: '1',
},
optionsPais: {
  backgroundColor: '#213361',
  position: 'absolute',
  left: '25%',
  padding: '0',
  display: 'block',
  zIndex: '1',
  overflow: 'auto',
  height: '20%'
},
optionsCategorias: {
  backgroundColor: '#213361',
  position: 'absolute',
  left: '45%',
  padding: '0',
  display: 'block',
  zIndex: '1',
  overflow: 'auto',
  height: '20%'
},
optionsNivel: {
  backgroundColor: '#213361',
  position: 'absolute',
  left: '77%',
  padding: '0',
  display: 'block',
  zIndex: '1',
},
  optionsFecha2: {
  float: 'none',
  color: 'white',
  padding: '1rem 1rem',
  display: 'block',
  border: '1px solid black',
  cursor: 'pointer'

  },
  optionsFecha2Mobile: {
    float: 'none',
    color: 'white',
    padding: '0.5rem 0.5rem',
    display: 'block',
    border: '1px solid black',
    cursor: 'pointer'

    },
    star: {
      color: '#EABE3F',
      position: 'absolute',
      left: '80%',
      marginTop: '-2%'

    },
  triangulos: {
    position: 'absolute',
    width: '100%',
    height: '98%',
    left:'1%',
    top: '1%'
  },
  triangulosM: {
    position: 'absolute',
    width: '100%',
    height: '33%',
    left:'-30%',
    top: '0.1%',
  }

}));

export default function InicioPage() {
  
  
  CleanConsole.init()

  const [mobileFiltro, setMobile] = React.useState({
    showDivMobile: false,
    showDivMobile2: false,
    showDivMobile3: false,
    showDivMobile4: false,
  });
    
  const [state, setState] = React.useState({
    mobileView: false,
    experiencia: [],  
    showDiv: false,
    showDiv2: false,
    showDiv3: false,
    showDiv4: false,
    countNoticias: 6,
  }); 

  const { showDiv } = state
  const { showDivMobile } = mobileFiltro
  const { showDivMobile2 } = mobileFiltro
  const { showDivMobile3 } = mobileFiltro
  const { showDivMobile4 } = mobileFiltro
  const { showDiv2 } = state
  const { showDiv3 } = state
  const { showDiv4 } = state

  const [categorias, setCategorias] = useState([]);
  const [paises, setPaises] = useState([]);

  const buscadorRef = React.useRef();

  

  const { mobileView } = state;
  const classes = useStyles();

  const [noticias, setNoticias] = useState([]);
  const [timer, setTimer] = useState(null) 


  const inputChanged = e => {

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      getNoticias()
    }, 500)

    setTimer(newTimer)
  }


  function getNoticias(order="", nivel="", categoria_id="", pais=""){ 
    var url=localStorage.getItem('URL') + "/main/api/noticia/list?estado=A"    
    var  Buscador = buscadorRef.current.value
    console.log(Buscador)
    if (Buscador != "") {
      url+="&noticia_nombre="+Buscador
    };
    
    if (order != "" ) {
      url+="&order_by="+order
    };

    if (categoria_id != "" ) {
      url+="&categoria_id="+categoria_id
    };

    if (pais != "" ) {
      url+="&pais_id="+pais
    };

    if (nivel != "" ) {
      url+="&nivel_complejidad="+nivel
    };

    
    
    axios
    .get(url, {

    }
    )
    .then((response) => {
      setNoticias(response.data.data)
      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function getPaises(){

    axios
    .get(localStorage.getItem('URL') + "/main/api/pais/list")
    .then((response) => {
      setPaises (response.data.data );
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  function MostrarMasNoticias(){
    setState((prevState) => ({ ...prevState, countNoticias: state.countNoticias+6 }))
    
  }

  function getCategorias(){ 
    axios
    .get(localStorage.getItem('URL') + "/main/api/categoria/list", {

    }
    )
    .then((response) => {
      setCategorias(response.data.data);

      
    })
    .catch((error) => {
      console.log(error);
      
    });
  } 

  function componentDidMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setTimeout(() => window.location.reload(), 500);       
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  React.useEffect(() => {    
    getNoticias();
    getPaises();
    getCategorias();
    componentDidMount();
  }, []);
 
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
  

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => { 

    return (         
      <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)',    }}>
      <Box style={{position: 'relative', overflow: 'hidden', marginBottom: '5%'} }>
        <Box className={classes.fondoMobile}>
        <img alt=""  src={triangulos} className={classes.triangulosM}></img>
        {/* <Box>
          <a href="#/acceder"><img alt=""  src={Acceder}className={classes.accederMobile}></img></a>
        </Box>
        <Box>
          <a href="#/registrarse"><img alt=""  src={Registrarse}className={classes.registrarseMobile}></img></a>
        </Box> */}
        </Box>  
        <Box>
          <img  alt=""  src={LegalArriba}className={classes.legalImagenMobile}></img>
          <div  className={classes.fondo2M}><iframe frameborder="0"  width="100%" height="100%" style={{ position: 'absolute', top: 0, left: '0', width: '100%', height: '100%;'}} src="https://view.genial.ly/628adf4392fbc40012bf6b8e" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="no" allownetworking="all"></iframe> </div>  
        </Box>
         
        
      </Box>
        <Box className={classes.buscadorMobile}> 
            <SearchIcon className={classes.buscadorIcon} fontSize='small'></SearchIcon>           
            <TextField
      variant="outlined"
      placeholder="Buscador"
      fullWidth    
      InputProps={{        
        classes:{notchedOutline:classes.noBorder}        
      }}
      inputProps={{ style: { color: "white", fontFamily: 'Montserrat' } }}      
      onChange={inputChanged}
      inputRef={buscadorRef}
    ></TextField>
            </Box>
            <Box className={classes.buscador2Mobile}>
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile : !showDivMobile })}><ClearAllIcon className={classes.iconFiltro} fontSize='small'></ClearAllIcon> Fecha
              <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile && <div className={classes.optionsFechaMobile}>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile: !showDivMobile }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("asc","",""); setMobile({ showDivMobile: !showDivMobile }) }}>Más antiguas</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("desc","",""); setMobile({ showDivMobile: !showDivMobile }) } }>Más recientes</a>
            </div>}
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile2: !showDivMobile2 })}><GolfCourseIcon className={classes.iconFiltro} fontSize='small'></GolfCourseIcon>País
              <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile2 &&  (<div className={classes.optionsFechaMobile}>
                <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile2: !showDivMobile2 }) }}>Selecciona...</a>
              {paises.map(pais =>
                <a  className={classes.optionsFecha2Mobile} onClick={() => {getNoticias("","","",pais.id); setMobile({ showDivMobile2: !showDivMobile2})}}> {pais.nombre} </a>)}
            </div>)}
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile3: !showDivMobile3 })} > <BorderAllIcon className={classes.iconFiltro} fontSize='small' ></BorderAllIcon>Categorías de innovación<ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile3 && (<div className={classes.optionsFechaMobile}>
                <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile3: !showDivMobile3 }) }}>Selecciona...</a>
              {categorias.map(categoria =>
                <a  className={classes.optionsFecha2Mobile} onClick={() => {getNoticias("","",categoria.id); setMobile({ showDivMobile3: !showDivMobile3})}}> {categoria.nombre} </a>)}
            </div>)}   
              <li><a className={classes.textFiltrosMobile} onClick={() => setMobile({ showDivMobile4: !showDivMobile4 })}><GraphicEqIcon className={classes.iconFiltro}fontSize='small' ></GraphicEqIcon>Nivel de complejidad <ArrowDropDownIcon className={classes.flechaFiltro} ></ArrowDropDownIcon></a></li>
              {showDivMobile4 && (<div className={classes.optionsFechaMobile}>
              <a className={classes.optionsFecha2Mobile}  onClick={() => { getNoticias("","",""); setMobile({ showDivMobile4: !showDivMobile4 }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2Mobile} onClick={() =>  {getNoticias("","1",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Primer nivel de generación de información</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","2",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Segundo nivel de interoperabilidad</a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","3",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Tercer nivel de gestión de información </a>
              <a className={classes.optionsFecha2Mobile}  onClick={() => {getNoticias("","4",""); setMobile({ showDivMobile4: !showDivMobile4 })}}>Cuarto nivel de gestión de conocimiento</a>
            </div>)}
        
            </Box>         
            <Box className={classes.boxNoticiasMobile}> 
            {noticias.slice(0, state.countNoticias).map(noticia =>
               <div key={noticia.id} className={classes.boxNoticiaMobile}>
                 <Box className={classes.imagenNoticiaMobile}>              
                 {noticia.imagen1_filename != '' ? <img src={noticia.imagen1_filename} alt="" style={{display: 'block', maxWidth: '100%',  minHeight: '75%'}}   ></img> : <img src={fondoNoticia} alt=""  style={{width: '100%', height: '100%'}}></img>}
                 </Box>  
                 <hr className={classes.lineaM}></hr>
                 {noticia.destacada == "1" ? <Box><StarIcon  className={classes.star}></StarIcon></Box> : <Box></Box>}
                 <Typography className={classes.textTituloMobile} >Título:</Typography>                 
                 <Typography className={classes.textNombreMobile} >{noticia.nombre}</Typography>
                 <Typography className={classes.textCategoria2Mobile}  ><a className={classes.textCategoriaMobile}>Categoría: &nbsp;</a>{noticia.categoria_nombre}</Typography>
                  <Typography className={classes.textPais2NMobile} ><a className={classes.textPaisMobile}> País: &nbsp;</a>{noticia.pais_nombre == "" ? "Sin información": noticia.pais_nombre}</Typography>
                 <Typography className={classes.textAño2NMobile} ><a className={classes.textAñoNMobile}>Año: &nbsp;</a>{noticia.ano == null ? "Sin información": noticia.ano}</Typography>
                 <Typography className={classes.textPorqueMobile} >¿Por qué es una solución
innovadora?:</Typography>
                <Box className={classes.boxTextPorqueMobile}>
                 <Typography className={classes.textPorque2Mobile} >{ ReactHtmlParser(noticia.razon_innovadora_tarjeta)}</Typography>
                 </Box>
                 <Box className={classes.boxverMasFotoMobile}>
                 <a href={"#/experiencia/"+noticia.hash} rel="noreferrer" ><img src={verMas} className={classes.verMasFotoMobile}></img></a>
                 </Box>                
                 
               </div>
            )}  
            </Box>
            <Box className={classes.boxverMasFoto2M}> 
            {state.countNoticias > noticias.length ? <Box></Box> :  <img src={verMas} alt=""  className={classes.verMasFoto2M} onClick={MostrarMasNoticias}></img>}
            </Box>   
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img src={LegalArriba2} alt=""  className={classes.imagenFooter2M}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr>               
                <Typography className={classes.textFooterMobile}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooterMobile}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooterMobile}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooterMobile}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/'><img  alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank"><img alt=""  src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>

      </Box>    
            );
          }


const displayDesktop = () => {

  return (
    <Box style={{    backgroundImage: 'url(./fondo/fonodo-azulclaro.png)',  }}>
      <Box style={{position: 'relative', height: '100vh', overflow: 'hidden', marginBottom: '2%'} }>
        <Box  className={classes.fondo}>
        <img alt=""  src={triangulos} className={classes.triangulos}></img></Box>  
        <div  className={classes.fondo2}><iframe frameborder="0"  width="100%" height="100%" style={{  position: 'absolute', top: 0, left: '0', width: '100%', height: '100%;'}} src="https://view.genial.ly/627eb3a174003e0018943fc6" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="no" allownetworking="all"></iframe> </div>
        <Box>
          <img alt=""  src={LegalArriba}className={classes.legalImagen}></img>
        </Box>  
        {/* <Box >         
              <a href="#/registrarse"><img  alt=""  className={classes.registrarse} src={Registrarse}></img></a>
              <a href="#/acceder" ><img alt="" className={classes.acceder} src={Acceder}></img></a>          
        </Box>         */}
      </Box>
      <Box className={classes.buscador}> 
            <SearchIcon className={classes.buscadorIcon} fontSize='small'></SearchIcon>           
            <TextField
      variant="outlined"
      placeholder="Buscador"
      fullWidth
      InputProps={{        
        classes:{notchedOutline:classes.noBorder}        
      }}
      inputProps={{ style: { color: "white", fontSize: '130%', fontFamily: 'Montserrat'} }}      
      onChange={inputChanged}
      inputRef={buscadorRef}
    ></TextField>
            </Box>
      <Box className={classes.buscador2}>                        
            <Typography className={classes.textFiltros}  variant='h6'> <ClearAllIcon className={classes.iconFiltro}></ClearAllIcon> 
            Fecha
            <ArrowDropDownIcon className={classes.flechaFiltro2} fontSize='large' onClick={() => setState({ showDiv: !showDiv })}></ArrowDropDownIcon>
            </Typography>           
            <Typography className={classes.textpaisBarra}  variant='h6'><GolfCourseIcon className={classes.iconPais} fontSize='small'></GolfCourseIcon>
            País
            <ArrowDropDownIcon className={classes.flechaPais} fontSize='large'  onClick={() => setState({ showDiv3: !showDiv3})}></ArrowDropDownIcon>
            </Typography> 
            <Typography  className={classes.textCategoriaBarra} variant='h6'>
            <BorderAllIcon className={classes.iconCategoria} ></BorderAllIcon>
            Categorías de innovación
            <ArrowDropDownIcon className={classes.flechaCategoria} fontSize='large' onClick={() => setState({ showDiv4: !showDiv4})}></ArrowDropDownIcon>
            </Typography> 
            <Typography className={classes.textNivel} variant='h6' >
            <GraphicEqIcon className={classes.iconNivel}></GraphicEqIcon>
            Nivel de complejidad
            <ArrowDropDownIcon className={classes.flechaNivel} fontSize='large' onClick={() => setState({ showDiv2: !showDiv2 })}></ArrowDropDownIcon>
            </Typography>        
            </Box>
            {showDiv && <div className={classes.optionsFecha}>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("","",""); setState({ showDiv: !showDiv }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("asc","",""); setState({ showDiv: !showDiv }) }}>Más antiguas</a>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("desc","",""); setState({ showDiv: !showDiv }) } }>Más recientes</a>
            </div>}
            {showDiv2 && (<div className={classes.optionsNivel}>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("","",""); setState({ showDiv2: !showDiv2 }) }}>Selecciona...</a>
              <a className={classes.optionsFecha2} onClick={() =>  {getNoticias("","1",""); setState({ showDiv2: !showDiv2 })}}>Primer nivel de generación de información</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","2",""); setState({ showDiv2: !showDiv2 })}}>Segundo nivel de interoperabilidad</a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","3",""); setState({ showDiv2: !showDiv2 })}}>Tercer nivel de gestión de información </a>
              <a className={classes.optionsFecha2}  onClick={() => {getNoticias("","4",""); setState({ showDiv2: !showDiv2 })}}>Cuarto nivel de gestión de conocimiento</a>
            </div>)}
            {showDiv3 && (<div className={classes.optionsPais}>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("","",""); setState({ showDiv3: !showDiv3 }) }}>Selecciona...</a>
              {paises.map(pais =>
                <a  className={classes.optionsFecha2} onClick={() => {getNoticias("","","",pais.id); setState({ showDiv3: !showDiv3})}}> {pais.nombre} </a>)}
            </div>)}
            {showDiv4 && (<div className={classes.optionsCategorias}>
              <a className={classes.optionsFecha2}  onClick={() => { getNoticias("","",""); setState({ showDiv4: !showDiv4 }) }}>Selecciona...</a>
              {categorias.map(categoria =>
                <a  className={classes.optionsFecha2} onClick={() => {getNoticias("","",categoria.id); setState({ showDiv4: !showDiv4})}}> {categoria.nombre} </a>)}
            </div>)}      
      <Box className={classes.boxNoticias}> 
            {noticias.slice(0,state.countNoticias).map(noticia => 
               <div key={noticia.id}  className={classes.boxNoticia}>
                 <Box className={classes.imagenNoticia}>             
                 {noticia.imagen1_filename != '' ? <img alt=""  src={noticia.imagen1_filename} style={{display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'}} ></img> :<img src={fondoNoticia} alt="" style={{width: '100%', height: '100%'}}></img>}
                 </Box>   
                 <hr className={classes.linea}></hr>
                 {noticia.destacada == "1" ? <Box><StarIcon fontSize='large' className={classes.star}></StarIcon></Box> : <Box></Box>}
                 <Typography className={classes.textTitulo} >Título:</Typography>                 
                 <Typography className={classes.textNombre} >{noticia.nombre}</Typography>
                 <Box className={classes.boxTextCategoria2}>                            
                 <Typography className={classes.textCategoria2} ><a className={classes.textCategoria}>Categoría: &nbsp;</a>{noticia.categoria_nombre}</Typography>
                 </Box>                
                 <Typography className={classes.textPais2N} ><a className={classes.textPais}>País: &nbsp;</a>{noticia.pais_nombre == "" ? "Sin información": noticia.pais_nombre}</Typography>
                 <Typography className={classes.textAño2N} ><a className={classes.textAñoN}>Año: &nbsp;</a>{noticia.ano == null ? "Sin información": noticia.ano}</Typography>
                 <Box className={classes.boxtextPorque} >
                 <Typography className={classes.textPorque} >¿Por qué es una solución
innovadora?:</Typography>
          </Box>    
                <Box className={classes.boxTextPorque}>
                 <Typography className={classes.textPorque2} >{ ReactHtmlParser(noticia.razon_innovadora_tarjeta)}</Typography>
                 </Box>           
                 <Box className={classes.boxverMasFoto}>
                       
       <a href={"#/experiencia/"+noticia.hash}  rel="noreferrer" ><img alt="" src={verMas} className={classes.verMasFoto}></img></a>  
                </Box>              
               </div>
               
            )}                      
            </Box> 
            <Box className={classes.boxverMasFoto2}> 
            {state.countNoticias > noticias.length ? <Box></Box> : <img alt="" src={verMas} className={classes.verMasFoto2} onClick={MostrarMasNoticias}></img> }   
            </Box>  
           
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud' rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>

    </Box>

      );
    }

return (
  <header>
      {mobileView ? displayMobile() : displayDesktop()}
  </header>
);
}
