import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FondoArriba from './images/fondo-usuarior.png';
import axios from 'axios';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import { CleanConsole } from '@eaboy/clean-console';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondoArriba: {
    width: '100%',
    height: '8em',
    position: 'abosolute'
  },
  homeFoto: {   
    height: '60%',
    position: 'absolute',
    left: '10%',
    top:'10%'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position:'absolute',
    width: '5%',
    height: '12%'
  },
  legalFoto: {   
    width:'100%',
    top:'50%',
    marginTop: '5%',
    position: 'absolute'
  },  
  btnAdministradores: {
    position: 'absolute',
    top: '2%',
    left: '3%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnNoticias: {
    position: 'absolute',
    top: '2%',
    left: '40%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  bienvenido: {
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '65%',
    fontFamily: 'Montserrat'
  },
  btnCerrar: {
    position: 'absolute',
    top: '2%',
    left: '90%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnUsuarios: {
    position: 'absolute',
    top: '2%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnCategorias: {
    position: 'absolute',
    top: '2%',
    left: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  barraMenu: {
    position: 'absolute',
    backgroundColor: '#213361',
    width: '95%',
    height: '2.5em',
    marginTop: '1%',
    left: '2.5%',
    border: '5px',
    borderRadius: '10px'
  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'absolute',
    border: '1px',
    borderRadius: '10px',
    bottom: '0'
  
  },
  btnReportes: {
    position: 'absolute',
    top: '2%',
    left: '75%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  imagenFooter: {
    width: '100%',
    height:'40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter:{
    height:'2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },

}));
function cerrarSesion(){   
    
  const data = {
      
        email: localStorage.getItem("email"),
              
          
  };

  axios.post(localStorage.getItem('URL') + '/main/api/administrador/logout', data, {
    headers: {
      'Administrador-Id': localStorage.getItem("administrador_id"),
      'Session-Token': localStorage.getItem("session_token"),        
    }
  })
  .then((response) => {
    localStorage.clear();   
  })
  .catch((error) => {
    console.log(error);
    
  });
}

export default function MenuAdminPage() {
  CleanConsole.init()


  React.useEffect(() => {
    validateSession();

  }, []);

  function  validateSession(){
    if (localStorage.getItem('session_token') == undefined){
      window.location.href="/#/acceder"
    }
    if (localStorage.getItem('administrador_id') == undefined){
      window.location.href="/#/acceder"
    }
  }
  

      
  const [state, setState] = React.useState({
    mobileView: false,
    
  });
  

 
 

  const { mobileView } = state;
  const classes = useStyles();
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <Box></Box>
      );
    }
  
  const displayDesktop = () => {
  
    return (
        <Box>
          <Box>            
            <img alt="" src={FondoArriba} className={classes.fondoArriba}></img>            
            <Box>
              <Typography variant="h4" className={classes.bienvenido}>
                Bienvenido {localStorage.getItem("nombre")}
              </Typography>                        
            </Box>
            
            <Box className={classes.barraMenu} >
            <Button className={classes.btnAdministradores} variant="text" href="#/menu/admin/administradores">Administradores</Button>
            <Button className={classes.btnUsuarios} variant="text" href="#/menu/admin/usuarios">Usuarios</Button>
            <Button className={classes.btnCategorias} variant="text" href="#/menu/admin/categorias">Categorías</Button>
            <Button className={classes.btnNoticias} variant="text" href="#/menu/admin/noticias">Admins. Experiencias</Button>
            <Button className={classes.btnReportes} variant="text" href="#/menu/admin/reportes">Reportes</Button>
            <Button className={classes.btnCerrar} variant="text" onClick={cerrarSesion} href="#/">Cerrar</Button>
            </Box>
            <footer>
              <Box  className={classes.boxFooter}>
                <Box className={classes.imagenFooter}>
                  <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
                </Box>
                <hr className={classes.lineaFooter}></hr><br></br>                
                <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
                <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography> 
                <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
                <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
                <Box className={classes.boxRedes}>
                  <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer"  target="_blank"><img alt=""  src={facebookFooter} className={classes.redesFooter}></img></a>
                  <a href='https://twitter.com/infosegurapnud'  rel="noreferrer"  target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                  <a href='mailto:infosegura@undp.org'  ><img alt=""  src={msgFooter} className={classes.redesFooter}></img></a>
                
                </Box>
              </Box>
              
            </footer>

          </Box>
        </Box>
    );
  }
  
  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }