import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FondoArriba from './images/fondo-usuarior.png';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Modal from '@material-ui/core/Modal';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { ReactTableDefaults } from 'react-table-6';
import fondoNoticia from './images/fondoNoticia.png';
import Checkbox from '@material-ui/core/Checkbox';
import LegalArriba2 from './images/logos2SinFondo.png';
import facebookFooter from './images/facebook.png';
import twitterFooter from './images/twitterFooter.png';
import msgFooter from './images/msj.png';
import ReactHtmlParser from 'react-html-parser';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { CleanConsole } from '@eaboy/clean-console';






const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: 'Montserrat',
  },
  fondo: {
    width: '100%',
    height: '90em',
    backgroundColor: '#002A68',

  },
  homeFoto: {
    height: '60%',
    position: 'absolute',
    left: '10%',
    top: '10%'
  },
  boxHome: {
    backgroundColor: 'white',
    top: '0.1%',
    position: 'absolute',
    width: '5%',
    height: '12%'
  },

  tablaAdmin: {
    top: '32%',
    width: '85%',
    left: '5%',
    marginBottom: '5%',
  },
  btnCrear: {
    color: 'white',
    backgroundColor: '#5cb85c',
    marginTop: '7em',
    left: '5%',
    marginBottom: '3%',
    fontFamily: 'Montserrat'
  },
  fondoModalInsertar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto',
    // eslint-disable-next-line
    ['@media (max-width:1300px)']: {
      left: '50%',
      width: '80%'
    },
  },
  iconBtn: {
    position: 'absolute',
    left: '90%',
    top: '2%',
    borderBottomLeftRadius: '10px 10px',
  },
  titleModalInsertar: {
    fontSize: '150%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  titleModalInsertar2: {
    fontSize: '250%',
    fontWeight: 'bold',
    left: '10%',
    width: '79%',
    position: 'relative',
    color: '#002A68',
    marginTop: '3%',
    fontFamily: 'Montserrat'
  },
  titleModalEliminar: {
    fontSize: '150%',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10%',
    fontFamily: 'Montserrat'

  },
  form: {
    position: 'absolute',
    top: '15%',
    left: '10%',
    width: '85%',
    height: '80%'
  },
  inputEmail: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputNombre: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  inputContraseña: {
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%'
  },
  title: {
    position: 'absolute',
    color: 'white',
    fontWeight: 'bold',
    top: '10%',
    left: '15%'
  },
  fondoArriba: {
    width: '100%',
    height: '8em',
    position: 'abosolute'
  },
  legalFoto: {
    width: '100%',
    marginTop: '5%',
    position: 'relative'
  },
  btnAdministradores: {
    position: 'absolute',
    top: '2%',
    left: '3%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnNoticias: {
    position: 'absolute',
    top: '2%',
    left: '40%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  bienvenido: {
    fontWeight: '900',
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '65%',
    fontFamily: 'Montserrat'
  },
  btnCerrar: {
    position: 'absolute',
    top: '2%',
    left: '90%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnUsuarios: {
    position: 'absolute',
    top: '2%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  barraMenu: {
    position: 'absolute',
    backgroundColor: '#213361',
    width: '95%',
    height: '2.5em',
    marginTop: '1%',
    left: '2.5%',
    border: '5px',
    borderRadius: '10px'
  },
  textNombreCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textNombre: {
    fontFamily: 'Montserrat'
  },
  textCorreoCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textIdCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  textEditarCell: {
    fontSize: '100%',
    fontWeight: 'bold'
  },
  btnCategorias: {
    position: 'absolute',
    top: '2%',
    left: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  inputDescripciones: {
    width: '89%',
    height: '15em',
    marginTop: '3%',
    marginBottom: '1%'
  },
  inputDescripciones2: {
    width: '89%',
    height: '10em',
    marginTop: '3%',
    marginBottom: '1%'
  },
  textAgregar: {
    marginTop: '3%',
    fontFamily: 'Montserrat'
  },
  textAgregar2: {
    marginTop: '3%',
    marginBottom: '8%',
    fontFamily: 'Montserrat'
  },
  textAgregar4: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat'

  },
  textAgregar3: {
    marginTop: '1%',
    fontFamily: 'Montserrat'
  },
  botonAcceder: {
    backgroundImage: 'url(./fondo/archivo.png)',
    backgroundSize: '100%',
    marginTop: '5%',
    width: '30%',
    height: '44px',
    position: 'absolute',
    cursor: 'pointer'

  },

  botones: {
    marginTop: '10%',
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ocultar: {
    display: 'none',
  },
  btnInsertar: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    right: '10%',
    color: 'white',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnInsertar2: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    marginBottom: '5%',
    left: '25%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnInsertar3: {
    backgroundColor: '#5cb85c',
    marginTop: '3%',
    marginBottom: '5%',
    left: '25%',
    top: '60%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnCancelar: {
    backgroundColor: '#d9534f',
    left: '5%',
    marginTop: '3%',
    color: 'white',
    marginBottom: '5%',
    fontFamily: 'Montserrat'
  },
  btnCancelar2: {
    backgroundColor: '#d9534f',
    left: '35%',
    marginTop: '3%',
    marginBottom: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnCancelar3: {
    backgroundColor: '#d9534f',
    left: '40%',
    marginTop: '3%',
    top: '60%',
    marginBottom: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnEditar: {
    backgroundColor: '#0275d8',
    marginRight: '5%',
    marginLeft: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnVisualizar: {
    backgroundColor: '#5cb85c',
    marginRight: '10%',
    marginLeft: '5%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  btnEliminar: {
    backgroundColor: '#d9534f',
    color: 'white',
    fontFamily: 'Montserrat'

  },
  fondoModalEliminar: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '15%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px'

  },
  divExtio2: {
    backgroundColor: '#D3E9D0',
    opacity: '10',
    height: '10%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  exito: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  exito2: {
    color: 'green',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  divError: {
    backgroundColor: '#ff9090',
    height: '20%',
    width: '70%',
    left: '-5%',
    top: '450%',
    position: 'absolute',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divError4: {
    backgroundColor: '#ff9090',
    height: '15%',
    width: '40%',
    left: '-5%',
    position: 'absolute',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divError2: {
    backgroundColor: '#ff9090',
    height: '8%',
    width: '20%',
    position: 'absolute',
    top: '75%',
    left: '3%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'Montserrat'
  },
  fondoModalInsertar2: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    maxHeight: '100%',
    overflow: 'auto'
  },
  fondoFormulario2: {
    backgroundImage: 'url(./fondo/fondo4-20.png)',
    backgroundSize: 'cover',
    width: '80%',
    left: '10%',
    position: 'relative',
    border: '1px',
    borderRadius: '1.2em',
    marginTop: '5%',
    marginBottom: '2% '
  },
  textPais2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    top: '5%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textPais3: {
    color: '#e3e4e5',
    position: 'relative',
    fontWeight: '10'

  },
  textAño2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    top: '11%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  textAño3: {
    color: '#e3e4e5',
    position: 'absolute',
    fontSize: '170%',
    top: '11%',
    left: '20%',
  },
  textDescripcion2: {
    color: 'white',
    position: 'relative',
    fontSize: '170%',
    top: '17%',
    left: '5%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextDescripcion3: {
    position: 'relative',
    width: '90%',
    height: '33%',
    top: '23%',
    left: '5%',
    overflow: 'hidden',

  },
  textDescripcion3: {
    color: '#e3e4e5',
    fontSize: '110%',
    fontFamily: 'Montserrat'

  },
  boxFotoDescripcion1: {
    backgroundColor: 'white',
    width: '330px',
    height: '206px',
    position: 'relative',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
    marginLeft: '5%',
    overflow: 'hidden',
    // eslint-disable-next-line
    ['@media (max-width:1300px)']: {
      width: '220px',
      height: '138px',
    },
  },
  textResultados2: {
    color: 'white',
    position: 'relative',
    fontSize: '150%',
    top: '58%',
    left: '45%',
    width: '50%',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  boxTextResultados3: {
    position: 'relative',
    width: '95%',
    left: '5%',
    top: '62%',
    overflow: 'hidden',
  },
  textResultados3: {
    color: '#e3e4e5',
    position: 'relative',
    fontSize: '110%',
    width: '98%',
    fontFamily: 'Montserrat'
  },
  boxFotoDescripcion2: {
    position: 'relative',
    backgroundColor: 'white',
    width: '330px',
    height: '206px',
    border: '3px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
    marginRight: '5%',
    overflow: 'hidden',
    // eslint-disable-next-line
    ['@media (max-width:1300px)']: {
      width: '220px',
      height: '138px',
    },
  },
  imagen1: {
    border: '3px',
    borderRadius: '20px',
    display: 'block', maxWidth: '100%', height: 'auto', maxHeight: '100%', minHeight: '75%'
  },
  boxtitleModalInsertar: {
    left: '10%',
    position: 'relative',
    width: '80%',
    height: '5%',
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textOds: {
    fontWeight: 'bold',
    marginTop: '4%',
    marginBottom: '4%',
    fontFamily: 'Montserrat'
  },
  odsDerecha: {
    position: 'relative',

  },
  odsIzquierda: {

  },
  checkDestacar: {
    position: 'relative',

  },
  noBorder: {
    border: "none",
    color: 'white',
    float: 'right',

  },
  boxFooter: {
    width: '100%',
    height: '20em',
    backgroundColor: '#222222',
    position: 'relative',
    marginTop: '10%',
    border: '1px',
    borderRadius: '10px'

  },
  imagenFooter: {
    width: '100%',
    height: '40%',
  },
  imagenFooter2: {
    left: '5%',
    top: '5%',
    position: 'relative',
    width: '10em'
  },
  btnReportes: {
    position: 'absolute',
    top: '2%',
    left: '75%',
    color: 'white',
    fontFamily: 'Montserrat'
  },
  boxRedes: {
    position: 'absolute',
    width: '13%',
    top: '50%',
    left: '87%',
    height: '10%',
  },
  redesFooter: {
    width: '2em',
    margin: '0 2px'
  },
  lineaFooter: {
    height: '2%',
    backgroundColor: 'white'
  },
  textFooter: {
    left: '5%',
    position: 'relative',
    color: 'white',
    width: '95%',
    fontSize: '90%',
    marginBottom: '0.5%',
    fontFamily: 'Montserrat'

  },
  textImagenEditar: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '1.5em',
    marginTop: '-1%',
    fontFamily: 'Montserrat'
  },
  boxOdses: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    left: '5%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  fotoOds: {
    width: '6em',
    height: 'auto',
    margin: '0.5em'
  },
  textodsD: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    // eslint-disable-next-line
    ['@media (max-width:1200px)']: {
      fontSize: '12px'
    },
  },
  boxEditor: {
    border: '1px solid #9b9b9b',
    padding: '0 1em',
    width: '86%',
    height: '10em',
    overflowY: 'scroll',
    marginTop: '1em',
    marginBottom: '1em'
  },
  buscador: {
    position: 'absolute',
    marginBottom: '5%',
    width: '30%',
    height: '2em',
    top: '14.5em',
    left: '58%',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 15px',
    border: 'solid #223362 2px'

  },



}));


export default function AdminAdministradores() {
  CleanConsole.init()

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = (rowId) => {
    setIdRowNow(rowId);
    setOpen2(true);
  };
  const handleOpen3 = (rowId) => {
    setIdRowNow(rowId);
    setOpen3(true);
  };
  const handleOpen4 = (rowId) => {
    setIdRowNow(rowId);
    setOpen4(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [formularioEnviado2, cambiarFormularioEnviado2] = useState(false);
  const [formularioEnviado3, cambiarFormularioEnviado3] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorFormulario2, cambiarErrorFormulario2] = useState(false);
  const [errorFormulario3, cambiarErrorFormulario3] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [idRowNow, setIdRowNow] = useState(0);
  const [errorMessage, setError] = useState([]);

  const html = "";
  const blocksFromHTML = convertFromHTML(html)
  const content = ContentState.createFromBlockArray(blocksFromHTML)

  const MAX_LENGTH = 1000;
  const [stateText, setStateText] = React.useState({
    editorState: EditorState.createEmpty(),
  });


  const { editorState } = stateText;



  const DescripcionText = draftToHtml(convertToRaw(editorState.getCurrentContent()))


  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange = (editorState) => {
    setStateText({
      editorState,
    });
  }

  const [stateText2, setStateText2] = React.useState({
    editorState2: EditorState.createEmpty(),
  });


  const { editorState2 } = stateText2;
  const DescripcionText2 = draftToHtml(convertToRaw(editorState2.getCurrentContent()))


  const _handleBeforeInput2 = () => {
    const currentContent = editorState2.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText2 = (pastedText) => {
    const currentContent = editorState2.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange2 = (editorState2) => {
    setStateText2({
      editorState2,
    });
  }

  const [stateText3, setStateText3] = React.useState({
    editorState3: EditorState.createEmpty(),
  });


  const { editorState3 } = stateText3;
  const DescripcionText3 = draftToHtml(convertToRaw(editorState3.getCurrentContent()))


  const _handleBeforeInput3 = () => {
    const currentContent = editorState3.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText3 = (pastedText) => {
    const currentContent = editorState3.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange3 = (editorState3) => {
    setStateText3({
      editorState3,
    });
  };

  const [stateText4, setStateText4] = React.useState({
    editorState4: EditorState.createWithContent(content)
  });


  const { editorState4 } = stateText4;
  const DescripcionText4 = draftToHtml(convertToRaw(editorState4.getCurrentContent()))


  const _handleBeforeInput4 = () => {
    const currentContent = editorState4.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText4 = (pastedText) => {
    const currentContent = editorState4.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange4 = (editorState4) => {
    setStateText4({
      editorState4,
    });
  };

  const [stateText5, setStateText5] = React.useState({
    editorState5: EditorState.createWithContent(content)
  });


  const { editorState5 } = stateText5;
  const DescripcionText5 = draftToHtml(convertToRaw(editorState5.getCurrentContent()))


  const _handleBeforeInput5 = () => {
    const currentContent = editorState5.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText5 = (pastedText) => {
    const currentContent = editorState5.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange5 = (editorState5) => {
    setStateText5({
      editorState5,
    });
  };

  const [stateText6, setStateText6] = React.useState({
    editorState6: EditorState.createWithContent(content)
  });


  const { editorState6 } = stateText6;
  const DescripcionText6 = draftToHtml(convertToRaw(editorState6.getCurrentContent()))


  const _handleBeforeInput6 = () => {
    const currentContent = editorState6.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const _handlePastedText6 = (pastedText) => {
    const currentContent = editorState6.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      alert('El contenido tiene mas de 1000 caracteres');

      return 'handled';
    }
  }

  const onEditorStateChange6 = (editorState6) => {
    setStateText6({
      editorState6,
    });
  };
  function getOdses() {

    axios
      .get(localStorage.getItem('URL') + "/main/api/ods/list")
      .then((response) => {
        setState((prevState) => ({ ...prevState, odses: response.data.data }));
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const [state, setState] = React.useState({
    mobileView: false,
    administradores: [],
    categorias: [],
    paises: [],
    noticias: [],
    noticia: [],
    odses: [],
    ods_found: false,
  });

  const [imagenes, setImagenes] = React.useState({

  });
  const [OdsEstado, setOds] = React.useState({

  });


  Object.assign(ReactTableDefaults, {
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando..',
    noDataText: 'No rows found',
    pageText: 'Pagina',
    ofText: 'de',
    rowsText: 'Filas',
  })

  const columns = [{
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    width: 90,
    Cell: row => <div style={{ textAlign: "center", }}>{row.value}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }

  }, {
    id: 'nombre',
    Header: 'NOMBRE',
    accessor: 'nombre',
    width: 400,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }
  }, {
    id: 'ano',
    Header: 'AÑO',
    accessor: 'ano',
    width: 90,
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }
  },
  {
    id: 'pais',
    Header: 'PAÍS',
    accessor: 'pais_nombre',
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }
  }, {
    id: 'ciudad',
    Header: 'CIUDAD',
    accessor: 'ciudad',
    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }
  }, {
    id: 'estado',
    Header: 'ESTADO',
    accessor: 'estado',
    // eslint-disable-next-line
    Cell: row => <div style={{ textAlign: "center" }}>{row.value == 'A' ? 'ACTIVO' : row.value == 'I' ? 'INACTIVO' : 'PENDIENTE'}</div>,
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' }
  }, {
    id: 'acciones',
    Header: 'ACCIONES',
    width: 300,
    accessor: '',
    headerStyle: { fontWeight: 'bold', backgroundColor: '#e3e4e5' },
    Cell: (row) => {
      return (<div>
        <button className={classes.btnEditar}
          onClick={() => {
            handleOpen2(row.value.id);
            getNoticia(row.value.hash);
          }}
        >EDITAR </button>

        <button className={classes.btnEliminar} onClick={() => handleOpen3(row.value.id)}>ELIMINAR </button>

        <button className={classes.btnVisualizar}
          onClick={() => {
            handleOpen4(row.value.id);
            getNoticia(row.value.hash);
          }}
        >VISUALIZAR </button>

      </div>);
    }
  }]


  const { mobileView } = state;
  const classes = useStyles();
  const categoriaRef = React.useRef();
  const añoRef = React.useRef();
  const imagen1Ref = React.useRef();
  const imagen2Ref = React.useRef();
  const pdfRef = React.useRef();
  const imagen1Editar = React.useRef();
  const imagen2Editar = React.useRef();
  const pdfEditar = React.useRef();
  const comentariosRef = React.useRef();
  const nivelRef = React.useRef();
  const destacarRef = React.useRef();
  const nombreExperienciaRef = React.useRef();
  const paisRef = React.useRef();
  const ciudadRef = React.useRef();
  const estadoRef = React.useRef();

  React.useEffect(() => {
    validateSession();
    getCategorias();
    getPaises();
    getNoticias();
    getOdses();
  }, []);

  function validateSession() {
    // eslint-disable-next-line
    if (localStorage.getItem('session_token') == undefined) {
      window.location.href = "/#/acceder"
    }
    // eslint-disable-next-line
    if (localStorage.getItem('administrador_id') == undefined) {
      window.location.href = "/#/acceder"
    }
  }

  function handleChangeEvent(event) {

    setState((prevState) => ({
      ...prevState, noticia: {
        [event.target.name]: event.target.value,
      }
    }));
  }


  function eliminarNoticia(noticiaID) {
    axios
      .delete(localStorage.getItem('URL') + "/main/api/noticia/delete?id=" + noticiaID, {
        headers: {
          'Administrador-Id': localStorage.getItem("administrador_id"),
          'Session-Token': localStorage.getItem("session_token")
        }
      }
      )
      .then((response) => {
        handleClose3()
        cambiarFormularioEnviado2(true);
        setTimeout(() => cambiarFormularioEnviado2(false), 5000)
        setTimeout(() => refreshPage(), 2000)


      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message)
        handleClose3()
        cambiarErrorFormulario2(true);
        setTimeout(() => cambiarErrorFormulario2(false), 5000);
      });
  }


  function refreshPage() {
    window.location.reload(false);
  }


  function getNoticia(noticiaHash) {
    axios
      .get(localStorage.getItem('URL') + "/main/api/noticia/detail?hash=" + noticiaHash, {
        headers: {
          'Administrador-Id': localStorage.getItem("administrador_id"),
          'Session-Token': localStorage.getItem("session_token")
        }

      }
      )
      .then((response) => {
        setState((prevState) => ({ ...prevState, noticia: response.data.data }));
        setImagenes(response.data.data);
        setOds(response.data.data);
        const blocksFromHTML = convertFromHTML(response.data.data.descripcion)
        const content = ContentState.createFromBlockArray(blocksFromHTML)
        const blocksFromHTML2 = convertFromHTML(response.data.data.razon_innovadora)
        const content2 = ContentState.createFromBlockArray(blocksFromHTML2)
        const blocksFromHTML3 = convertFromHTML(response.data.data.resultados)
        const content3 = ContentState.createFromBlockArray(blocksFromHTML3)
        setStateText4((prevState) => ({ ...prevState, editorState4: EditorState.createWithContent(content) }));
        setStateText5((prevState) => ({ ...prevState, editorState5: EditorState.createWithContent(content2) }));
        setStateText6((prevState) => ({ ...prevState, editorState6: EditorState.createWithContent(content3) }));


      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [timer, setTimer] = useState(null)

  const inputChanged = e => {

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      getNoticias()
    }, 500)

    setTimer(newTimer)
  }


  function getNoticias() {
    var filtro = buscadorRef.current.value
    axios
      .get(localStorage.getItem('URL') + "/main/api/noticia/list?noticia_nombre=" + filtro, {
        headers: {
          'Administrador-Id': localStorage.getItem("administrador_id"),
          'Session-Token': localStorage.getItem("session_token")
        }
      }
      )
      .then((response) => {

        setDataTable(response.data.data);
        response.data.data.map((noticia) =>
          console.log("")
        );
        setState((prevState) => ({ ...prevState, noticias: response.data.data })
        );

      })
      .catch((error) => {
        console.log(error);

      });
  }


  function getCategorias() {
    axios
      .get(localStorage.getItem('URL') + "/main/api/categoria/list", {
        headers: {
          'Administrador-Id': localStorage.getItem("administrador_id"),
          'Session-Token': localStorage.getItem("session_token")
        }
      }
      )
      .then((response) => {
        setState((prevState) => ({ ...prevState, categorias: response.data.data })
        );

      })
      .catch((error) => {
        console.log(error);

      });
  }



  function getPaises() {

    axios
      .get(localStorage.getItem('URL') + "/main/api/pais/list")
      .then((response) => {
        setState((prevState) => ({ ...prevState, paises: response.data.data }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function cerrarSesion() {

    const data = {

      email: localStorage.getItem("email"),


    };

    axios.post(localStorage.getItem('URL') + '/main/api/administrador/logout', data, {
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),
      }
    })
      .then((response) => {
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);

      });
  }

  function validarPeso() {
    const oFile = document.getElementById("imagen1").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen1").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }
  function validarPesoE() {
    const oFile = document.getElementById("imagen1Editar").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen1Editar").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }
  function validarPeso2() {
    const oFile = document.getElementById("imagen2").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen2").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }
  function validarPesoE2() {
    const oFile = document.getElementById("imagen2Editar").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("imagen2Editar").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }
  function validarPeso3() {
    const oFile = document.getElementById("pdf").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("pdf").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }
  function validarPesoE3() {
    const oFile = document.getElementById("pdfEditar").files[0];
    if (oFile.size > 3145728) {
      document.getElementById("pdfEditar").value = "";
      alert("El archivo pesa más de 3MB ")
      return;
    }
  }



  function enviarNoticia() {
    var bodyFormData = new FormData();
    bodyFormData.append('categoria_id', categoriaRef.current.value);
    bodyFormData.append('nombre', nombreExperienciaRef.current.value);
    bodyFormData.append('ano', añoRef.current.value);
    bodyFormData.append('pais_id', paisRef.current.value);
    bodyFormData.append('ciudad', ciudadRef.current.value);
    bodyFormData.append('descripcion', DescripcionText);
    bodyFormData.append('razon_innovadora', DescripcionText2);
    bodyFormData.append('resultados', DescripcionText3);
    bodyFormData.append('nivel_complejidad', nivelRef.current.value);
    bodyFormData.append('imagen1', imagen1Ref.current.files[0]);
    bodyFormData.append('imagen2', imagen2Ref.current.files[0]);
    bodyFormData.append('pdf', pdfRef.current.files[0]);
    bodyFormData.append('destacada', destacarRef.current.value);


    var markedCheckbox = document.getElementsByName('ods');
    for (var checkbox of markedCheckbox) {
      if (checkbox.checked)
        bodyFormData.append("ods", checkbox.value);
    }
    axios({
      method: "post",
      url: localStorage.getItem('URL') + "/main/api/noticia",
      data: bodyFormData,
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),
      },
    })
      .then((response) => {
        handleClose1();
        cambiarFormularioEnviado(true);
        setTimeout(() => cambiarFormularioEnviado(false), 5000);
        setTimeout(() => refreshPage(), 2000)

      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message)
        cambiarErrorFormulario(true);
        setTimeout(() => cambiarErrorFormulario(false), 5000);

      });
  }

  function editarNoticia() {
    var bodyFormData = new FormData();
    bodyFormData.append('id', idRowNow);
    bodyFormData.append('categoria_id', categoriaRef.current.value);
    bodyFormData.append('nombre', nombreExperienciaRef.current.value);
    bodyFormData.append('ano', añoRef.current.value);
    bodyFormData.append('pais_id', paisRef.current.value);
    bodyFormData.append('ciudad', ciudadRef.current.value);
    bodyFormData.append('estado', estadoRef.current.value);
    bodyFormData.append('comentario', comentariosRef.current.value);
    bodyFormData.append('nivel_complejidad', nivelRef.current.value);
    bodyFormData.append('descripcion', DescripcionText4);
    bodyFormData.append('razon_innovadora', DescripcionText5);
    bodyFormData.append('resultados', DescripcionText6);
    bodyFormData.append('imagen1', imagen1Editar.current.files[0]);
    bodyFormData.append('imagen2', imagen2Editar.current.files[0]);
    bodyFormData.append('pdf', pdfEditar.current.files[0]);
    bodyFormData.append('destacada', destacarRef.current.value);
    var markedCheckbox = document.getElementsByName('ods_editar_' + idRowNow);
    for (var checkbox of markedCheckbox) {
      if (checkbox.checked)
        bodyFormData.append("ods", checkbox.value);
    }

    axios({
      method: "put",
      url: localStorage.getItem('URL') + "/main/api/noticia/update",
      data: bodyFormData,
      headers: {
        'Administrador-Id': localStorage.getItem("administrador_id"),
        'Session-Token': localStorage.getItem("session_token"),
      },
    })
      .then((response) => {
        handleClose2()
        cambiarFormularioEnviado3(true);
        setTimeout(() => cambiarFormularioEnviado3(false), 5000);
        setTimeout(() => refreshPage(), 2000)


      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message)
        cambiarErrorFormulario3(true);
        setTimeout(() => cambiarErrorFormulario3(false), 5000);

      });
  }
  const buscadorRef = React.useRef("");


  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => {
    return (

      <Box></Box>
    );
  }

  const displayDesktop = () => {

    return (
      <Box>
        <Box >
          <Box>
            <Box>
              <img alt="" src={FondoArriba} className={classes.fondoArriba}></img>
              <Box>
                <Typography variant="h4" className={classes.bienvenido}>
                  Bienvenido {localStorage.getItem("nombre")}
                </Typography>
              </Box>

              <Box className={classes.barraMenu} >
                <Button className={classes.btnAdministradores} variant="text" href="#/menu/admin/administradores">Administradores</Button>
                <Button className={classes.btnUsuarios} variant="text" href="#/menu/admin/usuarios">Usuarios</Button>
                <Button className={classes.btnCategorias} variant="text" href="#/menu/admin/categorias">Categorías</Button>
                <Button className={classes.btnNoticias} variant="text" href="#/menu/admin/noticias">Admins. Experiencias</Button>
                <Button className={classes.btnReportes} variant="text" href="#/menu/admin/reportes">Reportes</Button>
                <Button className={classes.btnCerrar} variant="text" onClick={cerrarSesion} href="#/">Cerrar</Button>
              </Box>
            </Box>
          </Box>
          <Button className={classes.btnCrear} variant="contained" onClick={handleOpen1}>
            CREAR EXPERIENCIA
          </Button>
          <Box className={classes.buscador}>
            <TextField placeholder='Buscador'
              variant="outlined"
              fullWidth
              inputRef={buscadorRef}
              onChange={inputChanged}
              InputProps={{
                classes: { notchedOutline: classes.noBorder }
              }}
              inputProps={{ style: { fontSize: '120%', fontFamily: 'Montserrat' } }}
            ></TextField>
          </Box>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.fondoModalInsertar}>
              <IconButton
                aria-label="close"
                onClick={handleClose1}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
                className={classes.iconBtn}
              >
                <CancelPresentationIcon fontSize="large" />
              </IconButton>
              <Box className={classes.boxtitleModalInsertar}>
                <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
                  CREAR EXPERIENCIA
                </Typography>
              </Box>
              <Box className={classes.form}>
                <Box>
                  <Typography id="modal-modal-description" className={classes.textNombre} sx={{ mt: 2 }}>
                    Categoría:
                  </Typography>
                  <NativeSelect variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.inputEmail} inputRef={categoriaRef} >
                    <option>Selecciona...</option>
                    {state.categorias.map(elemento => (
                      <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                    )
                    )}
                  </NativeSelect>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Nombre de la experiencia</Typography>
                  <TextField inputProps={{ style: { fontFamily: 'Montserrat' } }} placeholder='El nombre de la experiencia no puede contener mas 255 caracteres' variant="outlined" className={classes.inputNombre} inputRef={nombreExperienciaRef} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Año:</Typography>
                  <TextField inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" type="number" className={classes.inputNombre} inputRef={añoRef} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>País</Typography>
                  <NativeSelect variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.inputNombre} inputRef={paisRef} >
                    <option value="" >Selecciona...</option>
                    {state.paises.map(elemento => (
                      <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                    )
                    )}
                  </NativeSelect>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Ciudad:</Typography>
                  <TextField inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={ciudadRef} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Descripción de la innovación: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.inputDescripcion}>
                    <Box className={classes.boxEditor}>
                      <Editor
                        editorState={editorState}
                        toolbar={{
                          options: ['inline', 'list', 'textAlign'],
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        handleBeforeInput={_handleBeforeInput}
                        handlePastedText={_handlePastedText}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>¿Por qué es una solución innovadora?: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.boxEditor}>
                    <Editor
                      editorState={editorState2}
                      toolbar={{
                        options: ['inline', 'list', 'textAlign'],
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2}
                      handleBeforeInput={_handleBeforeInput2}
                      handlePastedText={_handlePastedText2}
                    />
                  </Box>                  </Box>
                <Box>
                  <Typography className={classes.textNombre}>Resultados: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.boxEditor}>
                    <Editor
                      editorState={editorState3}
                      toolbar={{
                        options: ['inline', 'list', 'textAlign'],
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange3}
                      handleBeforeInput={_handleBeforeInput3}
                      handlePastedText={_handlePastedText3}
                    />
                  </Box>                  </Box>
                <Box>
                  <Typography className={classes.textAgregar}>Agregar imágenes/tablas relevantes de la experiencia.<br></br><br></br>Imagen 1</Typography>  <a style={{ fontFamily: "Montserrat", fontSize: '14px' }}>(El tamaño sugerido de la imagen es 400x250 px, o un tamaño que mantenga está misma relación.)</a>
                  <TextField inputProps={{ accept: "image/*", style: { fontFamily: 'Montserrat' } }} type="file" id="imagen1" inputRef={imagen1Ref} onChange={(e) => validarPeso()} className={classes.inputNombre} variant="outlined" />
                </Box>
                <Box>
                  <Typography className={classes.textAgregar3}>Imagen 2</Typography>  <a style={{ fontFamily: "Montserrat", fontSize: '14px' }}>(El tamaño sugerido de la imagen es 400x250 px, o un tamaño que mantenga está misma relación.)</a>
                  <TextField inputProps={{ accept: "image/*", style: { fontFamily: 'Montserrat' } }} type="file" id="imagen2" inputRef={imagen2Ref} onChange={(e) => validarPeso2()} className={classes.inputNombre} variant="outlined" />
                </Box>
                <Box>
                  <Typography className={classes.textAgregar3}>Subir PDF: (Máximo 3MB)</Typography>
                  <TextField inputProps={{ accept: "application/pdf", style: { fontFamily: 'Montserrat' } }} type="file" id="pdf" inputRef={pdfRef} onChange={(e) => validarPeso3()} className={classes.inputNombre} variant="outlined" />
                </Box>

                <Box>
                  <Typography className={classes.textAgregar2}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
                    máximo de 3MB.</Typography>
                </Box>
                <hr></hr>
                <Typography className={classes.textAgregar4} variant='h6'>Sección para administrador.</Typography>
                <hr></hr><br></br>

                <Box>
                  <Typography className={classes.textAgregar3} >Nivel de complejidad</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={nivelRef} >
                    <option value=""> Selecciona </option>
                    <option value="1"> Primer nivel de generación de información </option>
                    <option value="2"> Segundo nivel de interoperabilidad </option>
                    <option value="3"> Tercer nivel de gestión de información  </option>
                    <option value="4"> Cuarto nivel de gestión de conocimiento </option>
                  </NativeSelect>
                </Box>

                <Typography className={classes.textOds}>ODS</Typography>
                <Box style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, 50%)',
                  justifyContent: 'center',
                  padding: '0 0 5% 0'
                }} >
                  <Box className={classes.odsDerecha}>
                    {state.odses.slice(0, 9).map(elemento => (
                      <Box key={elemento.id} className={classes.checkOds} >
                        <Checkbox name="ods" value={elemento.id} color="primary"></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box>
                    )
                    )}
                  </Box>
                  <Box className={classes.odsIzquierda}>
                    {state.odses.slice(9, 20).map(elemento => (
                      <Box key={elemento.id} className={classes.checkOds} >
                        <Checkbox name="ods" value={elemento.id} color="primary"></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box>
                    )
                    )}
                  </Box>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '300px' }}>
                  <Typography className={classes.textOds} variant='h5'>Destacar Noticia</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.checkDestacar} inputRef={destacarRef} >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                  </NativeSelect>
                </Box>
                {errorFormulario && <div className={classes.divError4}><Typography className={classes.error}>{errorMessage} </Typography> </div>}

                <Box className={classes.botones}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={enviarNoticia}
                    className={classes.btnInsertar}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleClose1}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>



          <Modal

            open={open2}
            onClose={handleClose2}

          >
            <Box className={classes.fondoModalInsertar}>
              <IconButton
                aria-label="close"
                onClick={handleClose2}
                className={classes.iconBtn}
              >
                <CancelPresentationIcon fontSize="large" />
              </IconButton>
              <Box className={classes.boxtitleModalInsertar}>
                <Typography className={classes.titleModalInsertar} variant="h6" component="h2">
                  EDITAR EXPERIENCIA
                </Typography>
              </Box>
              <Box className={classes.form}>
                <Box>
                  <Typography id="modal-modal-description" className={classes.textNombre} sx={{ mt: 2 }}>
                    Id:
                  </Typography>
                  <TextField variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.inputEmail} name="id" disabled="disabled" value={idRowNow} />
                </Box>
                <Box>
                  <Typography id="modal-modal-description" className={classes.textNombre} sx={{ mt: 2 }}>
                    Categoría:
                  </Typography>
                  <NativeSelect variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.inputEmail} inputRef={categoriaRef} value={state.noticia.categoria_id} onChange={handleChangeEvent} >
                    <option>Selecciona...</option>
                    {state.categorias.map(elemento => (
                      <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                    )
                    )}
                  </NativeSelect>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Nombre de la experiencia</Typography>
                  <TextField variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} placeholder='El nombre de la experiencia no puede contener mas 255 caracteres' className={classes.inputNombre} inputRef={nombreExperienciaRef} value={state.noticia.nombre} onChange={handleChangeEvent} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Año:</Typography>
                  <TextField variant="outlined" inputProps={{ style: { fontFamily: 'Montserrat' } }} type="number" className={classes.inputNombre} inputRef={añoRef} value={state.noticia.ano} onChange={handleChangeEvent} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>País</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={paisRef} value={state.noticia.pais_id} onChange={handleChangeEvent} >
                    <option value="" >Selecciona...</option>
                    {state.paises.map(elemento => (
                      <option key={elemento.id} value={elemento.id}>{elemento.nombre}</option>
                    )
                    )}
                  </NativeSelect>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Ciudad:</Typography>
                  <TextField inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={ciudadRef} value={state.noticia.ciudad} onChange={handleChangeEvent} />
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>Descripción de la innovación: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.boxEditor}>
                    <Editor
                      editorState={editorState4}
                      toolbar={{
                        options: ['inline', 'list', 'textAlign'],
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange4}
                      handleBeforeInput={_handleBeforeInput4}
                      handlePastedText={_handlePastedText4} />
                  </Box>
                </Box>
                <Box>
                  <Typography className={classes.textNombre}>¿Por qué es una solución innovadora?: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.boxEditor}>
                    <Editor
                      editorState={editorState5}
                      toolbar={{
                        options: ['inline', 'list', 'textAlign'],
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange5}
                      handleBeforeInput={_handleBeforeInput5}
                      handlePastedText={_handlePastedText5} />
                  </Box>                  </Box>
                <Box>
                  <Typography className={classes.textNombre}>Resultados: (Máximo 1.000 caracteres)</Typography>
                  <Box className={classes.boxEditor}>
                    <Editor
                      editorState={editorState6}
                      toolbar={{
                        options: ['inline', 'list', 'textAlign'],
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange6}
                      handleBeforeInput={_handleBeforeInput6}
                      handlePastedText={_handlePastedText6} />
                  </Box>                  </Box>
                <Box>
                  <Typography className={classes.textAgregar}>Agregar imágenes/tablas relevantes de la experiencia.<br></br><br></br>Imagen 1 CAPA</Typography>
                  <TextField inputProps={{ accept: "image/*", style: { fontFamily: 'Montserrat' } }} type="file" inputRef={imagen1Editar} onChange={(e) => validarPesoE()} id="imagen1Editar" className={classes.inputNombre} variant="outlined" />
                  {imagenes.imagen1_filename != "" ? <Typography className={classes.textImagenEditar}><Link color="inherit" underline='always' href={imagenes.imagen1_filename} rel="noreferrer" target="_blank">Imagen Actual</Link></Typography> : <Box></Box>}
                </Box>
                <Box>
                  <Typography className={classes.textAgregar3}>Imagen 2</Typography>
                  <TextField inputProps={{ accept: "image/*", style: { fontFamily: 'Montserrat' } }} type="file" inputRef={imagen2Editar} onChange={(e) => validarPesoE2()} id="imagen2Editar" className={classes.inputNombre} variant="outlined" />
                  {imagenes.imagen2_filename != "" ? <Typography className={classes.textImagenEditar}><Link color="inherit" underline='always' href={imagenes.imagen2_filename} rel="noreferrer" target="_blank">Imagen Actual</Link></Typography> : <Box></Box>}
                </Box>
                <Box>
                  <Typography className={classes.textAgregar3}>Subir PDF: (Máximo 3MB)</Typography>
                  <TextField type='file' inputProps={{ accept: "application/pdf", style: { fontFamily: 'Montserrat' } }} inputRef={pdfEditar} id="pdfEditar" onChange={(e) => validarPesoE3()} className={classes.inputNombre} variant="outlined" />
                  {imagenes.pdf_filename != "" ? <Typography className={classes.textImagenEditar}><Link color="inherit" underline='always' href={imagenes.pdf_filename} rel="noreferrer" target="_blank">PDF actual</Link></Typography> : <Box></Box>}

                </Box>
                <Box>
                  <Typography className={classes.textAgregar2}>Las imágenes deben ser en formato PNG y/o JPEG y deben pesar un
                    máximo de 3MB.</Typography>
                </Box>
                <hr></hr>
                <Typography className={classes.textAgregar4} variant='h6'>Sección para administrador.</Typography>
                <hr></hr><br></br>

                <Box>
                  <Typography className={classes.textNombre}>Estado:</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} inputRef={estadoRef} value={state.noticia.estado} onChange={handleChangeEvent} >
                    <option>Selecciona...</option>
                    <option value="A">Activo</option>
                    <option value="P">Pendiente</option>
                    <option value="I">Inactivo</option>
                  </NativeSelect>
                </Box>
                <Box>
                  <Typography className={classes.textNivel}>Comentarios</Typography>
                  <TextField inputProps={{ style: { fontFamily: 'Montserrat' } }} multiline rows={6} inputRef={comentariosRef} variant="outlined" value={state.noticia.comentario} onChange={handleChangeEvent} className={classes.inputNombre} />

                </Box>
                <Box>
                  <Typography  >Nivel de complejidad</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} variant="outlined" className={classes.inputNombre} value={state.noticia.nivel_complejidad} onChange={handleChangeEvent} inputRef={nivelRef} >
                    <option value=""> Selecciona </option>
                    <option value="1"> Primer nivel de generación de información </option>
                    <option value="2"> Segundo nivel de interoperabilidad </option>
                    <option value="3"> Tercer nivel de gestión de información  </option>
                    <option value="4"> Cuarto nivel de gestión de conocimiento </option>
                  </NativeSelect>
                </Box>
                <Box >
                  <Typography className={classes.textOds}>ODS</Typography>
                  <Box style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 50%)',
                    justifyContent: 'center',
                    padding: '0 0 5% 0'
                  }} >
                    <Box className={classes.odsDerecha}>
                      {state.odses.slice(0, 9).map(elemento => (
                        <Box key={elemento.id} className={classes.checkOds} >
                          {state.ods_found = false}
                          {OdsEstado.odses != undefined ?
                            <Box>
                              {OdsEstado.odses.map(noticia_ods => (
                                noticia_ods.id == elemento.id ? <Box>{state.ods_found = true}<Checkbox name={"ods_editar_" + idRowNow} value={elemento.id} color="primary" defaultChecked={true}></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label> </Box> : <Box></Box>
                              ))}

                            </Box> : <Box></Box>
                          }
                          {state.ods_found == false ? <Box><Checkbox name={"ods_editar_" + idRowNow} value={elemento.id} color="primary" ></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box> : <Box></Box>}

                        </Box>

                      ))}
                    </Box>
                    <Box className={classes.odsIzquierda}>
                      {state.odses.slice(9, 20).map(elemento => (
                        <Box key={elemento.id} className={classes.checkOds} >
                          {state.ods_found = false}
                          {OdsEstado.odses != undefined ?
                            <Box>
                              {OdsEstado.odses.map(noticia_ods => (
                                noticia_ods.id == elemento.id ? <Box>{state.ods_found = true}<Checkbox name={"ods_editar_" + idRowNow} value={elemento.id} color="primary" defaultChecked={true} ></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label> </Box> : <Box></Box>
                              ))}

                            </Box> : <Box></Box>
                          }
                          {state.ods_found == false ? <Box><Checkbox name={"ods_editar_" + idRowNow} value={elemento.id} color="primary" ></Checkbox><label className={classes.textodsD}>{elemento.codigo}-{elemento.nombre}</label></Box> : <Box></Box>}

                        </Box>

                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '300px' }}>
                  <Typography className={classes.textOds} variant='h5'>Destacar Noticia</Typography>
                  <NativeSelect inputProps={{ style: { fontFamily: 'Montserrat' } }} className={classes.checkDestacar} inputRef={destacarRef} onChange={handleChangeEvent} value={state.noticia.destacada} >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                  </NativeSelect>


                </Box>


                {errorFormulario3 && <div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div>}
                <Box className={classes.botones}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={editarNoticia}
                    className={classes.btnInsertar}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleClose2}
                    className={classes.btnCancelar}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={open3}
            onClose={handleClose3}

          >
            <Box className={classes.fondoModalEliminar}>
              <IconButton
                aria-label="close"
                onClick={handleClose3}
                className={classes.iconBtn}
              >
                <CancelPresentationIcon fontSize="large" />
              </IconButton>
              <Box className={classes.boxtitleModalInsertar}>
                <Typography className={classes.titleModalEliminar} variant="h6" component="h2">
                  ¿Seguro que desea eliminar esta experiencia?
                </Typography>
              </Box>
              <Box className={classes.form}>


                <Button
                  variant="contained"
                  size="large"
                  className={classes.btnInsertar3}
                  onClick={() => eliminarNoticia(idRowNow)}
                >
                  Eliminar
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleClose3}
                  className={classes.btnCancelar3}
                >
                  Cancelar
                </Button>
              </Box>

            </Box>
          </Modal>

          <Modal
            open={open4}
            onClose={handleClose4}

          >
            <Box className={classes.fondoModalInsertar2}>
              <IconButton
                aria-label="close"
                onClick={handleClose4}
                className={classes.iconBtn}
              >
                <CancelPresentationIcon fontSize="large" />
              </IconButton>

              <Typography className={classes.titleModalInsertar2} variant="h6" component="h2">
                {state.noticia.nombre}
              </Typography>
              <Box className={classes.fondoFormulario2}><br></br>
                <Typography className={classes.textPais2}>País: <a className={classes.textPais3}>{state.noticia.pais_nombre == "" ? "Sin información" : state.noticia.pais_nombre}</a></Typography><br></br>
                <Typography className={classes.textAño2}>Año: <a className={classes.textPais3}>{state.noticia.ano == null ? "Sin información" : state.noticia.ano}</a></Typography><br></br>
                <Typography className={classes.textDescripcion2}>Descripcion</Typography><br></br>
                <Box className={classes.boxTextDescripcion3}>
                  <Box className={classes.boxFotoDescripcion1}>
                    {state.noticia.imagen1_filename != '' ? <img alt="" src={state.noticia.imagen1_filename} className={classes.imagen1} ></img> : <img alt="" src={fondoNoticia} style={{ width: '100%', height: '100%' }} ></img>}
                  </Box>   <br></br>
                  <Typography className={classes.textDescripcion3}>{ReactHtmlParser(state.noticia.descripcion)}</Typography>
                </Box>   <br></br>       <br></br><br></br>
                <Typography className={classes.textResultados2}>Resultados</Typography><br></br>
                <Box className={classes.boxTextResultados3}>
                  <Box className={classes.boxFotoDescripcion2}>
                    {state.noticia.imagen2_filename != '' ? <img alt="" src={state.noticia.imagen2_filename} className={classes.imagen1} ></img> : <img alt="" src={fondoNoticia} style={{ width: '100%', height: '100%' }} ></img>}
                  </Box><br></br>
                  <Typography className={classes.textResultados3}>{ReactHtmlParser(state.noticia.resultados)}</Typography>
                </Box><br></br><br></br>
                <Box>
                  {state.noticia.odses != undefined ?
                    <Box className={classes.boxOdses}>
                      {state.noticia.odses.map(noticia_ods => (
                        <img alt="" className={classes.fotoOds} src={noticia_ods.imagen}></img>
                      ))}

                    </Box> : <Box></Box>
                  }
                </Box>
                <br></br><br></br>

              </Box>

            </Box>
          </Modal>


          <ReactTable className={classes.tablaAdmin}
            getTdProps={() => ({
              style: { border: `1px solid rgb(200 200 200)` },
            })}
            data={dataTable}
            columns={columns}
            pagination
          />
          {formularioEnviado && <div className={classes.divExtio2}> <Typography className={classes.exito}>Experiencia creada correctamente</Typography> </div>}
          {formularioEnviado2 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Experiencia Eliminada correctamente</Typography> </div>}
          {formularioEnviado3 && <div className={classes.divExtio2}> <Typography className={classes.exito2}>Experiencia Editada correctamente</Typography> </div>}
          {errorFormulario2 && <div className={classes.divError2}><Typography className={classes.error}>{errorMessage} </Typography> </div>}
          <footer>
            <Box className={classes.boxFooter}>
              <Box className={classes.imagenFooter}>
                <img alt="" src={LegalArriba2} className={classes.imagenFooter2}></img>
              </Box>
              <hr className={classes.lineaFooter}></hr><br></br>
              <Typography className={classes.textFooter}>Unidad de coordinación regional del proyecto Infosegura, oficina del PNUD en El Salvador</Typography>
              <Typography className={classes.textFooter}>Edificio Naciones Unidas,blvd. Orden de Malta sur, No 2B,Santa Elena,Antiguo Cuscatlan, La Libertad.</Typography>
              <Typography className={classes.textFooter}>teléfono:(503)2263 -0066 | E-mail: infosegura@undp.org</Typography>
              <Typography className={classes.textFooter}>© 2022.Infosegura. Todos los derechos reservados.</Typography>
              <Box className={classes.boxRedes}>
                <a href='https://www.facebook.com/infoseguraPNUD/' rel="noreferrer" target="_blank"><img alt="" src={facebookFooter} className={classes.redesFooter}></img></a>
                <a href='https://twitter.com/infosegurapnud' rel="noreferrer" target="_blank" ><img alt="" src={twitterFooter} className={classes.redesFooter}></img></a>
                <a href='mailto:infosegura@undp.org'  ><img alt="" src={msgFooter} className={classes.redesFooter}></img></a>

              </Box>
            </Box>

          </footer>
        </Box>
      </Box>
    );
  }

  return (
    <header>
      {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
}